import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tryParseJSON } from '../../../../common/utils/jsonUtils';
import { defaults } from '../../../../system/services/HttpService';
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg';
import { closeVideoReUploadModal, onVideoReUploadSuccess, } from '../../../../redux/actions/live-streams/live-streams-actions';
import { openNotificationModal } from '../../../../redux/actions/notification-modal/notification-modal-actions';
import { LIVE_STREAMS } from '../../../../redux/actions/live-streams/live-streams-action-types';
import { getAccountSlice, getVideoReUploadModal } from '../../../../redux/selectors';
import { VideoUpload } from '../index';
import UploadModalBody from './upload-modal-body';
const UploadVideoModal = () => {
    const dispatch = useDispatch();
    const [videoFileRequest, setVideoFileRequest] = useState(null);
    const [videoProgress, setVideoProgress] = useState(0);
    const [videoProgressStart, setVideoProgressStart] = useState(false);
    const [videoFileName, setVideoFileName] = useState('');
    const videoReUploadModalData = useSelector(getVideoReUploadModal);
    const accountInfo = useSelector(getAccountSlice);
    const handleModalClose = () => {
        dispatch(closeVideoReUploadModal());
    };
    const progressHandler = (event) => {
        const percent = (event.loaded / event.total) * 100;
        const numberBeforeDecimal = Number.parseInt(percent.toString(), 10);
        setVideoProgress(numberBeforeDecimal);
    };
    const completeHandler = (event) => {
        var _a, _b;
        setVideoProgressStart(false);
        const request = event.currentTarget;
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                const response = tryParseJSON(request.responseText);
                if (response && response.data) {
                    dispatch(onVideoReUploadSuccess({
                        file: (_a = response.data) === null || _a === void 0 ? void 0 : _a.file,
                        liveStreamId: (_b = videoReUploadModalData === null || videoReUploadModalData === void 0 ? void 0 : videoReUploadModalData.data) === null || _b === void 0 ? void 0 : _b.liveStreamId, // TODO Fix me
                    }));
                    setVideoFileName('');
                    dispatch(closeVideoReUploadModal());
                    dispatch(openNotificationModal(LIVE_STREAMS.UPDATE_SUCCESS));
                }
            }
            else {
                console.log('Error loading file');
            }
        }
    };
    const errorHandler = (event) => {
        console.log(event);
        setVideoProgressStart(false);
        setVideoProgress(0);
        setVideoFileName('');
    };
    const abortHandler = () => {
        setVideoProgressStart(false);
        setVideoProgress(0);
        setVideoFileName('');
    };
    const abortVideoUpload = () => {
        videoFileRequest === null || videoFileRequest === void 0 ? void 0 : videoFileRequest.abort();
    };
    const videoTranscode = (file, _, fileName) => {
        var _a;
        const url = `${defaults.api_host}/dashboard/liveStreams/${(_a = videoReUploadModalData === null || videoReUploadModalData === void 0 ? void 0 : videoReUploadModalData.data) === null || _a === void 0 ? void 0 : _a.liveStreamId // TODO FIX ME
        }/upload`;
        const formData = new window.FormData();
        formData.append('file', file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const request = new window.XMLHttpRequest();
        setVideoFileRequest(request);
        setVideoFileName(fileName);
        request.upload.addEventListener('progress', progressHandler, false);
        request.addEventListener('load', completeHandler, false);
        request.addEventListener('error', errorHandler, false);
        request.addEventListener('abort', abortHandler, false);
        request.open('POST', url);
        request.setRequestHeader('Authorization', 'Bearer ' + accountInfo.token);
        request.send(formData);
        setVideoProgressStart(true);
    };
    return (React.createElement(Modal, { title: "Re-upload video recording", open: videoReUploadModalData.open, closeIcon: React.createElement(CloseIcon, null), onCancel: handleModalClose, width: 600, className: "tap-event-modal", destroyOnClose: true, footer: React.createElement(React.Fragment, null, videoProgressStart ? (React.createElement(Button, { type: "primary", size: "large", onClick: abortVideoUpload, "data-automation-id": "cancel-upload-btn" }, "Cancel")) : (React.createElement(VideoUpload, { videoTranscode: videoTranscode }))) },
        React.createElement(UploadModalBody, { videoProgress: videoProgress, videoFileName: videoFileName, videoProgressStart: videoProgressStart })));
};
export default UploadVideoModal;
