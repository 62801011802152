import { App } from 'antd';
import React, { useEffect } from 'react';
import { getSuccessMessageConfig } from 'src/app/components/ant-app/message';
import { ToolbarButton } from 'src/app/components/common/BulkToolbar/ToolbarButton';
import { useBulkPublishMutation } from 'src/app/queries/posts/bulk';
import { useGalleryBulkStore } from '../bulk-state';
export const BulkPublishButton = () => {
    const selectedIds = useGalleryBulkStore((state) => state.selectedIds);
    const resetBulk = useGalleryBulkStore((state) => state.reset);
    const setLoading = useGalleryBulkStore((state) => state.setLoading);
    const { message } = App.useApp();
    const disabled = selectedIds.size === 0;
    const { mutate, isPending, reset, isSuccess } = useBulkPublishMutation();
    const handlePublish = () => {
        setLoading();
        mutate({
            postIds: selectedIds,
            publish: true,
        });
    };
    useEffect(() => {
        if (isSuccess) {
            reset();
            resetBulk();
            message.info(getSuccessMessageConfig('Media published'));
        }
    }, [isSuccess, reset, resetBulk, message]);
    return (React.createElement(ToolbarButton, { icon: "plus-sign", title: "Publish", "aria-label": "Publish", disabled: disabled || isPending, onClick: handlePublish }));
};
