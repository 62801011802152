import React from 'react';
import { selectIsSascOrIdp } from 'src/app/redux/selectors';
import { useAppSelector } from '../hooks/store';
import { Permissions } from './permissions';
import { HomeRedirect } from 'src/app/containers/_layout/nav/components/HomeRedirect';
export const Protect = ({ children, permission, defaultValue }) => {
    const isSascOrIdp = useAppSelector(selectIsSascOrIdp) && !permission;
    const hasPermission = !permission || Permissions.can(permission, defaultValue);
    if (isSascOrIdp || !hasPermission) {
        return React.createElement(HomeRedirect, null);
    }
    return React.createElement(React.Fragment, null, children);
};
