export var FIELD_TYPE;
(function (FIELD_TYPE) {
    FIELD_TYPE["JURNEY_STAGE"] = "Journey stage";
    FIELD_TYPE["JURNEY_STEP"] = "Journey step";
    FIELD_TYPE["DESTINATON"] = "Destination";
    FIELD_TYPE["TOPICS_THEME"] = "Theme";
    FIELD_TYPE["TOPIC"] = "Topic";
    FIELD_TYPE["CHANNEL"] = "Channel";
    FIELD_TYPE["CONTENT_TYPE"] = "Content type";
})(FIELD_TYPE || (FIELD_TYPE = {}));
export const journeyStepOptions = [
    {
        label: 'Awareness',
        options: ['Discover', 'Browse', 'Consider'],
    },
    {
        label: 'Research',
        options: ['Seek', 'Refine', 'Research'],
    },
    {
        label: 'Engage',
        options: ['Approach', 'Understand', 'Finalise'],
    },
    {
        label: 'Apply',
        options: ['Refine and retrieve', 'Select', 'Prepare', 'Submit'],
    },
    {
        label: 'Accept',
        options: ['Wait for offer', 'Choose and accept offer', 'Apply visa'],
    },
    {
        label: 'Prepare',
        options: ['Prepare '],
    },
    {
        label: 'Arrive',
        options: ['Institution', 'Settling', 'Accommodation', 'Social'],
    },
    {
        label: 'Thrive',
        options: ['Work', 'Visa', 'ROI'],
    },
];
export const topicOptions = [
    {
        label: 'Destination',
        options: ['Education and learning', 'Culture and Lifestyle', 'Student experience', ' Affordability'],
    },
    {
        label: 'Funding & Finance',
        options: ['Scholarships & funding', 'Investment', 'ROI', 'Financial aid rules', 'Tutition fees & payment'],
    },
    {
        label: 'Work & Careers',
        options: ['During study', 'After study', 'Career support'],
    },
    {
        label: 'Institutions',
        options: ['Rankings & USPs', 'Student life', 'Education and research', 'Communication'],
    },
    {
        label: 'Degrees & Courses',
        options: ['Study level', 'Qualification type', 'Subject and specilisations', 'Entry requirements'],
    },
    {
        label: 'Application',
        options: ['Testing', 'Admission and application'],
    },
    {
        label: 'Immigration',
        options: ['Visa', 'Immigration rules', 'Travel planning'],
    },
];
const channelOptions = [
    'Hotcourses India',
    'Hotcourses Abroad',
    'Hotcourses Vietnam',
    'Hotcourses Thailand',
    'Hotcourses Indonesia',
    'Hotcourses Middle East',
    'Hotcourses Turkey',
    'Hotcourses Russia',
    'Hotcourses Latin America',
    'Hotcourses Brazil',
    'IDP Live App',
    'WhatUni Web',
    'WhatUni App',
    'IDP Singapore',
    'IDP Malaysia',
    'IDP Mauritius',
    'IDP Australia',
    'IDP New Zealand',
    'IDP Canada',
    'IDP Vietnam',
    'IDP Thailand',
    'IDP Global',
    'IDP Bangladesh',
    'IDP Nepal',
    'IDP Sri Lanka',
    'IDP Philippines',
    'IDP Egypt',
    'IDP Oman',
    'IDP Turkey',
    'IDP UAE',
    'IDP Saudi Arabia',
    'IDP Korea',
    'IDP Indonesia',
    'IDP Taiwan',
    'IDP India',
    'IDP Hong Kong',
    'IDP Cambodia',
    'IDP Iran',
    'IDP Middle East',
    'IDP Pakistan',
    'IDP Kenya',
    'IDP Nigeria',
    'IDP China',
    'IDP Ghana',
    'IDP Country',
    'IDP Australia',
    'IDP Bahrain',
    'IDP Bangladesh',
    'IDP Cambodia',
    'IDP Canada',
    'IDP Egypt',
    'IDP Hong Kong',
    'IDP India',
    'IDP Indonesia',
    'IDP Iran',
    'IDP Kenya',
    'IDP Korea',
    'IDP Kuwait',
    'IDP Jordan',
    'IDP Lebanon',
    'IDP Malaysia',
    'IDP Mauritius',
    'IDP Nepal',
    'IDP New Zealand',
    'IDP Oman',
    'IDP Pakistan',
    'IDP Philippines',
    'IDP Saudi Arabia',
    'IDP Singapore',
    'IDP Sri Lanka',
    'IDP Taiwan',
    'IDP Thailand',
    'IDP Turkey',
    'IDP UAE',
    'IDP Vietnam',
    'IDP Nigeria',
    'IDP Ghana',
];
export const contentTypeOptions = [
    { label: 'text', value: 'text' },
    { label: 'photo', value: 'image' },
    { label: 'video', value: 'video' },
];
const convertToFormat = (list, type) => {
    return list.map((item) => {
        const label = item;
        const value = `${type} : ${item}`;
        return { label, value };
    });
};
const convertToGroupedOptions = (options, type, title) => {
    return options.map((group) => ({
        label: group.label,
        options: group.options.map((optionLabel) => ({
            label: optionLabel,
            value: `${type} : ${optionLabel}`,
            title: `${title} : ${group.label}`,
        })),
    }));
};
export const topics = convertToGroupedOptions(topicOptions, FIELD_TYPE.TOPIC, FIELD_TYPE.TOPICS_THEME);
export const channel = convertToFormat(channelOptions, FIELD_TYPE.CHANNEL);
export const journeyStep = convertToGroupedOptions(journeyStepOptions, FIELD_TYPE.JURNEY_STEP, FIELD_TYPE.JURNEY_STAGE);
