var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCurrentGroupUniversityId, getUniversityInfo, getUsersSlice } from '../../../../redux/selectors';
import { useUsersColumnsConfig } from './use-users-columns-config';
import { MAX_USERS_LIMIT_POPUP_TOGGLE, USERS_INFO_REQUESTED, getUserTableTotalsAction, openUserInfoModal, } from '../../../../redux/actions/content/usersActions';
import { UserRowModel } from './models';
import { checkIsInTHE } from '../../../../common/utils/user-account-helpers';
import { useAppSelector } from '../../../../hooks/store';
const ORDER_DIRECTION_MAP = {
    ascend: 'asc',
    descend: 'desc',
};
const SETTINGS_INITIAL_STATE = {
    sorter: {
        field: 'full_name',
        order: 'ascend',
    },
    pagination: { current: 1, pageSize: 20 },
    filter: {},
    search: {},
    withGlobalTags: true,
};
const createRequestParams = ({ sorter, pagination, filter, search, withGlobalTags }) => {
    const { field, order: orderValue, columnKey } = sorter;
    const { current, pageSize } = pagination;
    let order = ORDER_DIRECTION_MAP[orderValue];
    const offset = pageSize * (current - 1);
    const limit = 10;
    let orderByField = { field };
    if (field && field.includes('partner_group_tap_feed_permission')) {
        orderByField = {
            field: 'partner_group_tap_feed_permission',
            id: columnKey,
        };
    }
    if (!order) {
        order = 'asc';
        orderByField = {
            field: 'full_name',
        };
    }
    const orderBy = orderByField;
    return {
        orderBy,
        order,
        offset,
        filter,
        search,
        withGlobalTags,
        limit,
    };
};
export const useUserTable = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const universityId = useAppSelector((state) => { var _a; return (_a = state.account.account_info) === null || _a === void 0 ? void 0 : _a.university_id; });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const university = useSelector(getUniversityInfo);
    const currentGroupUniversityId = useAppSelector(getCurrentGroupUniversityId);
    const _a = useSelector(getUsersSlice), { items: users, total, searchItems, usersMaxLimitReached, usersMaxLimitReachedText, usersTotals } = _a, usersRestData = __rest(_a, ["items", "total", "searchItems", "usersMaxLimitReached", "usersMaxLimitReachedText", "usersTotals"]);
    const isTableEmpty = users.length === 0 && searchItems.length === 0;
    const isTHEPartnerRegistration = checkIsInTHE(university) && university.isRegisteredPartner;
    const [gridSettings, setGridSettings] = useState(SETTINGS_INITIAL_STATE);
    const [newUsersCount, setNewUsersCount] = useState(0);
    const [ambassadorEmail, setAmbassadorEmail] = useState(search.split('ambassadorEmail=')[1]
        ? decodeURIComponent(search.split('ambassadorEmail=')[1]).split('&')[0]
        : null);
    const columnsConfig = useUsersColumnsConfig(isTHEPartnerRegistration, university.partnerGroups, usersTotals);
    useEffect(() => {
        const { before_filter: beforeFilter, after_filter: afterFilter } = usersTotals;
        const beforFilterTotal = beforeFilter ? beforeFilter.new_ambassadors_today : 0;
        const afterFilterTotal = afterFilter ? afterFilter.new_ambassadors_today : 0;
        setNewUsersCount(afterFilter ? afterFilterTotal : beforFilterTotal);
    }, [usersTotals]);
    useEffect(() => {
        if (!ambassadorEmail) {
            const params = createRequestParams(gridSettings);
            dispatch({
                type: USERS_INFO_REQUESTED,
                payload: params,
            });
        }
    }, [gridSettings, dispatch, currentGroupUniversityId]);
    useEffect(() => {
        if (!ambassadorEmail) {
            dispatch(getUserTableTotalsAction({
                filter: {},
            }));
        }
        else {
            const onSuccess = (data) => {
                var _a;
                setAmbassadorEmail(null);
                dispatch(openUserInfoModal({
                    userId: (_a = data.find((item) => item)) === null || _a === void 0 ? void 0 : _a.id,
                }));
            };
            dispatch(getUserTableTotalsAction({
                filter: {
                    searchItems: [ambassadorEmail],
                },
            }));
            dispatch({
                type: USERS_INFO_REQUESTED,
                payload: {
                    filter: {
                        searchItems: [ambassadorEmail],
                    },
                },
                meta: {
                    onSuccess,
                },
            });
        }
    }, [dispatch, currentGroupUniversityId]);
    const requestSearch = useCallback((items) => {
        dispatch(getUserTableTotalsAction({
            filter: {
                searchItems: items,
            },
        }));
        setGridSettings((prevState) => {
            var _a;
            return (Object.assign(Object.assign({}, prevState), { pagination: { current: 1, pageSize: ((_a = prevState === null || prevState === void 0 ? void 0 : prevState.pagination) === null || _a === void 0 ? void 0 : _a.pageSize) || 20 }, filter: {
                    searchItems: items,
                    university_id: universityId,
                } }));
        });
    }, [dispatch, universityId]);
    const handleTableChange = useCallback((pagination, filters, sorter) => {
        setGridSettings((prevState) => (Object.assign(Object.assign({}, prevState), { sorter,
            pagination })));
    }, []);
    const handleCloseMaxLimitPopup = useCallback(() => {
        dispatch({
            type: MAX_USERS_LIMIT_POPUP_TOGGLE,
        });
    }, [dispatch]);
    const dataSource = useMemo(() => users.map((item) => UserRowModel(Object.assign(Object.assign(Object.assign({}, item), usersRestData), { partnerGroups: university.partnerGroups }))), [users, usersRestData, university]);
    const pagination = useMemo(() => ({
        pageSize: 10,
        current: gridSettings.pagination.current,
        total: total,
        position: ['bottomCenter'],
        showSizeChanger: false,
        size: 'small',
    }), [total, gridSettings]);
    return {
        requestSearch,
        handleTableChange,
        searchItems,
        isTableEmpty,
        newUsersCount,
        columnsConfig,
        isTHEPartnerRegistration,
        dataSource,
        pagination,
        usersMaxLimitReached,
        usersMaxLimitReachedText,
        handleCloseMaxLimitPopup,
    };
};
