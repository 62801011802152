import { Button } from 'antd';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { pinMessage } from '../../redux/actions/chat/messagingActions';
import { OPEN_ACTION_MODAL } from '../../redux/actions/components/componentsActions';
import { getChatMessagesSlice } from '../../redux/selectors';
const PinMessageButton = ({ message }) => {
    const dispatch = useAppDispatch();
    const { pinnedMessages } = useAppSelector(getChatMessagesSlice);
    const onPin = useCallback(() => {
        if (pinnedMessages.length > 2) {
            dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'You’ve reached the limit of pinned messages. To pin this message, please, unpin at least one.',
                },
            });
        }
        else {
            dispatch(pinMessage({ message }));
        }
    }, [message, dispatch, pinnedMessages]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: "delete-button", type: "link", onClick: onPin, style: { padding: 0 }, disabled: pinnedMessages.some((item) => item.id === message.id) }, "Pin")));
};
export default PinMessageButton;
