import { prop } from 'ramda';
import { createSelector } from 'reselect';
import { getAccountSlice } from './common-selectors';
export const getAccountInfo = createSelector(getAccountSlice, (account) => account.account_info);
export const getAccountInfoId = createSelector(getAccountInfo, (accountInfo) => accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.id);
export const getUniversityInfo = createSelector(getAccountInfo, (accountInfo) => accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.university);
export const getIsGroupAdmin = createSelector(getAccountInfo, (accountInfo) => accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.isGroupAdmin);
export const getPlsWizardSetup = createSelector(getAccountInfo, (accountInfo) => accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.plsWizardSetup);
export const getUniversity = createSelector(getAccountSlice, prop('account_info'));
export const getCurrentGroupUniversityId = createSelector(getAccountSlice, (account) => account.currentGroupUniversityId);
export const selectUniversityId = createSelector(getAccountSlice, (account) => { var _a; return (_a = account.account_info) === null || _a === void 0 ? void 0 : _a.university.id; });
export const selectUniversityUrl = createSelector(getAccountSlice, (account) => { var _a; return (_a = account.account_info) === null || _a === void 0 ? void 0 : _a.university.university_url; });
export const selectAdminUniversityId = createSelector(getAccountSlice, (account) => { var _a; return account.currentGroupUniversityId || ((_a = account.account_info) === null || _a === void 0 ? void 0 : _a.university.id); });
export const selectActiveUnivercityId = (state) => { var _a; return state.account.currentGroupUniversityId || ((_a = state.account.account_info) === null || _a === void 0 ? void 0 : _a.university_id); };
export const saveSelectActiveUnivercityId = (state) => { var _a; return state.account.currentGroupUniversityId || ((_a = state.account.account_info) === null || _a === void 0 ? void 0 : _a.university_id) || 0; };
export const selectIsSascOrIdp = createSelector(getAccountInfo, (accountInfo) => (accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.isSasc) || (accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.isIdp));
export const selectIsIdpActivation = createSelector(getAccountSlice, (account) => account.showIdpActivation);
export const selectIdpStepActivation = (state) => {
    var _a, _b;
    const isSecondStepActivation = (_a = state.account.account_info) === null || _a === void 0 ? void 0 : _a.university.secondStepActivation;
    const isThirdStepActivation = (_b = state.account.account_info) === null || _b === void 0 ? void 0 : _b.university.thirdStepActivation;
    if (isThirdStepActivation) {
        return 2;
    }
    else if (isSecondStepActivation) {
        return 1;
    }
    else {
        return 0;
    }
};
