import Modal from 'antd/es/modal/Modal';
import React, { useRef } from 'react';
import { Button } from '../../../../../components/base/Button';
import { UploadContent } from './UploadContent';
export const UploadContentButton = () => {
    const [file, setFile] = React.useState(null);
    const inputRef = useRef(null);
    return (React.createElement("div", { className: "ml-auto" },
        React.createElement("label", { className: "block", htmlFor: "file-upload" },
            React.createElement(Button, { type: "secondary", size: "large", onClick: (event) => {
                    var _a;
                    // Click label to open file upload dialog
                    return (_a = event.currentTarget.parentElement) === null || _a === void 0 ? void 0 : _a.click();
                } }, "Upload Content")),
        React.createElement("input", { ref: inputRef, className: "sr-only", type: "file", name: "content", id: "file-upload", multiple: false, accept: "image/x-png,image/gif,image/jpeg,image/*,video/mp4,video/x-m4v,video/*", onChange: (event) => {
                const files = event.target.files;
                if (files) {
                    setFile(files[0]);
                }
            } }),
        file != null && (React.createElement(Modal, { open: true, centered: true, footer: null, closable: true, maskClosable: false, onCancel: () => {
                setFile(null);
                if (inputRef.current) {
                    inputRef.current.value = '';
                }
            } },
            React.createElement(UploadContent, { onDone: () => {
                    setFile(null);
                    if (inputRef.current) {
                        inputRef.current.value = '';
                    }
                }, file: file })))));
};
