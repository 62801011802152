import Modal from 'antd/es/modal';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/app/hooks/store';
import { useModalVisibility } from 'src/app/hooks/useModalVisibility';
import { PostShareForm } from './forms/PostsShareForm';
import { Button } from 'src/app/components/base/Button';
import { Permissions } from 'src/app/permissions/permissions';
import restrictedIcon from 'src/assets/svg/restricted.svg';
import { PermissionEnum } from 'src/types/general';
export const PostsShareModal = () => {
    const history = useHistory();
    const { hash, state: sharedPost } = useLocation();
    const visible = hash.startsWith('#share-post');
    const [mount, setMount] = useModalVisibility(visible);
    const post = useAppSelector((state) => {
        if (sharedPost)
            return sharedPost;
        const id = visible ? Number(hash.split('/')[1]) : null;
        if (Number.isNaN(id) || id == null)
            return null;
        return state.posts.posts.items.find((post) => post.id === id);
    });
    const closeModal = () => {
        history.replace({ hash: '' });
    };
    return mount ? (React.createElement(Modal, { open: visible, className: "preview-post-gallery", centered: true, closable: true, maskClosable: true, destroyOnClose: true, onCancel: closeModal, afterClose: setMount, footer: null, zIndex: 9999, title: "Share Post", width: "50vw" }, Permissions.can(PermissionEnum.ContentSharing) ? (post != null ? (React.createElement(PostShareForm, { post: post, onClose: closeModal })) : null) : (React.createElement("div", { className: "restricted-modal-body" },
        React.createElement("p", { className: "restricted-modal-header" }, "Oops, this feature is not supported by your license"),
        React.createElement("img", { src: restrictedIcon, className: "image-restricted", alt: "restricted" }),
        React.createElement("p", { className: "restricted-modal-text" }, "You have a THE Student Chat license. This functionality is only available for organizations that have TAP Full License. We'd love to support you in recruiting your best-fit students."),
        React.createElement("p", { className: "restricted-modal-text" }, "If you want to provide a similar to THE Student Chat experience to users on your institution's website, along with other useful features, please let us know, and we'll get back to you as soon as we can."),
        React.createElement(Button, { className: "mt-6", href: "https://www.theambassadorplatform.com/book-a-demo?hsCtaTracking=535c1b08-9e1c-44ad-88bf-f69dea284e5c%7Cc6f20b98-2f1a-4b99-b836-f56dd5c3d84a", rel: "noopener noreferrer", target: "_blank", size: "large" }, "Contact our team"))))) : null;
};
