import { Permissions } from 'src/app/permissions/permissions';
import { PermissionEnum } from 'src/types/general';
export const stepTitles = (step) => {
    switch (step) {
        case 0:
            return {
                title: 'Create your account',
                description: 'Complete your profile in order to start working with your dashboard',
                buttonText: 'Get started',
            };
        case 1:
            return {
                title: 'Profile setup',
                description: 'Let’s get aquatinted',
                buttonText: 'Continue',
            };
        case 2:
            return {
                title: 'Last step',
                description: 'Set up your password',
                buttonText: 'Get started',
            };
        default:
            return {
                title: '',
                description: '',
                buttonText: 'Continue',
            };
    }
};
export const pickFieldsToValidate = (step) => {
    if (step === 0)
        return ['name', 'lastName', 'email', 'phoneNumber', 'countryId', 'universityName'];
    if (step === 1)
        return ['jobTitle', 'priorities'];
    if (step === 2)
        return ['password', 'confirmPassword', 'terms'];
};
export const prepareBookSesionLink = (countryCode) => {
    const isIdpOnly = Permissions.can(PermissionEnum.IdpOnly, false);
    switch (countryCode) {
        case 'USA':
        case 'CAN':
            return 'https://meetings.hubspot.com/artie-lane';
        case 'AUS':
        case 'NZL':
        case 'IND':
            return isIdpOnly
                ? 'https://meetings.hubspot.com/nikhigginstap/meeting-with-nik'
                : 'https://meetings.hubspot.com/kirsty-o-sullivan/pls-australia-customers';
        default:
            return 'https://meetings.hubspot.com/joe-marzano';
    }
};
