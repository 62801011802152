var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Form } from 'antd';
import { uniq } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import API from 'src/app/api';
import { pluckIds } from 'src/app/common/utils/array-utils';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { useIdpPublishMutation } from 'src/app/queries/posts/idpContent';
import { CREATE_CONTENT_GROUP } from 'src/app/redux/actions/content/messagingActions';
import { ContentGroupTypeEnum } from 'src/types/general';
const prepareFilters = (filters) => {
    const filtersData = {};
    for (const key in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, key)) {
            const value = filters[key];
            if (Array.isArray(value) && value.length > 0) {
                filtersData[key] = value;
            }
            else if (typeof value === 'string' && key === 'type') {
                filtersData.isDomestic = value === 'domestic';
            }
        }
    }
    return filtersData;
};
export const useIdpContentGroup = ({ post } = {}) => {
    const [form] = Form.useForm();
    const [ambassadors, setAmbassadors] = useState([]);
    const [ambassadorsIds, setAmbassadorsIds] = useState([]);
    const [isAmbassadorsListLoading, setIsAmbassadorsListLoading] = useState(false);
    const [isCreateGroupDisabled, setIsCreateGroupDisabled] = useState(false);
    const [tags, setTags] = useState({
        jurneyStage: [],
        jurneyStep: [],
        destination: [],
        topicsTheme: [],
        topics: [],
        channel: [],
    });
    const [filters, setFilters] = useState({
        type: undefined,
        nationalities: [],
        universities: [],
        subjects: [],
    });
    const { groupType } = useAppSelector((state) => state.createContentGroupModal);
    const isBroadcast = groupType === ContentGroupTypeEnum.Broadcast;
    const dispatch = useAppDispatch();
    const { mutateAsync: publishContent } = useIdpPublishMutation();
    useEffect(() => {
        const filtersData = prepareFilters(filters);
        setIsAmbassadorsListLoading(true);
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const resp = yield API.dashboardGroup.getIdpContentGroupAmbassadors({
                    filter: filtersData,
                });
                setIsAmbassadorsListLoading(false);
                setAmbassadors(resp.data);
                setIsCreateGroupDisabled(resp.data.length === 0);
                setAmbassadorsIds(pluckIds(resp.data));
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        });
        if (!post) {
            fetchData();
        }
    }, [form, filters, post]);
    useEffect(() => {
        form.setFieldValue('tags', uniq([
            ...tags.jurneyStage,
            ...tags.destination,
            ...tags.jurneyStep,
            ...tags.topicsTheme,
            ...tags.topics,
            ...tags.channel,
        ]));
    }, [form, tags]);
    const onFinish = useCallback(() => {
        form.validateFields().then((values) => {
            const requestData = isBroadcast
                ? {
                    name: values.name,
                    description: values.description,
                    idpContentGroupType: ContentGroupTypeEnum.Broadcast,
                    ambassadorsIds,
                }
                : {
                    name: values.name,
                    description: values.description,
                    tags: values.tags,
                    type: values.contentType,
                    idpContentGroupType: ContentGroupTypeEnum.Content,
                    ambassadorsIds,
                };
            dispatch({
                type: CREATE_CONTENT_GROUP,
                payload: requestData,
            });
        });
    }, [form, dispatch, isBroadcast, ambassadorsIds]);
    const onPublishContent = useCallback(() => {
        form.validateFields().then((values) => {
            if (!(post === null || post === void 0 ? void 0 : post.id))
                return;
            publishContent({
                postId: post.id,
                tags: values.tags,
            });
        });
    }, [form, post === null || post === void 0 ? void 0 : post.id, publishContent]);
    const handleJurneyStepChange = useCallback((val, options) => {
        setTags((prevTags) => (Object.assign(Object.assign({}, prevTags), { jurneyStep: uniq([...[], ...val.map((item) => item.trim())]), jurneyStage: uniq([...[], ...options.map((item) => item.title)]) })));
    }, []);
    const handleTopicsOfInterestChange = useCallback((val, options) => {
        setTags((prevTags) => (Object.assign(Object.assign({}, prevTags), { topics: val, topicsTheme: uniq([...[], ...options.map((item) => item.title)]) })));
    }, []);
    const handleChannelChange = useCallback((val) => {
        setTags((prevTags) => (Object.assign(Object.assign({}, prevTags), { channel: val })));
    }, []);
    const handleDestinationChange = useCallback((val) => {
        setTags((prevTags) => (Object.assign(Object.assign({}, prevTags), { destination: val.map((item) => item.split('_')[0]) })));
    }, []);
    const handleAmbassadorsSelect = useCallback((ids) => {
        setAmbassadorsIds(ids);
        setIsCreateGroupDisabled(ids.length === 0);
    }, []);
    return {
        form,
        ambassadors,
        isAmbassadorsListLoading,
        isCreateGroupDisabled,
        onFinish,
        handleJurneyStepChange,
        handleTopicsOfInterestChange,
        handleChannelChange,
        handleDestinationChange,
        setFilters,
        handleAmbassadorsSelect,
        isBroadcast,
        onPublishContent,
    };
};
