var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Handler from './handler';
import { getAccessToken, refreshToken } from './helpers';
import store from 'src/app/redux/store';
import { ACCOUNT_LOGIN_SUCCESS } from 'src/app/redux/actions/account/accountActions';
import axios from 'axios';
import config from '../../config';
export const axiosInstance = axios.create({
    baseURL: config.api_host,
    headers: {
        'Content-Type': 'application/json',
    },
});
axiosInstance.interceptors.request.use((config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        // Set the Authorization header on the axios instance instead
    }
    return config;
}, (error) => Promise.reject(error));
let promise = null;
axiosInstance.interceptors.response.use((response) => response, (error) => __awaiter(void 0, void 0, void 0, function* () {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        if (!promise) {
            promise = refreshToken()
                .then((newAccessToken) => {
                store.dispatch({
                    type: ACCOUNT_LOGIN_SUCCESS,
                    payload: newAccessToken,
                });
                return newAccessToken;
            })
                .catch((refreshError) => {
                Handler.authError();
                return Promise.reject(refreshError);
            })
                .finally(() => {
                promise = null;
            });
        }
        return promise.then((newAccessToken) => {
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return axiosInstance(originalRequest);
        });
    }
    return Promise.reject(error);
}));
