import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
const initState = {
    bulkEnabled: false,
    selectedIds: new Set(),
    isLoading: false,
};
export const useGalleryBulkStore = create((set) => (Object.assign(Object.assign({}, initState), { updateSelected: (id, selected) => set((state) => {
        const newSet = new Set(state.selectedIds);
        selected ? newSet.add(id) : newSet.delete(id);
        return {
            selectedIds: newSet,
        };
    }), toggleBulk: (val) => set((state) => ({
        bulkEnabled: val !== null && val !== void 0 ? val : !state.bulkEnabled,
        selectedIds: new Set(),
    })), deselectAll: () => set({ selectedIds: new Set() }), reset: () => set(initState), setLoading: () => set({ isLoading: true }) })));
export const useBulkState = () => {
    return useGalleryBulkStore(useShallow((state) => ({
        bulkEnabled: state.bulkEnabled,
        selectedIds: state.selectedIds,
        isLoading: state.isLoading,
    })));
};
export const useBulkActions = () => {
    return useGalleryBulkStore(useShallow((state) => ({
        deselectAll: state.deselectAll,
        updateSelected: state.updateSelected,
        reset: state.reset,
        toggleBulk: state.toggleBulk,
        setLoading: state.setLoading,
    })));
};
