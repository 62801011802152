var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery } from '@tanstack/react-query';
import API from 'src/app/api';
import { isApiError } from 'src/app/helpers/guards';
import { getTopNItemsByKey } from './helpers';
export function useGetAmbassadorsQuery(enabled = false, data) {
    return useQuery({
        queryKey: ['getFilterAmbassadors', data === null || data === void 0 ? void 0 : data.published, data === null || data === void 0 ? void 0 : data.type, data === null || data === void 0 ? void 0 : data.universityId],
        queryFn() {
            return __awaiter(this, void 0, void 0, function* () {
                const res = yield API.posts.filters.getAmbassadors(data);
                return res.data.ambassadors;
            });
        },
        enabled,
        retry(failureCount, error) {
            var _a;
            if (isApiError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 500) {
                return false;
            }
            return failureCount < 2;
        },
        initialData: [],
        select(ambassadors) {
            const top5Ambassadors = getTopNItemsByKey(ambassadors, 'postsCount', 5);
            return [
                {
                    label: 'Top 5 ambassadors',
                    title: 'Top 5 ambassadors',
                    options: top5Ambassadors.map((ambassador) => ({
                        label: `${ambassador.name} ${ambassador.lastName}`,
                        value: ambassador.id,
                        key: `${ambassador.name}_${ambassador.id}`,
                        avatar: ambassador.avatar.sizes['160x160'] || ambassador.avatar.original,
                        subtitle: [ambassador.profileType, ambassador.courseName].join(', '),
                        trailing: ambassador.postsCount == 1 ? '1 result' : `${ambassador.postsCount} results`,
                    })),
                },
                {
                    label: 'All ambassadors',
                    title: 'All ambassadors',
                    options: ambassadors.map((ambassador) => ({
                        label: `${ambassador.name} ${ambassador.lastName}`,
                        value: ambassador.id,
                        avatar: ambassador.avatar.sizes['160x160'] || ambassador.avatar.original,
                        subtitle: [ambassador.profileType, ambassador.courseName].join(', '),
                        trailing: ambassador.postsCount == 1 ? '1 result' : `${ambassador.postsCount} results`,
                    })),
                },
            ];
        },
    });
}
