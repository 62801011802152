import React, { useCallback } from 'react';
import { Input, Button, Form } from 'antd';
const EditNameForm = ({ onSubmit, onCancel }) => {
    const [form] = Form.useForm();
    const onFinish = useCallback(() => {
        form.validateFields().then((values) => onSubmit(values, form));
    }, [form, onSubmit]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { form: form, name: "change_first_name", layout: "vertical", onFinish: onFinish, hideRequiredMark: true },
            React.createElement(Form.Item, { name: "firstName", label: "", className: "form-input", rules: [
                    { required: true, message: 'Add first name' },
                    { min: 2, message: 'First name should contain at least 2 symbols' },
                ] },
                React.createElement(Input, { className: "signin-input mod-hidden settings-input", placeholder: "First name" })),
            React.createElement(Button, { htmlType: "submit", className: "settings-btn", type: "link", style: { paddingLeft: 0 }, "data-automation-id": "submit-first-name" }, "Submit"),
            React.createElement(Button, { className: "settings-btn mod-cancel js-hide-form", type: "link", onClick: onCancel, "data-automation-id": "cancel-first-name-change" }, "Cancel"))));
};
export default EditNameForm;
