var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from 'src/app/api';
export const USERS_MESSAGING_INFO = 'USERS_MESSAGING_INFO';
export const USERS_MESSAGING_INFO_SUCCESS = 'USERS_MESSAGING_INFO_SUCCESS';
export const USERS_MESSAGING_INFO_FAILED = 'USERS_MESSAGING_INFO_FAILED';
export const AMBASSADORS_INFO = 'AMBASSADORS_INFO';
export const AMBASSADORS_INFO_SUCCESS = 'AMBASSADORS_INFO_SUCCESS';
export const AMBASSADORS_INFO_FAILED = 'AMBASSADORS_INFO_FAILED';
export const OPEN_CONTENT_GROUP_POPUP = 'OPEN_CONTENT_GROUP_POPUP';
export const CLOSE_CONTENT_GROUP_POPUP = 'CLOSE_CONTENT_GROUP_POPUP';
export const CREATE_CONTENT_GROUP = 'CREATE_CONTENT_GROUP';
export const CREATE_CONTENT_GROUP_SUCCESS = 'CREATE_CONTENT_GROUP_SUCCESS';
export const CREATE_CONTENT_GROUP_FAILED = 'CREATE_CONTENT_GROUP_FAILED';
export const CONTENT_GROUPS_INFO = 'CONTENT_GROUPS_INFO';
export const CONTENT_GROUPS_INFO_SUCCESS = 'CONTENT_GROUPS_INFO_SUCCESS';
export const CONTENT_GROUPS_INFO_FAILED = 'CONTENT_GROUPS_INFO_FAILED';
export const DIALOG_MESSAGES_INFO = 'DIALOG_MESSAGES_INFO';
export const DIALOG_MESSAGES_INFO_SUCCESS = 'DIALOG_MESSAGES_INFO_SUCCESS';
export const DIALOG_MESSAGES_INFO_FAILED = 'DIALOG_MESSAGES_INFO_FAILED';
export const VIDEO_TRANSCODE = 'VIDEO_TRANSCODE';
export const VIDEO_TRANSCODE_SUCCESS = 'VIDEO_TRANSCODE_SUCCESS';
export const VIDEO_TRANSCODE_FAILED = 'VIDEO_TRANSCODE_FAILED';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';
export const EDIT_CONTENT_GROUP = 'EDIT_CONTENT_GROUP';
export const EDIT_CONTENT_GROUP_SUCCESS = 'EDIT_CONTENT_GROUP_SUCCESS';
export const EDIT_CONTENT_GROUP_FAILED = 'EDIT_CONTENT_GROUP_FAILED';
export const CLOSE_CONTENT_GROUP = 'CLOSE_CONTENT_GROUP';
export const CLOSE_CONTENT_GROUP_SUCCESS = 'CLOSE_CONTENT_GROUP_SUCCESS';
export const CLOSE_CONTENT_GROUP_FAILED = 'CLOSE_CONTENT_GROUP_FAILED';
export const CLEAR_CONTENT_GROUP = 'CLEAR_CONTENT_GROUP';
export const OPEN_UPLOAD_FILE_POPUP = 'OPEN_UPLOAD_FILE_POPUP';
export const SET_ACTIVE_CONTENT_GROUP = 'SET_ACTIVE_CONTENT_GROUP';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
export const RELOAD_CONTENT_GROUP = 'RELOAD_DIALOG';
export const RELOAD_CONTENT_GROUP_SUCCESS = 'RELOAD_DIALOG_SUCCESS';
export const RELOAD_CONTENT_GROUP_FAILED = 'RELOAD_DIALOG_FAILED';
// export const VIEW_DIALOGS = "VIEW_DIALOGS";
export const EDIT_MESSAGE_POST_GLOBAL_TAG = 'EDIT_MESSAGE_POST_GLOBAL_TAG';
export const EDIT_MESSAGE_POST_GLOBAL_TAG_FINISH = 'EDIT_MESSAGE_POST_GLOBAL_TAG_FINISH';
export const RESET_DIALOG_MESSAGES_COUNTER = 'RESET_DIALOG_MESSAGES_COUNTER';
export const EDIT_MESSAGE_POST_CAPTION_SUCCESS = 'EDIT_MESSAGE_POST_CAPTION_SUCCESS';
export const DELETE_CONTENT_MESSAGE = 'DELETE_CONTENT_MESSAGE';
export const LOAD_OLD_MESSAGES_SUCCESS = 'LOAD_OLD_MESSAGES_SUCCESS';
export const getAmbassadorsInfo = createAction(AMBASSADORS_INFO);
export const getNewAmbassadorsInfoAsync = createAsyncThunk('AMBASSADORS_INFO_REQUESTED_NEW', (payload_1, _a) => __awaiter(void 0, [payload_1, _a], void 0, function* (payload, { getState }) {
    const state = getState();
    const universityIds = state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null;
    return API.dashboardNew.universityAmbassadors(Object.assign(Object.assign({}, payload), { universityIds }));
}));
