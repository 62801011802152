import { StrictMap } from 'src/types/StrictMap';
import store, { startAppListening } from '../redux/store';
import { idpIELTSEnabled } from './tmp';
import { Feature } from './types';
export class FeatureFlags {
    constructor() {
        var _a;
        this.map = new StrictMap();
        if (!store) {
            throw new Error('Account store is not initialized');
        }
        const { account } = store.getState();
        this.set({
            key: Feature.IsIdpIELTS,
            enabled: idpIELTSEnabled(((_a = account.account_info) === null || _a === void 0 ? void 0 : _a.university.id) || 0),
        });
        startAppListening({
            predicate: (_, currentState, originalState) => {
                var _a, _b;
                return (((_a = currentState.account.account_info) === null || _a === void 0 ? void 0 : _a.university.id) !=
                    ((_b = originalState.account.account_info) === null || _b === void 0 ? void 0 : _b.university.id));
            },
            effect: (_, api) => {
                var _a;
                const { account } = api.getState();
                if ((_a = account.account_info) === null || _a === void 0 ? void 0 : _a.university.id) {
                    this.set({
                        key: Feature.IsIdpIELTS,
                        enabled: idpIELTSEnabled(account.account_info.university.id),
                    });
                }
            },
        });
    }
    get(key) {
        return this.map.get(key);
    }
    set(value) {
        this.map.set(value.key, value);
    }
    static get instance() {
        if (!this._instance) {
            this._instance = new this();
        }
        return this._instance;
    }
    /**
     * @throws Error if flag is not found
     */
    static get(key) {
        const f = this.instance.get(key);
        if (!f) {
            throw new Error(`Flag ${key} not found`);
        }
        return f;
    }
    static set(flag) {
        this.instance.set(flag);
    }
    static isEnabled(key) {
        var _a, _b;
        return (_b = (_a = this.instance.get(key)) === null || _a === void 0 ? void 0 : _a.enabled) !== null && _b !== void 0 ? _b : false;
    }
}
