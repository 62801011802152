import React from 'react';
export function textAreaCountFormatter({ count, maxLength }) {
    if (maxLength == null) {
        return count;
    }
    const remaining = maxLength - count;
    return (React.createElement("span", { className: remaining < 20 ? 'text-red-500' : undefined },
        remaining,
        "/",
        maxLength));
}
