import React from 'react';
import { ReactComponent as CloseIcon } from 'src/assets/svg/icons/close.svg';
import { Icon } from '../base/Icon';
import { concatClsx } from 'src/app/helpers/classNames';
import './modal.scss';
export function getModalProps(props) {
    return Object.assign(Object.assign({ closeIcon: React.createElement(CloseIcon, null), closable: true, width: 550, centered: true, footer: null, icon: null }, props), { className: concatClsx('tap-event-modal', props.className) });
}
export function getSuccessModalConfig(message) {
    return getModalProps({
        icon: React.createElement(Icon, { className: "w-6 h-6 pr-2", icon: "check" }),
        content: React.createElement("h3", { className: "text-header-sm" }, message),
    });
}
