import { Progress, Typography } from 'antd';
import React from 'react';
const { Text } = Typography;
const UploadModalBody = ({ videoProgressStart, videoFileName, videoProgress }) => {
    return (React.createElement("section", { className: "re-upload-modal-body" },
        React.createElement(Text, null, `Select the video you want to re-upload. Depending on the size of the file, it can take a few minutes
                    for it to upload on the platform.`),
        videoProgressStart && React.createElement(Text, { className: "file-name" }, videoFileName),
        React.createElement(Text, { className: "hint" }, `Don’t leave the page until the upload is completed.`),
        videoProgressStart && (React.createElement(Progress, { strokeLinecap: "butt", percent: videoProgress, strokeColor: {
                '0%': '#ff5100',
                '100%': '#ff5100',
            } }))));
};
export default UploadModalBody;
