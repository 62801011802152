/* eslint-disable camelcase */
import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { Permissions } from 'src/app/permissions/permissions';
import { PermissionEnum } from 'src/types/general';
import { CellTitle, CopyLinkCell, DeleteUserCell, LastActiveCell, NameCell, PartnerGroupCellTitle, ToggleCell, } from './column-components';
const { Text } = Typography;
export const useUsersColumnsConfig = (isTHEPartner, partnerGroups, usersTotals) => {
    return useMemo(() => {
        let cells = [
            {
                title: React.createElement(CellTitle, { text: "Ambassadors", record: usersTotals, titleKey: "total_ambassadors" }),
                dataIndex: 'full_name',
                key: 'fullName',
                sorter: true,
                render: NameCell,
            },
            {
                title: React.createElement(Text, { className: "text-submenu-lg" }, "Last active"),
                dataIndex: 'last_activity',
                key: 'lastActivity',
                sorter: true,
                render: LastActiveCell,
            },
        ];
        if (Permissions.can(PermissionEnum.IdpCommunity, false)) {
            cells.push({
                title: React.createElement(Text, { className: "text-submenu-lg" }, "IDP P2P Community"),
                dataIndex: 'idp_community_permission',
                render: (data, record) => (React.createElement(ToggleCell, { columnName: "idpCommunity", data: data, record: record, permissionId: data === null || data === void 0 ? void 0 : data.id, permissionKey: PermissionEnum.IdpCommunity })),
            });
        }
        if (Permissions.can(PermissionEnum.IdpLiveApp, false)) {
            cells.push({
                title: React.createElement(Text, { className: "text-submenu-lg" }, "IDP Live App"),
                dataIndex: 'idp_live_app_permission',
                render: (data, record) => (React.createElement(ToggleCell, { columnName: "idpLiveApp", data: data, record: record, permissionId: data === null || data === void 0 ? void 0 : data.id, permissionKey: PermissionEnum.IdpLiveApp })),
            });
        }
        if (Permissions.can(PermissionEnum.IdpWhatUni, false)) {
            cells.push({
                title: React.createElement(Text, { className: "text-submenu-lg" }, "IDP WhatUni"),
                dataIndex: 'idp_what_uni_permission',
                render: (data, record) => (React.createElement(ToggleCell, { columnName: "idpWhatUni", data: data, record: record, permissionId: data === null || data === void 0 ? void 0 : data.id, permissionKey: PermissionEnum.IdpWhatUni })),
            });
        }
        if (partnerGroups && partnerGroups.length > 0) {
            partnerGroups.map((group) => cells.push({
                title: (React.createElement(PartnerGroupCellTitle, { text: group.name, record: usersTotals, groupId: group.id, titleKey: "total_partner_group_tap_feeds" })),
                dataIndex: `partner_group_tap_feed_permission_${group.id}`,
                key: group.id,
                sorter: true,
                render: (data, record) => (React.createElement(ToggleCell, { columnName: "chatPartnerCheckbox", data: data, record: record, permissionId: group.id, permissionKey: PermissionEnum.Chat })),
            }));
        }
        if (!isTHEPartner) {
            cells.push({
                title: React.createElement(CellTitle, { text: "Tap Feed", record: usersTotals, titleKey: "total_university_tap_feed" }),
                dataIndex: 'university_tap_feed_permission',
                sorter: true,
                render: (data, record) => (React.createElement(ToggleCell, { columnName: "chatUniCheckbox", data: data, record: record, permissionId: data.id, permissionKey: PermissionEnum.Chat })),
            });
        }
        const restCells = [
            {
                title: React.createElement(CellTitle, { text: "Chat", record: usersTotals, titleKey: "total_chat" }),
                dataIndex: 'chat_permission',
                sorter: true,
                render: (data, record) => (React.createElement(ToggleCell, { columnName: "chatCheckbox", data: data, record: record, permissionId: data.id, permissionKey: PermissionEnum.Chat })),
            },
            {
                title: React.createElement(CellTitle, { text: "Content", record: usersTotals, titleKey: "total_content" }),
                dataIndex: 'content_permission',
                sorter: true,
                render: (data, record) => (React.createElement(ToggleCell, { columnName: "contentCheckbox", data: data, record: record, permissionId: data.id, permissionKey: PermissionEnum.Content })),
            },
            {
                title: React.createElement(CellTitle, { text: "FAQ", record: usersTotals, titleKey: "total_faq" }),
                dataIndex: 'faq_permission',
                sorter: true,
                render: (data, record) => (React.createElement(ToggleCell, { columnName: "faqCheckbox", data: data, record: record, permissionId: data.id, permissionKey: PermissionEnum.Faq })),
            },
            {
                title: React.createElement(Text, { className: "text-submenu-lg" }, "Link"),
                dataIndex: 'link',
                key: 'link',
                render: CopyLinkCell,
                width: 130,
            },
            {
                title: '',
                dataIndex: 'delete',
                key: 'delete',
                render: DeleteUserCell,
            },
        ];
        cells = [...cells, ...restCells];
        return cells;
    }, [isTHEPartner, partnerGroups, usersTotals]);
};
