import { Empty, Spin } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Virtuoso } from 'react-virtuoso';
import { clearItems, getMorePostsAsync, getNewPostsAsync, getPosts } from 'src/app/redux/actions/content/postsActions';
import { toMapPairs } from 'src/app/utils/list';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { usePusher } from '../../../../../hooks/usePusher';
import { selectUniversityId } from '../../../../../redux/selectors/account-selectors';
import { selectPosts, selectPostsFilters } from '../../../../../redux/selectors/posts-selectors';
import { LoadingFooter } from './LoadingFooter';
import { NewPostsButton } from './NewPostsButton';
import { PostsGroupItem } from './PostsGroupItem';
import { useGalleryBulkStore } from './bulk-state';
export const PostsList = () => {
    const dispatch = useAppDispatch();
    const adminUniversityId = useAppSelector(selectUniversityId);
    const { isLoading, hasMore, total } = useAppSelector(selectPosts);
    const filters = useAppSelector(selectPostsFilters);
    const reset = useGalleryBulkStore((state) => state.reset);
    const uiData = useAppSelector((state) => state.posts.uiData);
    const accountInfo = useAppSelector((state) => state.account.account_info);
    const history = useHistory();
    const scrollTop = useRef(0);
    const virtuoso = useRef(null);
    const [postGroups, setPostGroups] = useState([]);
    const [showNewPostsButton, setShowNewPostsButton] = useState(false);
    useEffect(() => {
        setPostGroups(toMapPairs(uiData));
    }, [uiData]);
    useEffect(() => {
        dispatch(getPosts({
            filter: filters,
            limit: 20,
            offset: 0,
            withGlobalTags: true,
            sort: {
                created_at: 'desc',
            },
        }));
        reset();
        return () => {
            dispatch(clearItems());
        };
    }, [dispatch, filters, reset]);
    const loadMore = useCallback(() => {
        if (!total || !hasMore)
            return;
        dispatch(getMorePostsAsync());
    }, [dispatch, total, hasMore]);
    const handleAtBottomStateChange = useCallback((atBottom) => {
        if (atBottom && !isLoading && hasMore) {
            loadMore();
        }
    }, [loadMore, hasMore, isLoading]);
    usePusher(`posts:new_exists:${adminUniversityId}`, () => {
        if (Object.values(filters).filter(Boolean).length === 0) {
            dispatch(getNewPostsAsync()).then(() => {
                if (scrollTop.current > 200) {
                    setShowNewPostsButton(true);
                }
            });
        }
    });
    const handleScroll = useCallback((event) => {
        scrollTop.current = event.currentTarget.scrollTop || 0;
        if (showNewPostsButton && scrollTop.current < 50) {
            setShowNewPostsButton(false);
        }
    }, [showNewPostsButton]);
    const onPostClick = (post) => {
        history.push({
            hash: `#post-preview/${post.id}`,
        });
    };
    const onShareClick = (post) => {
        history.push({
            hash: `#share-post/${post.id}`,
            state: post,
        });
    };
    if (isLoading && postGroups.length === 0) {
        return (React.createElement("div", { className: "flex flex-1 items-center justify-center p-6" },
            React.createElement(Spin, { size: "large" })));
    }
    return postGroups.length === 0 ? (React.createElement("div", { className: "flex flex-1 items-center justify-center p-6 text-2xl font-semibold" },
        React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: "No posts found" }))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "list px-5 pb-6" },
            React.createElement(Virtuoso, { ref: virtuoso, totalCount: postGroups.length, endReached: loadMore, components: { Footer: hasMore ? LoadingFooter : undefined }, onScroll: handleScroll, atBottomStateChange: handleAtBottomStateChange, itemContent: (index) => {
                    return (React.createElement(PostsGroupItem, { title: postGroups[index][0], posts: postGroups[index][1], onPostClick: onPostClick, onShareClick: onShareClick, allowSharing: !(accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.isIdpGroupAdmin) }));
                } })),
        React.createElement(NewPostsButton, { showButton: showNewPostsButton, onClick: () => {
                var _a;
                setShowNewPostsButton(false);
                (_a = virtuoso.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                    behavior: 'smooth',
                    top: 0,
                });
            } })));
};
