import React, { useCallback } from 'react';
import ReactPlayer from 'react-player';
import { Button } from 'src/app/components/base/Button';
import { useAppSelector } from 'src/app/hooks/store';
import { getAccountInfo, selectIsIdpActivation } from 'src/app/redux/selectors';
import { useStepsData } from '../StepsProvider';
export const StepOne = () => {
    var _a;
    const [isPlayPressed, setIsPlayPressed] = React.useState(false);
    const accountInfo = useAppSelector(getAccountInfo);
    const isIdpActivation = useAppSelector(selectIsIdpActivation);
    const { setCurrentStep } = useStepsData();
    const onPlay = () => {
        if (!isPlayPressed) {
            setIsPlayPressed(true);
        }
    };
    const onNext = useCallback(() => {
        setCurrentStep(1);
    }, [setCurrentStep]);
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", { className: "text-header-xl font-interface-bold pb-6" }, "TAP x IDP Joint Services"),
        React.createElement("div", { className: "mx-23" },
            React.createElement("p", null,
                "Hi ", accountInfo === null || accountInfo === void 0 ? void 0 :
                accountInfo.name,
                "!"),
            React.createElement("p", { className: "my-6" }, (_a = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.university) === null || _a === void 0 ? void 0 :
                _a.name,
                ", as a partner of both The Ambassador Platform and IDP Education, can activate the following P2P service(s) at no additional cost - forever. This will not affect your normal TAP activities, and we'll never share your data with anyone."),
            React.createElement("p", { className: "mb-12" }, "Check the video and links below to learn more."),
            React.createElement("div", { className: "flex flex-col items-center" },
                React.createElement(ReactPlayer, { url: "https://vimeo.com/943974706?share=copy", playing: false, className: "mb-8 !h-[40rem]", pip: true, playsinline: true, loop: true, controls: true, config: {
                        file: {
                            attributes: {
                                controlsList: 'nodownload',
                                pip: 'true',
                            },
                        },
                    }, onPlay: onPlay }),
                React.createElement("div", { className: "bg-brand-gray rounded-xl min-w-[64rem]" },
                    React.createElement("ul", { className: "grid gap-7 px-8 py-9 list-disc" },
                        React.createElement("li", { className: "underline" },
                            React.createElement("a", { rel: "noopener noreferrer", target: "_blank", className: "faq-link", href: "https://knowledge.theambassadorplatform.com/tapxidp-joint-p2p-services-faqs#value-of-joint-services" }, "What's the value of these services?")),
                        React.createElement("li", null,
                            React.createElement("a", { rel: "noopener noreferrer", target: "_blank", className: "faq-link", href: "https://knowledge.theambassadorplatform.com/tapxidp-joint-p2p-services-faqs#cost-of-js" }, "Do I have to pay extra?"),
                            "\u00A0 Disclaimer: No"),
                        React.createElement("li", null,
                            React.createElement("a", { rel: "noopener noreferrer", target: "_blank", className: "faq-link", href: "https://knowledge.theambassadorplatform.com/tapxidp-joint-p2p-services-faqs#time-on-js" }, "What's the time commitment for ambassadors on these services?")))))),
        React.createElement(Button, { disabled: !isPlayPressed && isIdpActivation, className: "mt-8 mb-6 process-btn", onClick: onNext }, "Continue"),
        !isPlayPressed && isIdpActivation && React.createElement("p", null, "Please, click on the video first in order to continue")));
};
