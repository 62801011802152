import { axiosInstance } from './client'

import config from '../../config'
import handler from './handler'

export const defaults = {
    debug: true,
    api_host_clear: config.api_host_clear,
    api_host: config.api_host,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
}

export const getConfigData = (withCredentials, data, formData) => {
    const configData = {
        headers: { ...defaults.headers },
        withCredentials: !!withCredentials,
    }

    if (data) {
        // GET
        configData.headers['Content-Type'] = 'text/plain'
        configData.params = data
    }
    if (formData) {
        configData.headers['Content-Type'] = 'multipart/form-data'
    }

    const accessToken = window.localStorage.getItem('Account')

    if (accessToken) {
        configData.headers.Authorization = `Bearer ${JSON.parse(accessToken)}`
    }
    return configData
}

const getOnThen = (response) => {
    if (!response.data.status && response.data.error) {
        handler.errorHandler(response)
        return {
            success: false,
        }
    } else {
        return response.data
    }
}

const getOnCatch = (err, rethrow) => {
    console.log(err)
    handler.errorHandler(err)
    if (rethrow) {
        throw err
    }
    return err.response && err.response.data
        ? {
              errorData: err.response.data,
              success: false,
          }
        : {
              success: false,
          }
}

class AuthService {
    // eslint-disable-next-line no-useless-constructor
    constructor() {}

    /**
     * @template [Res=any]
     * @template [Req=any]
     * @param {string} path
     * @param {Req} [data]
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res> | ErrorResponse>}
     */
    static get(path, data, withCredentials = false) {
        return axiosInstance
            .get(defaults.api_host + path, getConfigData(withCredentials, data))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }

    /**
     * @template [Res=any]
     * @template [Req=any]
     * @param {string} path
     * @param {Req} data
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res> | ErrorResponse>}
     */
    static post(path, data, withCredentials = false) {
        return axiosInstance
            .post(defaults.api_host + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }

    /**
     * @template [Res=any]
     * @template [Req=any]
     * @param {string} path
     * @param {Req} [data]
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res> | ErrorResponse>}
     */
    static put(path, data, withCredentials = false) {
        return axiosInstance
            .put(defaults.api_host + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }

    static delete(path, data) {
        return axiosInstance
            .delete(defaults.api_host + path, { headers: defaults.headers, data })
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }

    /**
     * @template [Res=any]
     * @param {string} path
     * @param {FormData} data
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res> | ErrorResponse>}
     */
    static formData(path, data, withCredentials = false) {
        return axiosInstance
            .post(defaults.api_host + path, data, getConfigData(withCredentials, null, true))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }

    static postGlobal(path, data, withCredentials = false) {
        return axiosInstance
            .post(defaults.api_host + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    }
}

export default AuthService

export const HttpService = {
    /**
     * @template [Res=undefined]
     * @template [Req]
     * @param {string} path
     * @param {Req} [data]
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res>>}
     */
    get(path, data, withCredentials = false) {
        return axiosInstance
            .get(defaults.api_host + path, getConfigData(withCredentials, data))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error, true))
    },

    /**
     * @template [Res=undefined]
     * @template [Req]
     * @param {string} path
     * @param {Req} [data]
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res> | ErrorResponse>}
     */
    safeGet(path, data, withCredentials = false) {
        return axiosInstance
            .get(defaults.api_host + path, getConfigData(withCredentials, data))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error))
    },

    /**
     * @template [Res=undefined]
     * @param {string} path - The API endpoint to which the request will be sent.
     * @param {FormData} data - The FormData object containing the request payload.
     * @param {string} [method='POST'] - The HTTP method (e.g., 'POST', 'PUT').
     * @param {boolean} [withCredentials=false] - Whether to include credentials with the request.
     * @returns {Promise<SuccessResponse<Res>>} - A Promise that resolves to the response.
     */
    formData(path, data, method = 'POST', withCredentials = false) {
        return axiosInstance({
            method, // Dynamically set HTTP method (e.g., 'POST', 'PUT')
            url: defaults.api_host + path,
            data,
            ...getConfigData(withCredentials, null, true),
        })
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error, true))
    },
    /**
     * @template [Res=undefined]
     * @template [Req=any]
     * @param {string} path
     * @param {Req} data
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res>>}
     */
    post(path, data, withCredentials = false) {
        return axiosInstance
            .post(defaults.api_host + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error, true))
    },
    /**
     * @template [Res=undefined]
     * @template [Req=any]
     * @param {string} path
     * @param {Req} [data]
     * @param {boolean} withCredentials
     * @returns {Promise<SuccessResponse<Res>>}
     */
    put(path, data, withCredentials = false) {
        return axiosInstance
            .put(defaults.api_host + path, data, getConfigData(withCredentials))
            .then((response) => getOnThen(response))
            .catch((error) => getOnCatch(error, true))
    },
}
