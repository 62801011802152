import React, { useEffect, useRef, useState } from 'react';
import { stopPropagation } from 'src/app/helpers/functions';
import { useAppSelector } from 'src/app/hooks/store';
import { useGetAmbassadorsQuery } from 'src/app/queries/posts/filters';
import { selectUniversityId } from 'src/app/redux/selectors/account-selectors';
import { Button } from '../../../../../../components/base/Button';
import { UserSelect } from '../../../../../../components/common/UserSelect';
export const UserMultiselect = ({ placeholder, onChange, popupMatchSelectWidth, size, className, filters, }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState([]);
    const prevValueRef = useRef([]);
    const openRef = useRef(open);
    const adminUniversityId = useAppSelector(selectUniversityId);
    const { data, isFetching } = useGetAmbassadorsQuery(open, {
        universityId: adminUniversityId,
        published: filters.published,
        type: filters.type,
        limit: 200,
    });
    useEffect(() => {
        if (!openRef.current && value != prevValueRef.current) {
            onChange === null || onChange === void 0 ? void 0 : onChange(value);
        }
    }, [value, onChange]);
    const handleApplyClick = () => {
        prevValueRef.current = [...value];
        setOpen(false);
        openRef.current = false;
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    };
    const handleDropdownVisibleChange = (visible) => {
        setOpen(visible);
        openRef.current = visible;
        if (!visible) {
            setValue(prevValueRef.current);
        }
        else {
            prevValueRef.current = value;
        }
    };
    return (React.createElement(UserSelect, { multiple: true, size: size, open: open, value: value, users: data, className: className, placeholder: placeholder, popupMatchSelectWidth: popupMatchSelectWidth, onChange: setValue, onDropdownVisibleChange: handleDropdownVisibleChange, loading: isFetching, filterOption: (input, option) => {
            if (option === null || option === void 0 ? void 0 : option.options) {
                return false;
            }
            return option === null || option === void 0 ? void 0 : option.label.toLowerCase().includes(input.toLowerCase());
        }, dropdownRender: (menu) => {
            return (React.createElement(React.Fragment, null,
                menu,
                React.createElement(Button, { className: "mt-2 w-full", onKeyDown: stopPropagation, onClick: handleApplyClick }, "Apply")));
        } }));
};
