export default (chat) => {
    const { id, name, type, typ_key, university_id, reported, description, welcomeMessage, isHidden = false } = chat;
    return {
        id,
        name,
        type,
        typ_key,
        university_id,
        reported,
        description,
        welcomeMessage,
        avatar: chat.avatar,
        isHidden,
    };
};
