import React from 'react';
export const MessageContent = ({ text }) => {
    if (!text)
        return null;
    const hasBadWord = text.includes('[badword]') && text.includes('[/badword]');
    const hasLink = text.includes('[link]') && text.includes('[/link]');
    if (!hasLink && !hasBadWord) {
        return React.createElement(React.Fragment, null, text);
    }
    const combinedParts = text.split(/(\[badword].*?\[\/badword])|(\[link].*?\[\/link])/g);
    return (React.createElement(React.Fragment, null, combinedParts.map((part, index) => {
        if (!part)
            return null;
        if (part.startsWith('[badword]') && part.endsWith('[/badword]')) {
            const badWord = part.replace('[badword]', '').replace('[/badword]', '');
            return (React.createElement("span", { className: "bad-word", key: index }, badWord));
        }
        if (part.startsWith('[link]') && part.endsWith('[/link]')) {
            const link = part.replace('[link]', '').replace('[/link]', '');
            let hyperlink = link;
            if (!link.startsWith('http://') && !link.startsWith('https://')) {
                hyperlink = `https://${link}`;
            }
            return (React.createElement("a", { key: index, target: "_blank", href: hyperlink, rel: "noopener noreferrer" }, link));
        }
        return part;
    })));
};
