var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Handler from '../services/HttpService/handler';
import { axiosInstance } from '../services/HttpService/client';
export const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) => (_a) => __awaiter(void 0, [_a], void 0, function* ({ url, method, data, params, headers }) {
    var _b;
    try {
        const result = yield axiosInstance({
            url: baseUrl + url,
            method,
            data,
            params,
            headers,
        });
        return result.data;
    }
    catch (axiosError) {
        const err = axiosError;
        Handler.errorHandler(err);
        return {
            error: ((_b = err.response) === null || _b === void 0 ? void 0 : _b.data) || err.message,
        };
    }
});
