var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useId } from 'react';
import { clsx } from 'src/app/helpers/classNames';
import './styles.scss';
export const ButtonCheckbox = (_a) => {
    var { label, name, className, variant = 'default', onChange, checked, defaultChecked, disabled } = _a, rest = __rest(_a, ["label", "name", "className", "variant", "onChange", "checked", "defaultChecked", "disabled"]);
    const id = useId();
    const handleChange = (event) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(event.currentTarget.checked, event);
    };
    return (React.createElement("div", Object.assign({ className: clsx(`button-checkbox relative chx-${variant}`, disabled && 'pointer-events-none opacity-50', className), "data-role": "button-checkbox", "aria-disabled": disabled }, rest),
        React.createElement("input", { id: id, type: "checkbox", checked: checked, defaultChecked: defaultChecked, className: "absolute inset-0 cursor-pointer opacity-0", name: name, onChange: handleChange, disabled: disabled, "aria-disabled": disabled }),
        React.createElement("label", { "aria-disabled": disabled, htmlFor: id, className: "inline-block px-6 py-2 text-16", role: "button" }, label)));
};
