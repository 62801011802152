import { Modal } from 'antd';
import React from 'react';
import { useModalVisibility } from 'src/app/hooks/useModalVisibility';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { CLOSE_CONTENT_GROUP_POPUP } from 'src/app/redux/actions/content/messagingActions';
import { IdpContentGroupForm } from './forms/idp-content-group-form';
export const IdpContentGroupModal = () => {
    const { isOpened: createOpened } = useAppSelector((state) => state.createContentGroupModal);
    const dispatch = useAppDispatch();
    const visible = createOpened;
    const [mount, setMount] = useModalVisibility(visible);
    const closeModal = () => {
        dispatch({
            type: CLOSE_CONTENT_GROUP_POPUP,
        });
    };
    return mount ? (React.createElement(Modal, { open: visible, className: "react-create-content-group", centered: true, closable: true, maskClosable: true, destroyOnClose: true, onCancel: closeModal, afterClose: setMount, footer: null },
        React.createElement(IdpContentGroupForm, null))) : null;
};
