import { HttpService } from '../../system/services/HttpService';
function dialogSearch(data) {
    return HttpService.get('/dashboard/dialogs/search', data);
}
export default {
    dialogSearch,
    sendMessage: (payload) => {
        return HttpService.post(`/dashboard/dialogs/${payload.dialog_id}/messages/send`, payload);
    },
    sendGroupMessage: (payload) => {
        return HttpService.post(`/dashboard/dialogs/${payload.dialog_id}/messages/sendGroupMessage`, payload);
    },
    getDialogSelectedAmbassadors: (id) => {
        return HttpService.get(`/dashboard/dialogs/${id}/ambassadorIds`);
    },
    getDialogNewMessagesCount: (id) => {
        return HttpService.safeGet(`/dashboard/dialogs/${id}/messages/newCount`);
    },
};
