import { call, put, select } from 'redux-saga/effects'
import API from '../../../api'
import {
    CLOSE_CONFIRM_DELETE_POPUP,
    CLOSE_SHARE_POST_POPUP,
    EDIT_POST_CAPTION_FAILED,
    EDIT_POST_CAPTION_SUCCESS,
    EDIT_POST_GLOBAL_TAG_FINISH,
    POSTS_DELETE_FAILED,
    POSTS_DELETE_SUCCESS,
    POSTS_INFO_FAILED,
    POSTS_INFO_SUCCESS,
    PUBLISH_OR_UNPUBLISH_POST_SUCCESS,
    PUBLISH_POST_FAILED,
    SHARE_POST_FAILED,
    SHARE_POST_SUCCESS,
    UPDATE_SHARED_POST,
    UPLOAD_FILE_INFO_FAILED,
    UPLOAD_FILE_INFO_SUCCESS,
    USERS_POSTS_FAILED,
    USERS_POSTS_REQUESTED,
    USERS_POSTS_SUCCESS,
} from '../../actions/content/postsActions'
import store from '../../store'

import { openNotificationModal } from '../../actions/notification-modal/notification-modal-actions'

import { CLOSE_ACTION_MODAL, OPEN_ACTION_MODAL } from '../../actions/components/componentsActions'
import { EDIT_MESSAGE_POST_CAPTION_SUCCESS } from '../../actions/content/messagingActions'
import { USERS_INFO_REQUESTED } from '../../actions/content/usersActions'

const getGroupAdmin = (state) => state.account.account_info.isGroupAdmin
const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

const getIsIdpGroupAdmin = (state) => state.account.account_info.isIdpGroupAdmin

export function* postsInfo(action) {
    const payload = { ...action.payload }
    const isIdpGroupAdmin = yield select(getIsIdpGroupAdmin)
    try {
        const universityIds = yield select(getCurrentGroupUniversityIds)
        const limit = payload.limit || (yield select((state) => state.posts.posts.limit))
        const offset = payload.offset || 0

        if (isIdpGroupAdmin && !payload.filter?.universityId) {
            return
        }

        const apiCall = isIdpGroupAdmin ? API.dashboardGroup.getIdpPosts : API.dashboard.posts

        const basePayload = {
            limit,
            offset,
            sort: payload.sort,
            search: payload.search,
        }

        const requestPayload = isIdpGroupAdmin
            ? { ...basePayload, type: payload.filter?.type, universityId: payload.filter?.universityId }
            : {
                  ...basePayload,
                  filter: payload.filter,
                  withGlobalTags: payload.withGlobalTags,
                  postsInIds: payload.postsInIds,
                  postsNotInIds: payload.postsNotInIds,
                  universityIds,
              }

        const response = yield call(apiCall, requestPayload)

        yield response.success
            ? put({
                  type: POSTS_INFO_SUCCESS,
                  payload: {
                      items: response.data.posts,
                      total: response.data.total,
                      request: response.data.request,
                  },
              })
            : put({
                  type: POSTS_INFO_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: POSTS_INFO_FAILED,
        })
    }
}

export function* userPosts(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.userPosts(payload.user_id, payload.limit, payload.offset)
        })

        yield response.success
            ? put({
                  type: USERS_POSTS_SUCCESS,
                  payload: {
                      items: response.data.posts,
                  },
              })
            : put({
                  type: USERS_POSTS_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: USERS_POSTS_FAILED,
        })
    }
}

export function* addDownloadPoints(action) {
    const payload = action.payload

    try {
        yield call(() => {
            return API.dashboard.addDownloadPoints(payload.post_id)
        })
    } catch (error) {
        console.log(error)
        yield put({
            type: POSTS_INFO_FAILED,
        })
    }
}

export function* postsDelete(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.deletePost(payload.post_id)
        })

        if (response.success) {
            yield put({
                type: POSTS_DELETE_SUCCESS,
                payload: {
                    post_id: payload.postsType == 'all' ? payload.post_id : null,
                },
            })

            if (payload.postsInfo && payload.postsType == 'users') {
                yield put({
                    type: USERS_INFO_REQUESTED,
                    payload: {
                        filter: {},
                    },
                })

                yield put({
                    type: USERS_POSTS_REQUESTED,
                    payload: {
                        user_id: payload.typeId,
                        limit: 20,
                        offset: 0,
                        filter: {
                            university_id: 1,
                        },
                    },
                })
            }

            // TODO Remove this after refactoring
            yield put({
                type: CLOSE_CONFIRM_DELETE_POPUP,
            })

            if (payload.onConfirm) {
                payload.onConfirm()
            }
        } else {
            yield put({
                type: POSTS_DELETE_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: POSTS_DELETE_FAILED,
        })
    }
}

export function* fileUpload(action) {
    const payload = action.payload
    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        let universityId = null
        if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
            universityId = currentGroupUniversityId || payload.university_id
        }

        const response = yield call(() => {
            return API.dashboard.uploadFile(payload, universityId)
        })

        if (response.success) {
            yield put({
                type: UPLOAD_FILE_INFO_SUCCESS,
            })

            if (payload.onReady) {
                payload.onReady()
            }

            if (payload.isLargeFile) {
                yield call(() => {
                    return payload.callback(response.data.streamKey)
                })
            }
            yield put(openNotificationModal(UPLOAD_FILE_INFO_SUCCESS))
        } else {
            yield put({
                type: UPLOAD_FILE_INFO_FAILED,
            })
            if (payload.onError) {
                payload.onError()
            }
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: UPLOAD_FILE_INFO_FAILED,
        })
        if (payload.onError) {
            payload.onError()
        }
    }
}

export function* videoUniversityTranscode(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.videoUniversityTranscode(payload.formData)
        })

        if (response.success) {
            yield call(() => {
                return payload.callback(response.data.job_id, response.data.file)
            })
        }
    } catch (error) {
        console.log(error)
    }
}

export function* videoUpload(action) {
    const payload = action.payload

    try {
        const isGroupAdmin = yield select(getGroupAdmin)
        const currentGroupUniversityId = yield select(getCurrentGroupUniversityId)

        let universityId = null
        if (!isGroupAdmin || (isGroupAdmin && currentGroupUniversityId)) {
            universityId = currentGroupUniversityId || payload.university_id
        }
        const response = yield call(() => {
            return API.dashboard.videoUpload(payload, universityId)
        })

        if (response.success) {
            payload.callback()
        }
    } catch (error) {
        console.log(error)
    }
}

export function* sharePostToRSS(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.sharePostToRSS(payload.post_id, payload.caption)
        })

        if (response.success) {
            yield put({
                type: SHARE_POST_SUCCESS,
            })

            yield put({
                type: UPDATE_SHARED_POST,
                payload: {
                    postId: payload.post_id,
                },
            })
            if (payload.onSuccess) {
                payload.onSuccess()
            }
            setTimeout(function () {
                store.dispatch({
                    type: CLOSE_SHARE_POST_POPUP,
                })
            }, 2000)
        } else {
            yield put({
                type: SHARE_POST_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: SHARE_POST_FAILED,
        })
    }
}

export function* togglePublishPost(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.togglePublishPost(payload.post_id, payload.publish)
        })

        if (response.success) {
            yield put({
                type: PUBLISH_OR_UNPUBLISH_POST_SUCCESS,
                payload,
            })

            yield payload.publish
                ? put({
                      type: OPEN_ACTION_MODAL,
                      payload: {
                          title: 'Post has been published',
                      },
                  })
                : put({
                      type: OPEN_ACTION_MODAL,
                      payload: {
                          title: 'Post has been unpublished',
                      },
                  })

            setTimeout(function () {
                store.dispatch({
                    type: CLOSE_ACTION_MODAL,
                })
            }, 2000)
        } else {
            yield put({
                type: PUBLISH_POST_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: PUBLISH_POST_FAILED,
        })
    }
}

export function* editPost(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.dashboard.editPost(payload.post_id, payload.caption)
        })

        if (response.success) {
            yield put({
                type: EDIT_POST_CAPTION_SUCCESS,
                payload,
            })
            yield put({
                type: EDIT_MESSAGE_POST_CAPTION_SUCCESS,
                payload,
            })
        } else {
            yield put({
                type: EDIT_POST_CAPTION_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: EDIT_POST_CAPTION_FAILED,
        })
    }
}

export function* contentGroupPosts(action) {
    const payload = action.payload
    try {
        const response = yield call(() => {
            return API.dashboard.contentGroupPosts(payload.filter.content_group_id, payload.limit, payload.offset)
        })

        yield response.success
            ? put({
                  type: USERS_POSTS_SUCCESS,
                  payload: {
                      items: response.data.posts,
                  },
              })
            : put({
                  type: USERS_POSTS_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: USERS_POSTS_FAILED,
        })
    }
}

export function* changePostGlobalTags(action) {
    yield put({
        type: EDIT_POST_GLOBAL_TAG_FINISH,
        payload: action.payload,
    })
}
