export const CREATE_GLOBAL_TAG = 'CREATE_GLOBAL_TAG';
export const CREATE_GLOBAL_TAG_SUCCESS = 'CREATE_GLOBAL_TAG_SUCCESS';
export const CREATE_GLOBAL_TAG_FAILED = 'CREATE_GLOBAL_TAG_FAILED';
export const GET_GLOBAL_LIST_TAGS = 'GET_GLOBAL_LIST_TAGS';
export const GET_GLOBAL_LIST_TAGS_SUCCESS = 'GET_GLOBAL_LIST_TAGS_SUCCESS';
export const GET_GLOBAL_LIST_TAGS_FAILED = 'GET_GLOBAL_LIST_TAGS_FAILED';
export const CREATE_GLOBAL_USER_TAG = 'CREATE_GLOBAL_USER_TAG';
export const CREATE_GLOBAL_USER_TAG_SUCCESS = 'CREATE_GLOBAL_USER_TAG_SUCCESS';
export const CREATE_GLOBAL_USER_TAG_FAILED = 'CREATE_GLOBAL_USER_TAG_FAILED';
export const GET_GLOBAL_USER_TAGS = 'GET_GLOBAL_USER_TAGS';
export const GET_GLOBAL_USER_TAGS_SUCCESS = 'GET_GLOBAL_USER_TAGS_SUCCESS';
export const GET_GLOBAL_USER_TAGS_FAILED = 'GET_GLOBAL_USER_TAGS_FAILED';
export const REMOVE_GLOBAL_USER_TAG = 'REMOVE_GLOBAL_USER_TAG';
export const REMOVE_GLOBAL_USER_TAG_SUCCESS = 'REMOVE_GLOBAL_USER_TAG_SUCCESS';
export const REMOVE_GLOBAL_USER_TAG_FAILED = 'REMOVE_GLOBAL_USER_TAG_FAILED';
export const CREATE_GLOBAL_POST_TAG = 'CREATE_GLOBAL_POST_TAG';
export const CREATE_GLOBAL_POST_TAG_SUCCESS = 'CREATE_GLOBAL_POST_TAG_SUCCESS';
export const CREATE_GLOBAL_POST_TAG_FAILED = 'CREATE_GLOBAL_POST_TAG_FAILED';
export const GET_GLOBAL_POST_TAGS = 'GET_GLOBAL_POST_TAGS';
export const GET_GLOBAL_POST_TAGS_SUCCESS = 'GET_GLOBAL_POST_TAGS_SUCCESS';
export const GET_GLOBAL_POST_TAGS_FAILED = 'GET_GLOBAL_POST_TAGS_FAILED';
export const REMOVE_GLOBAL_POST_TAG = 'REMOVE_GLOBAL_POST_TAG';
export const REMOVE_GLOBAL_POST_TAG_SUCCESS = 'REMOVE_GLOBAL_POST_TAG_SUCCESS';
export const REMOVE_GLOBAL_POST_TAG_FAILED = 'REMOVE_GLOBAL_POST_TAG_FAILED';
export const CREATE_GLOBAL_FAQ_TAG = 'CREATE_GLOBAL_FAQ_TAG';
export const CREATE_GLOBAL_FAQ_TAG_SUCCESS = 'CREATE_GLOBAL_FAQ_TAG_SUCCESS';
export const CREATE_GLOBAL_FAQ_TAG_FAILED = 'CREATE_GLOBAL_FAQ_TAG_FAILED';
export const GET_GLOBAL_FAQ_TAGS = 'GET_GLOBAL_FAQ_TAGS';
export const GET_GLOBAL_FAQ_TAGS_SUCCESS = 'GET_GLOBAL_FAQ_TAGS_SUCCESS';
export const GET_GLOBAL_FAQ_TAGS_FAILED = 'GET_GLOBAL_FAQ_TAGS_FAILED';
export const REMOVE_GLOBAL_FAQ_TAG = 'REMOVE_GLOBAL_FAQ_TAG';
export const REMOVE_GLOBAL_FAQ_TAG_SUCCESS = 'REMOVE_GLOBAL_FAQ_TAG_SUCCESS';
export const REMOVE_GLOBAL_FAQ_TAG_FAILED = 'REMOVE_GLOBAL_FAQ_TAG_FAILED';
export const SET_CURRENT_SEARCH_LIST_TAGS = 'SET_CURRENT_SEARCH_LIST_TAGS';
