import React from 'react';
import { Avatar, Checkbox, Empty, Input, Spin } from 'antd';
import { clsx } from 'src/app/helpers/classNames';
import { SearchOutlined } from '@ant-design/icons';
const EMPTY_NAME_PLACEHOLDER = 'N/A';
const UserAvatar = ({ user }) => {
    const { avatar, name } = user;
    if (avatar) {
        return React.createElement(Avatar, { className: "!m-0", size: 64, src: avatar });
    }
    return (React.createElement(Avatar, { className: "!m-0", size: 64 }, name ? name[0] : EMPTY_NAME_PLACEHOLDER));
};
const AmbassadorsSelect = ({ items, isLoading, isSelected, handleSearch, serviceName, selectedIds, handleCheckboxChange, }) => {
    return (React.createElement("div", { className: clsx('card', isSelected ? 'selected' : undefined) },
        React.createElement("p", { className: clsx('text-lg', !isSelected ? 'text-secondary' : undefined) }, `Add five ${serviceName === 'P2P Conversion app' ? 'or more' : ''} ambassadors ${serviceName} (${selectedIds.length}/5)`),
        React.createElement(Input, { prefix: React.createElement(SearchOutlined, null), placeholder: "Search for an Ambassador", onChange: handleSearch, className: "mb-6 mt-4 text-reading-lg hover:border-border-gray focus:border-border-gray focus-within:border-border-gray", disabled: !isSelected }),
        React.createElement(Checkbox.Group, { value: selectedIds, disabled: !isSelected, className: "flex flex-col flex-nowrap h-[45rem] overflow-y-scroll border border-solid border-border-gray shadow-[inset_0px_-10px_15px_-10px_rgba(0,0,0,0.1)]" }, isLoading ? (React.createElement(Spin, { size: "default", className: "flex justify-center mt-23" })) : (React.createElement(React.Fragment, null, items.length > 0 ? (items.map((user) => {
            const name = `${user.name} ${user.lastName}` || EMPTY_NAME_PLACEHOLDER;
            return (React.createElement("div", { key: user.id, className: clsx('px-4 py-3 [&:not(:last-child)]:shadow-[inset_0px_-1px_0px_0px_rgba(0,0,0,0.1)]', (selectedIds === null || selectedIds === void 0 ? void 0 : selectedIds.includes(user.id)) ? 'bg-highlighted-gray' : undefined) },
                React.createElement(Checkbox, { value: user.id, onChange: handleCheckboxChange },
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement("div", { className: "!px-4" },
                            React.createElement(UserAvatar, { user: user })),
                        React.createElement("div", null,
                            React.createElement("div", { className: "text-submenu-base font-mainBold pb-1 font-interface-bold" }, name),
                            React.createElement("p", { className: "capitalize text-secondary text-reading-base" },
                                user.studentType && user.studentType !== 'eu' && (React.createElement(React.Fragment, null,
                                    user.studentType,
                                    ", ")),
                                user.countryName ? user.countryName : user.profileType))))));
        })) : (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, className: "mt-23" })))))));
};
export default React.memo(AmbassadorsSelect);
