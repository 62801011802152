import { Spin } from 'antd';
import React from 'react';
import { Button } from 'src/app/components/base/Button';
import { ButtonCheckbox } from 'src/app/components/base/ButtonCheckbox';
import { Tooltip } from 'src/app/components/base/Tooltip';
import { BulkToolbar } from 'src/app/components/common/BulkToolbar';
import { concatClsx } from 'src/app/helpers/classNames';
import { useAppSelector } from 'src/app/hooks/store';
import useUnmountEffect from 'src/app/hooks/useUnmountEffect';
import { selectTotalPosts, selectUnpublishedFilterValue } from 'src/app/redux/selectors/posts-selectors';
import { useBulkActions, useBulkState } from '../bulk-state';
import { BulkDeleteButton } from './BulkDeleteButton';
import { BulkPublishButton } from './BulkPublishButton';
export const BulkOperations = ({ className }) => {
    const { bulkEnabled, selectedIds, isLoading } = useBulkState();
    const { deselectAll, reset, toggleBulk } = useBulkActions();
    const total = useAppSelector(selectTotalPosts);
    const isUnpublishedFilter = useAppSelector(selectUnpublishedFilterValue);
    useUnmountEffect(() => {
        reset();
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: concatClsx('flex flex-1 justify-start gap-4 pt-4', className) },
            bulkEnabled && (React.createElement(Button, { type: "text", className: "h-10", onClick: deselectAll }, "Deselect all")),
            React.createElement(Tooltip, { title: "Bulk actions", trigger: ['hover', 'focus'] },
                React.createElement(ButtonCheckbox, { disabled: total === 0, checked: bulkEnabled, onChange: toggleBulk, label: "Select" }))),
        bulkEnabled && (React.createElement("div", { className: "absolute bottom-12 left-0 right-0 z-50 mx-auto w-max" },
            React.createElement(BulkToolbar, { selectedCount: selectedIds.size },
                isUnpublishedFilter && React.createElement(BulkPublishButton, null),
                React.createElement(BulkDeleteButton, null)))),
        React.createElement(Spin, { fullscreen: true, size: "large", spinning: isLoading })));
};
