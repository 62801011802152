import React from 'react';
import { Avatar } from '../../base/Avatar';
import { Select } from '../../base/Select';
import { Spin } from 'antd';
import './styles.scss';
function userOptionRender(option) {
    return (React.createElement("div", { className: "flex w-full items-center overflow-hidden" },
        React.createElement(Avatar, { src: option.data.avatar, className: "flex-shrink-0" }),
        React.createElement("div", { className: "ml-4 flex flex-1 flex-col overflow-hidden" },
            React.createElement("div", { className: "truncate text-16", title: option.data.label }, option.data.label),
            option.data.subtitle && React.createElement("div", { className: "flex items-center text-12" }, option.data.subtitle)),
        option.data.trailing && React.createElement("div", { className: "ml-2 flex-shrink-0" }, option.data.trailing)));
}
export const UserSelect = ({ users, placeholder, multiple, popupMatchSelectWidth, open, value, size, loading, className, filterOption, onChange, onSearch, dropdownRender, onDropdownVisibleChange, }) => {
    return (React.createElement(Select, { size: size, open: open, value: value, loading: loading, className: className, placeholder: placeholder, filterOption: filterOption, popupClassName: "users-dropdown", options: users, mode: multiple ? 'multiple' : undefined, popupMatchSelectWidth: popupMatchSelectWidth, onSearch: onSearch, onChange: onChange, optionRender: userOptionRender, dropdownRender: dropdownRender, onDropdownVisibleChange: onDropdownVisibleChange, notFoundContent: loading ? React.createElement(Spin, { className: "w-full py-8" }) : undefined }));
};
