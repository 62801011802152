import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
dayjs.extend(utc);
export const prepareFromDate = (dayjsObj) => {
    return dayjsObj.startOf('day').utcOffset(0, true).format();
};
export const prepareToDate = (dayjsObj) => {
    return dayjsObj.endOf('day').utcOffset(0, true).format();
};
export const getDateRange = (days) => {
    return {
        fromDate: prepareFromDate(dayjs().subtract(days - 1, 'days')),
        toDate: prepareToDate(dayjs()),
    };
};
export const getCurrentDay = () => {
    return dayjs().startOf('day');
};
export const getDateRangeForMonth = (dayjsObj) => {
    return {
        fromDate: dayjs(dayjsObj).startOf('month').format(),
        toDate: dayjs(dayjsObj).endOf('month').format(),
    };
};
export const getCurrentMonthDateRange = () => {
    return getDateRangeForMonth(dayjs());
};
export const isSameDay = (dayjsObj, dayjsObjOrString) => {
    return dayjsObj.isSame(dayjsObjOrString, 'day');
};
export const isSameMonth = (dayjsObj, dayjsObjOrString) => {
    return dayjsObj.isSame(dayjsObjOrString, 'month');
};
export const normalizeDate = (dayjsObj) => {
    return dayjs(dayjsObj).startOf('day').utcOffset(0, true).format();
};
