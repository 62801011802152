import React from 'react';
import { concatClsx } from 'src/app/helpers/classNames';
import './styles.scss';
export const BulkToolbar = ({ selectedCount = 0, children, className }) => {
    return (React.createElement("div", { className: concatClsx('bulk-toolbar flex items-center rounded-full bg-white px-6 py-3 text-16', className) },
        React.createElement("div", { className: "px-3 py-2" },
            selectedCount,
            " selected"),
        children));
};
