import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
const EditPrivacyPolicyForm = ({ handleSubmit }) => {
    var _a, _b;
    const [form] = Form.useForm();
    const { currentGroupUniversityId, account_info: accountInfo } = useSelector((state) => state.account);
    const [privacyPolicy, setPrivacyPolicy] = useState('');
    const prevGroupUniversityId = useRef(currentGroupUniversityId);
    useEffect(() => {
        if (prevGroupUniversityId !== currentGroupUniversityId) {
            prevGroupUniversityId.current = currentGroupUniversityId;
            form.setFieldValue('privacyPolicy', accountInfo.university ? accountInfo.university.privacy_policy : '');
        }
    }, [currentGroupUniversityId, accountInfo, form]);
    const onPrivacyChange = useCallback((e) => {
        console.log(e.currentTarget.value);
        setPrivacyPolicy(e.currentTarget.value);
    }, []);
    return (React.createElement(Form, { form: form, layout: "vertical", onFinish: handleSubmit, initialValues: { privacyPolicy: (_a = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.university) === null || _a === void 0 ? void 0 : _a.privacy_policy }, className: "university-form privacy-form" },
        React.createElement(Form.Item, { name: "privacyPolicy", rules: [
                {
                    required: true,
                    message: 'Please enter your privacy policy link',
                },
            ], className: "signin-input privacy-input" },
            React.createElement(Input, { placeholder: "Privacy policy URL", onChange: onPrivacyChange })),
        React.createElement(Button, { htmlType: "submit", className: "settings-btn privacy-save", disabled: privacyPolicy.length === 0 || privacyPolicy === ((_b = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.university) === null || _b === void 0 ? void 0 : _b.privacy_policy) }, "Save")));
};
export default EditPrivacyPolicyForm;
