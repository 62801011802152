import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import PlaceholderUser from '../../../../../assets/img/placeholder-user.png'
import Sprite from '../../../../../assets/img/symbol/sprite.svg'

import store from '../../../../redux/store'
import { ACCOUNT_LOGOUT } from '../../../../redux/actions/account/accountActions'
import { getCommonRoutePath } from '../../../../helpers/helpers'

const pickAvatarSrc = (avatar) => {
    if (!avatar) return PlaceholderUser

    const { sizes = {} } = avatar

    return sizes['160x160'] || sizes.original
}

class NavBottom extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            openDropdown: false,
        }

        this.handleLogoutClick = this.handleLogoutClick.bind(this)
        this.onDropdownClick = this.onDropdownClick.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
        // Initialize Headway widget
        if (window.Headway) {
            window.Headway.init(window.HW_config)
        } else {
            console.error('Headway init is not called')
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside(event) {
        const ref = document.querySelector('#nav-bottom')
        if (ref && !ref.contains(event.target)) {
            this.setState({ openDropdown: false })
        }
    }

    handleLogoutClick() {
        this.setState({ openDropdown: false })
        store.dispatch({
            type: ACCOUNT_LOGOUT,
        })
    }

    onDropdownClick() {
        this.setState((prevState) => ({ openDropdown: !prevState.openDropdown }))
    }

    render() {
        const { openDropdown } = this.state
        const { account } = this.props
        const { account_info: { avatar } = {} } = account

        const avatarSrc = pickAvatarSrc(avatar)

        const commonPath = getCommonRoutePath()

        return (
            <div id="nav-bottom" className="nav-bottom">
                <img
                    className="user-img nav-icon-notooltip"
                    src={avatarSrc}
                    alt="User icon"
                    onClick={this.onDropdownClick}
                />

                <a
                    href="https://knowledge.theambassadorplatform.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-link nav-icon-knowledge-base"
                >
                    <span className="nav-link-icon">
                        <svg className="icon">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + '#knowledge-base'} />
                        </svg>
                    </span>
                </a>
                {openDropdown && (
                    <ul className="bottom-helper-drop">
                        <li>
                            <NavLink
                                to={commonPath + '/settings/account'}
                                data-automation-id="help-menu-account"
                                onClick={this.onDropdownClick}
                            >
                                My Account
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={commonPath + '/home'}
                                onClick={this.handleLogoutClick}
                                data-automation-id="help-menu-log-out"
                            >
                                Log out
                            </NavLink>
                        </li>
                    </ul>
                )}
            </div>
        )
    }
}

export default NavBottom
