/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Badge, Col, Row, Typography } from 'antd'
import React, { useMemo } from 'react'

import PlaceholderUser from '../../../../../../../../../../../assets/img/placeholder-user.png'

const { Text } = Typography
const UserAvatar = ({ user }) => {
    const { avatar, prospect, name } = user

    if (avatar) {
        return <Avatar style={{ margin: 0 }} size={45} src={avatar.sizes['70x70'] || avatar.original} />
    }

    if (prospect) {
        return (
            <Avatar style={{ margin: 0 }} size={45}>
                {name[0]}
            </Avatar>
        )
    }

    return <Avatar style={{ margin: 0 }} size={45} src={PlaceholderUser} />
}

const AvatarWrapper = ({ children, isOnline }) =>
    isOnline ? (
        <Badge dot className="tap-avatar-online-badge" color="green">
            {children}
        </Badge>
    ) : (
        children
    )

const ParticipantsListItem = ({ user, accountInfo, isOnline, copyInvitationLink, canManageParticipants }) => {
    const { id, name, email, role_name: roleName, profileType, authLink, role_key: roleKey } = user
    const isLoggedInUser = useMemo(() => id === accountInfo.id, [accountInfo, user])

    if (authLink && roleKey === 'live-event-speaker' && canManageParticipants) {
        return (
            <Col style={{ width: '100%' }}>
                <Row justify="space-between" align="middle">
                    <Col>
                        <AvatarWrapper isOnline={isOnline}>
                            <UserAvatar user={user} />
                        </AvatarWrapper>
                    </Col>
                </Row>
                <Col>
                    <div>{name ? `${name} ${isLoggedInUser ? '(Me)' : ''}` : email}</div>
                    <div>
                        <Text type="secondary" style={{ textTransform: 'capitalize' }}>
                            {profileType || roleName}
                        </Text>
                    </div>
                </Col>
            </Col>
        )
    }
    return (
        <Row gutter={20}>
            <Col>
                <AvatarWrapper isOnline={isOnline}>
                    <UserAvatar user={user} />
                </AvatarWrapper>
            </Col>
            <Col>
                <div>{name ? `${name} ${isLoggedInUser ? '(Me)' : ''}` : email}</div>
                <div>
                    <Text type="secondary" style={{ textTransform: 'capitalize' }}>
                        {profileType || roleName}
                    </Text>
                </div>
            </Col>
        </Row>
    )
}

export default ParticipantsListItem
