import React from 'react';
import { Select } from 'src/app/components/base/Select';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import useUnmountEffect from 'src/app/hooks/useUnmountEffect';
import { CLEAR_POSTS_STORE, setPostsFilters } from 'src/app/redux/actions/content/postsActions';
import { selectPostsFilters } from 'src/app/redux/selectors/posts-selectors';
import { UniversitiesMultiselect } from './filters/IdpUniversitiesMultiselect';
export const IdpPostsListHeader = () => {
    const filters = useAppSelector(selectPostsFilters);
    const dispatch = useAppDispatch();
    useUnmountEffect(() => {
        dispatch({
            type: CLEAR_POSTS_STORE,
        });
    });
    return (React.createElement("div", { className: "search flex flex-wrap gap-4 p-5" },
        React.createElement("div", { className: "flex items-center gap-4 text-base" },
            React.createElement("span", { className: "whitespace-nowrap" }, "Content type:"),
            React.createElement(Select, { size: "large", className: "w-[10rem]", value: (filters === null || filters === void 0 ? void 0 : filters.type) || 'all', onChange: (value) => {
                    dispatch(setPostsFilters({ type: value === 'all' ? undefined : value }));
                }, options: [
                    {
                        label: 'All',
                        title: 'All',
                        value: 'all',
                    },
                    {
                        label: 'Photos',
                        title: 'Photos',
                        value: 'image',
                    },
                    {
                        label: 'Videos',
                        title: 'Videos',
                        value: 'video',
                    },
                ] })),
        React.createElement("div", { className: "flex items-center gap-4 text-base" },
            React.createElement("span", { className: "whitespace-nowrap" }, "University:"),
            React.createElement(UniversitiesMultiselect, { onChange: (value) => {
                    dispatch(setPostsFilters(Object.assign(Object.assign({}, filters), { universityId: value })));
                }, filters: filters }))));
};
