import { useDispatch } from 'react-redux';
import React, { useState, useCallback, useEffect } from 'react';
import { SET_TIMEZONE } from '../../../redux/actions/settings/timezonesActions';
import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions';
import { OPEN_ACTION_MODAL } from '../../../redux/actions/components/componentsActions';
import { TimezonesDropdown } from '../../../components/timezonesDropdown';
const Timezone = () => {
    var _a;
    const dispatch = useDispatch();
    const [selectedItemData, setSelectedItemData] = useState({
        selectedItem: { id: null },
        savedSelectedItem: { id: null },
    });
    useEffect(() => {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleSave = useCallback(() => {
        const { selectedItem, savedSelectedItem } = selectedItemData;
        if (selectedItem.id && savedSelectedItem.id !== selectedItem.id) {
            const onSuccess = () => {
                setSelectedItemData({ selectedItem, savedSelectedItem: selectedItem });
                dispatch({
                    type: OPEN_ACTION_MODAL,
                    payload: {
                        title: 'Success',
                    },
                });
            };
            dispatch({
                type: SET_TIMEZONE,
                payload: { timezoneId: selectedItem.id },
                onSuccess,
            });
        }
    }, [dispatch, selectedItemData]);
    const handleChange = useCallback((selectedItem, savedSelectedItemNew) => {
        setSelectedItemData({
            selectedItem,
            savedSelectedItem: savedSelectedItemNew || selectedItemData.savedSelectedItem,
        });
    }, [selectedItemData.savedSelectedItem]);
    return (React.createElement("section", { className: "content settings" },
        React.createElement("h3", { className: "settings-title" }, "Timezone"),
        React.createElement("div", { className: "settings-grid has-tutorial" },
            React.createElement("div", { className: "column" },
                React.createElement("div", { className: "tutorial-intro" },
                    React.createElement("p", null,
                        "Set your timezone so that you and your ambassadors receive platform notifications based on your local time. You can find out more in our",
                        ' ',
                        React.createElement("a", { rel: "noopener noreferrer", href: "https://knowledge.theambassadorplatform.com/how-to-set-your-timezone-in-the-admin-dashboard", target: "_blank" }, "help article"),
                        ".")),
                React.createElement("div", { className: "settings-item" },
                    React.createElement("h4", { className: "settings-name" }, "Select your timezone")),
                React.createElement(TimezonesDropdown, { handleChange: handleChange }),
                React.createElement("button", { type: "submit", className: "el-btn", onClick: handleSave, disabled: !((_a = selectedItemData.selectedItem) === null || _a === void 0 ? void 0 : _a.id) }, "Save")))));
};
export default Timezone;
