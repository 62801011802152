import React from 'react';
import { PostItemView } from './PostItemView';
import { useGalleryBulkStore } from './bulk-state';
export const PostsGroupItem = ({ title, posts, onPostClick, onShareClick, allowSharing }) => {
    const selected = useGalleryBulkStore((state) => state.selectedIds);
    const onToggleSelected = useGalleryBulkStore((state) => state.updateSelected);
    const bulkEnabled = useGalleryBulkStore((state) => state.bulkEnabled);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "posts-group-title bg-light-gray py-6" },
            React.createElement("h2", null, title)),
        React.createElement("div", { className: "grid grid-cols-3 gap-6 lg:grid-cols-4 2xl:grid-cols-5" }, posts.map((item, index) => {
            return (React.createElement(PostItemView, { onClick: onPostClick, index: index, post: item, key: item.id, onShareClick: onShareClick, selected: selected.has(item.id), bulkEnabled: bulkEnabled, onToggleSelected: onToggleSelected, allowSharing: allowSharing }));
        }))));
};
