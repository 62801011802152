import { PATTERNS } from '../../common/regex';
/**
 * Wrap links in message text
 */
export function wrapLinks(text) {
    return text.replace(PATTERNS.url, (url) => {
        return `[link]${url}[/link]`;
    });
}
export function formatChatMessage(text) {
    const hasBadWord = text.includes('[badword]') && text.includes('[/badword]');
    return hasBadWord ? text : wrapLinks(text);
}
