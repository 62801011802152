import { useCallback, useEffect, useState } from 'react';
export function useModalVisibility(visible) {
    const [isVisible, setIsVisible] = useState(visible);
    useEffect(() => {
        if (visible) {
            setIsVisible(visible);
        }
    }, [visible]);
    const setClose = useCallback(() => {
        setIsVisible(false);
    }, []);
    return [isVisible, setClose];
}
