var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getUserFingerprint } from 'src/app/helpers/helpers';
import { defaults, getConfigData } from '.';
import { axiosInstance } from './client';
export const getAccessToken = () => {
    const token = window.localStorage.getItem('Account');
    return token ? JSON.parse(token) : null;
};
export const refreshToken = () => __awaiter(void 0, void 0, void 0, function* () {
    const token = getAccessToken();
    if (token) {
        const response = yield axiosInstance.post(defaults.api_host + '/dashboard/auth/refreshTokens', { token, fingerprint: getUserFingerprint() }, getConfigData(true));
        if (response.data && response.data.data && response.data.data.token) {
            const newToken = response.data.data.token;
            window.localStorage.setItem('Account', JSON.stringify(newToken));
            return newToken;
        }
    }
    return null;
});
