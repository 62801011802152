import { HttpService } from 'src/app/system/services/HttpService';
export default {
    getDestinationOptions: (data) => {
        return HttpService.get(`/dashboardGroup/content/destinations`, data);
    },
    getContentGroups: (data) => {
        return HttpService.get(`/dashboardGroup/content/contentGroups`, data);
    },
    createContentGroup: (data) => {
        return HttpService.post(`/dashboardGroup/content/contentGroups/create`, data);
    },
    createBroadcastChannal: (data) => {
        return HttpService.post(`/dashboardGroup/content/broadcastChannels/create`, data);
    },
    getIdpContentGroupAmbassadors: (data) => {
        return HttpService.post(`/dashboardGroup/content/ambassadors`, data);
    },
    getIdpAmbassadorsSubjects: () => {
        return HttpService.get(`/dashboardGroup/content/ambassadors/subjects`);
    },
    getIdpAmbassadorsUniversities: () => {
        return HttpService.get(`/dashboardGroup/content/ambassadors/universities`);
    },
    getIdpPosts: (data) => {
        return HttpService.get(`/dashboardGroup/content/posts`, data);
    },
    publishIdpContent: (data) => {
        return HttpService.post(`/dashboardGroup/content/contentGroups/publish`, data);
    },
};
