import { HttpService } from 'src/app/system/services/HttpService';
export default {
    getSevicesList: () => {
        return HttpService.get('/dashboard/idp/services/list');
    },
    getAmbassadorsList: (data) => {
        return HttpService.get('/dashboard/idp/services/ambassadors', data);
    },
    activateSevices: (data) => {
        return HttpService.post('/dashboard/idp/services/activate', data);
    },
};
