export const getAccountSlice = (state) => state.account;
export const getLiveEventsSlice = (state) => state.liveEvents;
export const getLiveStreamsSlice = (state) => state.liveStreams;
export const getTimezonesSlice = (state) => state.timezones;
export const getGlobalTagsSlice = (state) => state.globalTags;
export const getNotificationModalSlice = (state) => state.notificationModal;
export const getUsersMessagingSlice = (state) => state.usersMessaging;
export const getChatMessagesSlice = (state) => state.chatMessages;
export const getEventStreamSlice = (state) => state.eventStream;
export const getPlsSignUpSlice = (state) => state.plsSignUp;
export const getCountriesSlice = (state) => state.countries;
export const getUsersSlice = (state) => state.users;
export const getAmbassadorsSlice = (state) => state.ambassadors;
export const getCampaignsSlice = (state) => state.campaign;
export const getChangeEmailModalSlice = (state) => state.changeEmailModal;
export const getChangePasswordModalSlice = (state) => state.changePasswordModal;
export const getChatsSlice = (state) => state.chats;
export const getCurrentChatSlice = (state) => state.currentChat;
export const getCommunitySlice = (state) => state.community;
