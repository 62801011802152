import { App } from 'antd';
import React from 'react';
import { Button } from '../base/Button';
import { licenseModal } from '../modals/LicenseModal';
import { useAppSelector } from 'src/app/hooks/store';
import { selectIsSascOrIdp } from 'src/app/redux/selectors';
export const ProtectedButton = (props) => {
    const { modal } = App.useApp();
    const isSascOrIdp = useAppSelector(selectIsSascOrIdp);
    const handleClick = (event) => {
        if (isSascOrIdp) {
            event.stopPropagation();
            event.preventDefault();
            modal.warning(licenseModal(props.licenseName));
        }
        else {
            props.onClick(event);
        }
    };
    return (React.createElement(Button, Object.assign({}, props, { type: props.type, onClick: handleClick }), props.children));
};
