import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import CreateQuestionForm from './forms/CreateQuestionForm'

import { FeatureFlags } from 'src/app/features/flags'
import { Feature } from 'src/app/features/types'
import { AMBASSADORS_INFO } from '../../../../../redux/actions/content/messagingActions'
import { CREATE_QUESTION, editFAQ } from '../../../../../redux/actions/faq/faqActions'
import { SET_CURRENT_SEARCH_LIST_TAGS } from '../../../../../redux/actions/tags/tagsActions'
import store from '../../../../../redux/store'
import { IdpQuestionForm } from './forms/IdpQuestionForm'

class CreateQuestionPopup extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            questionType: null,
            isButtonDisabled: false,
            hasFormBeenUpdated: false,
        }

        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.handleEscPress = this.handleEscPress.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.setFormUpdatedStatus = this.setFormUpdatedStatus.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.handleSearchUpdated = this.handleSearchUpdated.bind(this)
    }

    componentDidMount() {
        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'faq',
                },
            },
        })
        document.addEventListener('mousedown', this.handleClickOutside)
        document.addEventListener('keydown', this.handleEscPress, false)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
        document.removeEventListener('keydown', this.handleEscPress, false)
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.hasFormBeenUpdated) {
            const confirmByClickOutside = confirm(
                'Are you sure you want to close this popup? Changes will not be saved'
            )
            if (confirmByClickOutside) {
                this.props.onClosePopup()
            }
        }
    }

    handleEscPress(event) {
        const escapeButtonCode = 27
        if (event.keyCode !== escapeButtonCode) {
            return
        }

        if (!this.state.hasFormBeenUpdated) {
            this.props.onClosePopup()
            return
        }

        const confirmByEscPress = confirm('Are you sure you want to close this popup? Changes will not be saved')
        if (confirmByEscPress) {
            this.props.onClosePopup()
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }

    handleSearchUpdated(value) {
        store.dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    keyword: value,
                    permission: 'faq',
                },
            },
        })
    }

    setFormUpdatedStatus(value) {
        this.setState({ hasFormBeenUpdated: value })
        this.setState({ isButtonDisabled: false })
    }

    handleFormSubmit(values) {
        const { mode, faqID, question, answer, globalTagsIds, selectedUserIds, globalTags } = values
        const { editFAQ, isAnsweredQuestions, isPublished, onSubmit } = this.props

        if (mode === 'edit') {
            editFAQ(
                faqID,
                { question, answer, users: selectedUserIds, globalTagsIds, globalTags },
                isAnsweredQuestions,
                isPublished
            )
        } else {
            this.setState({ isButtonDisabled: true })

            const funcFinish = () => {
                store.dispatch({
                    type: SET_CURRENT_SEARCH_LIST_TAGS,
                    payload: {
                        items: [],
                    },
                })
                this.setState({ isButtonDisabled: false })
            }

            const payload = {
                question,
                funcFinish,
                globalTagsIds,
                globalTags,
            }

            if (selectedUserIds && selectedUserIds.length > 0) payload.users = selectedUserIds
            if (answer) payload.answer = answer

            store.dispatch({ type: CREATE_QUESTION, payload })

            if (onSubmit) {
                onSubmit(payload)
            }
        }
    }

    render() {
        const { onClosePopup, usersMessaging, createQuestionPopup, initialTags } = this.props

        const isIelts = FeatureFlags.isEnabled(Feature.IsIdpIELTS)
        const isEdit = createQuestionPopup.mode === 'edit'

        return (
            <div ref={this.setWrapperRef}>
                <div className="create-faq">
                    <h2 className="modal-title">{isEdit ? 'Edit question' : 'Create question'}</h2>
                    {isIelts ? (
                        <IdpQuestionForm
                            onCancelButtonClick={onClosePopup}
                            isEdit={isEdit}
                            onSubmit={this.handleFormSubmit}
                            faqData={{
                                id: createQuestionPopup.faqID,
                                question: createQuestionPopup.faqContent,
                                users: createQuestionPopup.members.map((member) => member.id),
                                tags: initialTags || createQuestionPopup.globalTags,
                            }}
                        />
                    ) : (
                        <CreateQuestionForm
                            questionType={this.state.questionType}
                            onCancelButtonClick={onClosePopup}
                            onSubmit={this.handleFormSubmit}
                            onSearchUpdated={this.handleSearchUpdated}
                            isCreateButtonDisabled={this.state.isButtonDisabled}
                            ambassadors={usersMessaging.ambassadors}
                            areAmbassadorsLoading={usersMessaging.isLoading}
                            createQuestionPopup={createQuestionPopup}
                            initialValues={{
                                question: createQuestionPopup.faqContent,
                                tags: initialTags || createQuestionPopup.globalTags,
                            }}
                            setFormUpdatedStatus={this.setFormUpdatedStatus}
                            hasFormBeenUpdated={this.state.hasFormBeenUpdated}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default connect(
    (state) => {
        return {
            usersMessaging: state.usersMessaging,
            createQuestionPopup: state.createQuestionPopup,
        }
    },
    { editFAQ }
)(CreateQuestionPopup)
