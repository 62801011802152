import { Form, Button, Row, Col } from 'antd';
import React from 'react';
import { PublishControl } from '../../../components';
import { PUBLISH_CONTROL } from '../../../variables';
const PublishControlForm = ({ data, handleSubmit, submitting }) => {
    const initialValues = {
        autoPublish: data.autoPublish,
    };
    return (React.createElement(Form, { onFinish: handleSubmit, initialValues: initialValues, layout: "vertical" },
        React.createElement(PublishControl, { id: PUBLISH_CONTROL.ID, label: PUBLISH_CONTROL.LABEL, description: PUBLISH_CONTROL.DESCRIPTON }),
        React.createElement(Row, { justify: "center", style: { paddingBottom: '5rem' } },
            React.createElement(Col, { flex: "162px" },
                React.createElement(Button, { type: "primary", block: true, size: "large", disabled: submitting, loading: submitting, htmlType: "submit", "data-automation-id": "update-auto-publish-button" }, "Save")))));
};
export default PublishControlForm;
