import { Tabs } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import DatesIntervalSelector from 'src/app/components/DatesIntervalSelector';
import { HomeRedirect } from 'src/app/containers/_layout/nav/components/HomeRedirect';
import { getDateRange } from 'src/app/helpers/time';
import { Permissions } from 'src/app/permissions/permissions';
import { TWO_TIER_MENU_CHANGE_SUBMENU } from 'src/app/redux/actions/content/componentsActions';
import { PermissionEnum } from 'src/types/general';
import { ConversationsChart } from './components/ConversationsChart';
import { FirstResponseTimeChart } from './components/FirstResponseTimeChart';
import { MessagesChart } from './components/MessagesChart';
import { NationalityBreakdownChart } from './components/NationalityBreakdownChart';
import { ProspectCountChart } from './components/ProspectCountChart';
const IdpConversionReport = () => {
    const dispatch = useDispatch();
    const canSeeIdpReport = Permissions.can(PermissionEnum.IdpLiveApp, false) || Permissions.can(PermissionEnum.IdpWhatUni, false);
    const showReportTypeFilter = Permissions.can(PermissionEnum.IdpLiveApp, false) && Permissions.can(PermissionEnum.IdpWhatUni, false);
    const [reportType, setReportType] = useState(PermissionEnum.IdpLiveApp);
    const [selectedButton, setSelectedButton] = useState('30Days');
    const [selectedDateRange, setSelectedDateRange] = useState(getDateRange(30));
    const filterRef = useRef(Object.assign(Object.assign({}, selectedDateRange), { product: showReportTypeFilter || Permissions.can(PermissionEnum.IdpLiveApp, false)
            ? PermissionEnum.IdpLiveApp
            : PermissionEnum.IdpWhatUni }));
    useEffect(() => {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'reporting',
            },
        });
    }, [dispatch]);
    const onReportTypeChange = useCallback((value) => {
        setReportType(value);
        filterRef.current = Object.assign(Object.assign({}, filterRef.current), { product: value === 'all'
                ? undefined
                : showReportTypeFilter
                    ? value
                    : Permissions.can(PermissionEnum.IdpLiveApp, false)
                        ? PermissionEnum.IdpLiveApp
                        : PermissionEnum.IdpWhatUni });
    }, [showReportTypeFilter]);
    const onReportDateChange = useCallback((selectedButtonVal, selectedDateRangeVal) => {
        setSelectedButton(selectedButtonVal);
        setSelectedDateRange(selectedDateRangeVal);
        filterRef.current = Object.assign(Object.assign({}, filterRef.current), selectedDateRangeVal);
    }, []);
    if (!canSeeIdpReport) {
        return React.createElement(HomeRedirect, null);
    }
    return (React.createElement("div", { className: "idp-conversion-container" },
        React.createElement("div", { className: "content reporting" },
            React.createElement("p", { className: "prospect-table-title" }, "P2P Conversion"),
            React.createElement("div", { className: "flex flex-row items-baseline justify-between" },
                React.createElement(Tabs, { activeKey: reportType, size: "large", onChange: onReportTypeChange, className: !showReportTypeFilter ? 'invisible' : undefined },
                    React.createElement(Tabs.TabPane, { tab: "Live App", key: PermissionEnum.IdpLiveApp }),
                    React.createElement(Tabs.TabPane, { tab: "WhatUni app", key: PermissionEnum.IdpWhatUni }),
                    React.createElement(Tabs.TabPane, { tab: "All", key: "all" })),
                React.createElement(DatesIntervalSelector, { selectedButton: selectedButton, fromDate: selectedDateRange.fromDate, toDate: selectedDateRange.toDate, onChange: onReportDateChange, restrictBeforeDate: new Date(2020, 7, 17) })),
            React.createElement("div", { className: "grid grid-cols-2 gap-4 mb-4" },
                React.createElement(ProspectCountChart, { filter: filterRef.current }),
                React.createElement(NationalityBreakdownChart, { filter: filterRef.current })),
            React.createElement("div", { className: "grid grid-cols-3 gap-4" },
                React.createElement(ConversationsChart, { filter: filterRef.current }),
                React.createElement(MessagesChart, { filter: filterRef.current }),
                React.createElement(FirstResponseTimeChart, { filter: filterRef.current })))));
};
export default IdpConversionReport;
