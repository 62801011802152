import dayjs from 'dayjs'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { OPEN_CONTENT_GROUP_POPUP } from '../../../../redux/actions/content/messagingActions'
import store from '../../../../redux/store'
import Loader from '../../components/Loader'
import calendar from 'dayjs/plugin/calendar'
import * as relativeTime from 'dayjs/plugin/relativeTime'

import { Button } from 'src/app/components/base/Button'
import { ContentGroupTypeEnum, LicensesEnum } from 'src/types/general'
import { ProtectedButton } from 'src/app/components/protected-button'

dayjs.extend(calendar)
dayjs.extend(relativeTime)

class ContentGroupsSidebar extends PureComponent {
    handleContentGroupClick({ groupType }) {
        store.dispatch({
            type: OPEN_CONTENT_GROUP_POPUP,
            payload: groupType,
        })
    }

    render() {
        if (this.props.contentGroups.isLoading && this.props.contentGroups.isFirstRequest)
            return (
                <div className="chat-nav">
                    <Loader />
                </div>
            )

        const { handleContentGroupClick, contentGroups, dialogMessages, account } = this.props
        const { isIdpGroupAdmin } = account.account_info
        let contentGroupsView = []
        contentGroupsView = contentGroups.items.map((contentGroup, index) => {
            if (contentGroup.closed)
                return (
                    <div
                        key={index}
                        className="chat-nav-item mod-archive"
                        data-content-group-id={contentGroup.id}
                        onClick={handleContentGroupClick}
                    >
                        <div className="chat-nav-info" data-content-group-id={contentGroup.id}>
                            <h3 className="chat-nav-name" data-content-group-id={contentGroup.id}>
                                {contentGroup.is_broadcast_channel ? `[BC] ${contentGroup.name}` : contentGroup.name}
                            </h3>
                            <p className="chat-nav-text" data-content-group-id={contentGroup.id}>
                                Closed
                            </p>
                        </div>
                    </div>
                )
            const lastMessage = {
                text: '',
                created_at: '',
            }

            if (contentGroup.last_message) {
                lastMessage.created_at = dayjs(contentGroup.last_message.created_at).calendar(null, {
                    sameDay: 'H:mm',
                    nextDay: '[Tomorrow]',
                    nextWeek: '[Next] dddd',
                    lastDay: '[Yesterday at ]H:mm',
                    lastWeek: 'DD MMMM',
                    sameElse: 'DD MMMM',
                })

                if (contentGroup.last_message.type == 'text') {
                    lastMessage.text =
                        contentGroup.last_message.content.text &&
                        contentGroup.last_message.content.text.replace(/<\/?a.*?>/gm, '')

                    if (lastMessage.text.length > 60) lastMessage.text = lastMessage.text.slice(0, 60)
                }
                if (contentGroup.last_message.type == 'post') {
                    lastMessage.text = 'Post'
                }

                if (contentGroup.last_message.type == 'unarchived') {
                    lastMessage.text = 'Group has been reopened'
                }
            }

            return (
                <div
                    key={index}
                    className={
                        dialogMessages.dialog_id == contentGroup.id ? 'chat-nav-item is-active' : 'chat-nav-item'
                    }
                    data-content-group-id={contentGroup.id}
                    onClick={handleContentGroupClick}
                >
                    <div className="chat-nav-info" data-content-group-id={contentGroup.id}>
                        <h3 className="chat-nav-name" data-content-group-id={contentGroup.id}>
                            {contentGroup.is_broadcast_channel ? `[BC] ${contentGroup.name}` : contentGroup.name}
                        </h3>
                        <p className="chat-nav-text" data-content-group-id={contentGroup.id}>
                            {lastMessage.text}
                        </p>
                    </div>
                    <div className="chat-nav-data" data-content-group-id={contentGroup.id}>
                        <p className="chat-nav-date" data-content-group-id={contentGroup.id}>
                            {lastMessage.created_at}
                        </p>
                        {contentGroup.new_messages > 0 && (
                            <p className="chat-nav-msg-counter" data-content-group-id={contentGroup.id}>
                                {contentGroup.new_messages}
                            </p>
                        )}
                    </div>
                </div>
            )
        })

        return (
            <div className="chat-nav">
                {isIdpGroupAdmin ? (
                    <div className="chat-nav-first-item flex-row">
                        <Button type="secondary" size="large" onClick={this.handleContentGroupClick} className="mr-4">
                            Create content request
                        </Button>
                        <Button
                            type="secondary"
                            size="large"
                            onClick={() => this.handleContentGroupClick({ groupType: ContentGroupTypeEnum.Broadcast })}
                        >
                            Broadcast channel
                        </Button>
                    </div>
                ) : (
                    <div className="chat-nav-first-item">
                        <ProtectedButton
                            licenseName={LicensesEnum.CreateContentGroup}
                            type="secondary"
                            data-automation-id="create-content-group"
                            size="large"
                            onClick={this.handleContentGroupClick}
                        >
                            Create Content Group
                        </ProtectedButton>
                    </div>
                )}
                <div className="chat-nav-list">{contentGroupsView}</div>
            </div>
        )
    }
}

export default connect((state) => {
    return {
        account: state.account,
        contentGroups: state.contentGroups,
        dialogMessages: state.dialogMessages,
    }
})(ContentGroupsSidebar)
