var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useContext, useEffect, useState } from 'react';
import API from 'src/app/api';
import { useAppSelector } from 'src/app/hooks/store';
import { IdpServiceTypeEnum } from 'src/types/general';
import { selectIdpStepActivation } from 'src/app/redux/selectors/account-selectors';
const StepsContext = createContext(null);
export const StepsProvider = ({ children }) => {
    const idpStepActivation = useAppSelector(selectIdpStepActivation);
    const [currentStep, setCurrentStep] = useState(idpStepActivation);
    const [isConversionSelected, setIsConversionSelected] = useState(true);
    const [isCommunitySelected, setIsCommunitySelected] = useState(true);
    const [isSpetialOffer, setIsSpetialOffer] = useState(false);
    const [isConversionAcive, setIsConversionActive] = useState(false);
    const [isCommunityActive, setIsCommunityActive] = useState(false);
    const [isConversionSpecialOffer, setIsConversionSpecialOffer] = useState(false);
    const [isCommunitySpecialOffer, setIsCommunitySpecialOffer] = useState(false);
    const isCompleted = currentStep === 3 && (isConversionSelected || isCommunitySelected);
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield API.idpService.getSevicesList();
                response.data.forEach((item) => {
                    if (item.service === IdpServiceTypeEnum.IdpConversionChat) {
                        setIsConversionActive(item.isActive);
                    }
                    else if (item.service === IdpServiceTypeEnum.IdpCommunity) {
                        setIsCommunityActive(item.isActive);
                    }
                });
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        });
        fetchData();
    }, []);
    const data = {
        isConversionSelected,
        setIsConversionSelected,
        isCommunitySelected,
        setIsCommunitySelected,
        isSpetialOffer,
        setIsSpetialOffer,
        currentStep,
        setCurrentStep,
        isCompleted,
        isConversionAcive,
        isCommunityActive,
        setIsConversionSpecialOffer,
        setIsCommunitySpecialOffer,
        isConversionSpecialOffer,
        isCommunitySpecialOffer,
    };
    return React.createElement(StepsContext.Provider, { value: data }, children);
};
export function useStepsData() {
    const context = useContext(StepsContext);
    if (!context) {
        throw new Error('useStepsData must be used within a StepsProvider');
    }
    return context;
}
