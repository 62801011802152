// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class StrictMap {
    constructor() {
        this.map = new Map();
    }
    get(key) {
        return this.map.get(key);
    }
    set(key, value) {
        this.map.set(key, value);
        return this;
    }
    clear() {
        this.map.clear();
    }
    delete(key) {
        return this.map.delete(key);
    }
    has(key) {
        return this.map.has(key);
    }
    get size() {
        return this.map.size;
    }
}
