var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Select } from 'antd';
import React, { useCallback } from 'react';
import { IdpFilterSelect } from './IdpFilterSelect';
import API from 'src/app/api';
const fetchNationalitiesData = () => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield API.settingsNew.getNationalities();
    return resp.data;
});
const fetchUniversitiesData = () => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield API.dashboardGroup.getIdpAmbassadorsUniversities();
    return resp.data;
});
const fetchSubjectsData = () => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield API.dashboardGroup.getIdpAmbassadorsSubjects();
    return resp.data;
});
const IdpAmbassadorsFilters = ({ onFilter }) => {
    const onTypeChange = useCallback((value) => {
        onFilter((prevFilters) => (Object.assign(Object.assign({}, prevFilters), { type: value })));
    }, [onFilter]);
    const onNationalitiesChange = useCallback((value) => {
        onFilter((prevFilters) => (Object.assign(Object.assign({}, prevFilters), { nationalities: value })));
    }, [onFilter]);
    const onUniversitiesChange = useCallback((value) => {
        onFilter((prevFilters) => (Object.assign(Object.assign({}, prevFilters), { universities: value })));
    }, [onFilter]);
    const onSubjectsChange = useCallback((value) => {
        onFilter((prevFilters) => (Object.assign(Object.assign({}, prevFilters), { subjects: value })));
    }, [onFilter]);
    return (React.createElement("div", { className: "mb-6 mt-9" },
        React.createElement("p", { className: "pb-2 text-reading-lg font-semibold" }, "Filters"),
        React.createElement("div", { className: "grid grid-cols-2 gap-3" },
            React.createElement(Select, { allowClear: true, onChange: onTypeChange, options: [
                    { value: 'domestic', label: 'Domestic' },
                    { value: 'international', label: 'International' },
                ], placeholder: "TYPE" }),
            React.createElement(IdpFilterSelect, { optionFetcher: fetchNationalitiesData, onChange: onNationalitiesChange, placeholder: "NATIONALITIES", mode: "multiple" }),
            React.createElement(IdpFilterSelect, { optionFetcher: fetchUniversitiesData, onChange: onUniversitiesChange, placeholder: "UNIVERSITIES", mode: "multiple" }),
            React.createElement(IdpFilterSelect, { optionFetcher: fetchSubjectsData, onChange: onSubjectsChange, placeholder: "SUBJECTS", mode: "multiple" }))));
};
export default IdpAmbassadorsFilters;
