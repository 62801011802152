/* eslint-disable camelcase */
import { Form, Input, Modal, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
export const AdditionalParameters = ({ form, initialValues, setTouched }) => {
    const fieldsNames = ['custom_1', 'custom_2', 'custom_3'];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [switchStates, setSwitchStates] = useState({
        custom_1: false,
        custom_2: false,
        custom_3: false,
    });
    const [activeSwitchState, setActiveSwitchState] = useState({
        fieldName: '',
        checked: false,
    });
    useEffect(() => {
        setSwitchStates((prevStates) => (Object.assign(Object.assign({}, prevStates), { custom_1: initialValues === null || initialValues === void 0 ? void 0 : initialValues.custom_1_unique, custom_2: initialValues === null || initialValues === void 0 ? void 0 : initialValues.custom_2_unique, custom_3: initialValues === null || initialValues === void 0 ? void 0 : initialValues.custom_3_unique })));
    }, [initialValues]);
    const onChange = (fieldName, checked) => {
        setIsModalOpen(true);
        setActiveSwitchState((prevStates) => (Object.assign(Object.assign({}, prevStates), { fieldName,
            checked })));
    };
    const onConfirm = () => {
        fieldsNames.forEach((switchName) => {
            setSwitchStates((prevStates) => (Object.assign(Object.assign({}, prevStates), { [switchName]: switchName !== activeSwitchState.fieldName ? false : activeSwitchState.checked })));
            form.setFieldValue(`${switchName}_unique`, switchName !== activeSwitchState.fieldName ? false : activeSwitchState.checked);
        });
        setIsModalOpen(false);
        setTouched(true);
    };
    const handleCloseConfirmPopup = () => {
        setIsModalOpen(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "mb-2.5 text-reading-lg font-interface-bold" }, "Additional custom fields"),
        React.createElement("p", { className: "text-reading-base mb-2.5" }, "Use the fields below if you need to pass some additional parameters for enabling SSO-powered authorisation."),
        React.createElement("div", { className: "sso-custom-field-row mb-2.5" },
            React.createElement("p", null, "This is the name of the parameter in your SSO App / provider"),
            React.createElement("p", { className: "col-span-2" },
                "This is how your custom field will be returned to our\u00A0",
                React.createElement("a", { target: "_blank", href: "https://public-api.theambassadorplatform.com/docs#tag/Users/operation/UsersController_getProspects", className: "text-brand-orange font-interface-bold", rel: "noreferrer" }, "public API"),
                "\u00A0 so that you can pull it from there")),
        fieldsNames.map((field, index) => {
            return (React.createElement("div", { key: index, className: "sso-custom-field-row" },
                React.createElement(Form.Item, { name: `${field}_name`, className: "sso-field-item" },
                    React.createElement(Input, { placeholder: "e.g. field_of_study" })),
                React.createElement(Form.Item, { name: `${field}_reference`, className: "sso-field-item" },
                    React.createElement(Input, { placeholder: "e.g. studyField" })),
                React.createElement(Form.Item, { className: "sso-field-item" },
                    React.createElement(Switch, { onChange: (checked) => onChange(`${field}`, checked), checked: switchStates[`${field}`] }),
                    React.createElement("span", { className: "ml-1" }, "This is a unique Identifier")),
                React.createElement(Form.Item, { name: `${field}_unique`, noStyle: true, valuePropName: "checked" },
                    React.createElement(Switch, { style: { display: 'none' } }))));
        }),
        React.createElement(Modal, { centered: true, destroyOnClose: true, open: isModalOpen, footer: null, closeIcon: null },
            React.createElement("div", { className: "p-8 text-center" },
                React.createElement("h3", { className: "text-header-sm" }, "Are you sure?"),
                React.createElement("p", { className: "text-reading-lg my-6" },
                    "This parameter is a unique identifier. There can be\u00A0",
                    React.createElement("span", { className: "font-interface-bold" }, "only one unique identifier"),
                    " from the custom parameters. If you turn it off some information that is stored about your users in TAP may not be provided back to your SSO app in case of any changes"),
                React.createElement("div", { className: "btn-wrap confirm-ban-buttons" },
                    React.createElement("button", { type: "submit", className: "el-btn confirm-delete-button", onClick: onConfirm }, "Yes, I am sure"),
                    React.createElement("button", { type: "button", className: "el-btn cancel-delete-button", onClick: handleCloseConfirmPopup }, "Cancel"))))));
};
