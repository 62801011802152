import React from 'react';
import { Icon } from '../base/Icon';
import { concatClsx } from 'src/app/helpers/classNames';
import './message.scss';
export function getMessageProps(props) {
    return Object.assign(Object.assign({}, props), { className: concatClsx('tap-event-message', props.className) });
}
export function getSuccessMessageConfig(message) {
    return getMessageProps({
        icon: React.createElement(Icon, { className: "size-6 pr-2", icon: "check" }),
        content: React.createElement("h3", { className: "text-header-sm text-white" }, message),
        className: 'mt-10 [&>.ant-message-notice-content]:!bg-[var(--message-notice-bg)]',
        duration: 2,
    });
}
