import React, { useEffect, useState } from 'react';
import { Avatar, Checkbox, Empty, Spin } from 'antd';
import { pluckIds } from 'src/app/common/utils/array-utils';
const EMPTY_NAME_PLACEHOLDER = 'N/A';
const UserAvatar = ({ user }) => {
    const { avatar, name } = user;
    if (avatar) {
        return React.createElement(Avatar, { className: "!m-0", size: 64, src: avatar });
    }
    return (React.createElement(Avatar, { className: "!m-0", size: 64 }, name ? name[0] : EMPTY_NAME_PLACEHOLDER));
};
const IdpAmbassadorsList = ({ items, isLoading, onSelect }) => {
    const [checkedList, setCheckedList] = useState([]);
    const [defaultList, setDefaultList] = useState([]);
    const checkAll = defaultList.length === checkedList.length;
    const onChange = (list) => {
        setCheckedList(list);
        onSelect(list);
    };
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? defaultList : []);
        onSelect(e.target.checked ? defaultList : []);
    };
    useEffect(() => {
        const ids = pluckIds(items);
        setCheckedList(ids);
        setDefaultList(ids);
    }, [items]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "mb-2 flex px-4 text-reading-lg" },
            React.createElement(Checkbox, { className: "!text-reading-lg", onChange: onCheckAllChange, checked: checkAll }, checkAll ? 'Unselect all' : 'Select all'),
            React.createElement("p", { className: "ml-8" },
                checkedList.length,
                " selected")),
        React.createElement("div", { className: "h-[25rem] overflow-auto border border-solid border-border-gray shadow-[inset_0px_-10px_15px_-10px_rgba(0,0,0,0.1)]" }, isLoading ? (React.createElement(Spin, { size: "large", className: "mt-20 flex justify-center" })) : (React.createElement(React.Fragment, null, items.length === 0 ? (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, className: "mt-23" })) : (React.createElement(Checkbox.Group, { className: "checkbox-group-custom", value: checkedList, onChange: onChange }, items.map((user) => {
            const name = `${user.name} ${user.lastName}` || EMPTY_NAME_PLACEHOLDER;
            return (React.createElement("div", { key: user.id, className: "px-4 py-3" },
                React.createElement(Checkbox, { value: user.id },
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement("div", { className: "!px-4" },
                            React.createElement(UserAvatar, { user: user })),
                        React.createElement("div", null,
                            React.createElement("div", { className: "font-mainBold pb-1 text-submenu-base" }, name),
                            React.createElement("p", { className: "text-reading-base capitalize text-secondary" }, user.profileType))))));
        }))))))));
};
export default React.memo(IdpAmbassadorsList);
