import React from 'react';
import { ChartCard } from './ChartCard';
import ChartHeader from '../../components/ChartHeader';
import { Spin } from 'antd';
import { useGetConversationsQuery } from 'src/app/redux/slices/reporting/idp-conversion-reporting/query';
import { useAppSelector } from 'src/app/hooks/store';
import { selectActiveUnivercityId } from 'src/app/redux/selectors/account-selectors';
import LineChart from '../../components/LineChart';
import { conversationsTooltipFormatter } from '../helpers';
export const ConversationsChart = ({ filter }) => {
    const universityId = useAppSelector(selectActiveUnivercityId);
    const { data: conversationsData, isFetching } = useGetConversationsQuery({
        filter,
        universityIds: universityId ? [universityId] : null,
    });
    return (React.createElement(ChartCard, null,
        React.createElement(ChartHeader, { title: "Number of Conversations", label: `Average: ${!isFetching && conversationsData ? conversationsData.average : 0}`, labelDescription: "Per day across date range", totalLabel: `Total: ${!isFetching && conversationsData ? conversationsData.total : 0}`, totalLabelDescription: "Across date range" }),
        isFetching ? (React.createElement(Spin, { size: "large", className: "flex justify-center mt-20" })) : (React.createElement(LineChart, { color: "#d2e116", data: conversationsData ? [...conversationsData.data] : [], height: 200, tooltipFormatter: conversationsTooltipFormatter }))));
};
