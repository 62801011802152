import { Col, Row, Typography } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFlagsToggle } from '../../../../../redux/actions/account/account-actions';
import { getUniversityInfo } from '../../../../../redux/selectors';
import { checkCanSeeFlags } from '../../../../../common/utils/user-account-helpers';
const { Title, Text } = Typography;
const FlagsToggler = () => {
    const dispatch = useDispatch();
    const university = useSelector(getUniversityInfo);
    const isFlagsAllowed = checkCanSeeFlags(university);
    const handleSwitchToggle = useCallback((e) => {
        const { checked, name } = e.target;
        dispatch(setFlagsToggle({ isEnabled: checked, permissionKey: name }));
    }, [dispatch]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, { level: 5, className: "settings-name" }, "Flags on ambassador profiles"),
        React.createElement(Col, { style: { marginBottom: '4rem', fontSize: '1.6rem' } },
            React.createElement(Row, null,
                React.createElement(Text, { style: { fontSize: '1.6rem', marginBottom: '2rem' } }, "You can show or hide flags on the profiles of the ambassadors on the TAP page and in the mobile application.")),
            React.createElement(Row, null,
                React.createElement(Col, { style: { marginRight: '10rem' } },
                    React.createElement(Row, { align: "middle", justify: "space-between", style: { marginBottom: '5rem' } },
                        React.createElement(Text, { style: { fontSize: '1.6rem', marginRight: '4rem' } }, "Show flags"),
                        React.createElement("label", { className: "slideOne js-holder" },
                            React.createElement("input", { type: "checkbox", name: "display_ambassador_country_flag", checked: isFlagsAllowed, onChange: handleSwitchToggle }),
                            React.createElement("span", { className: "slideOne-icon" }))))))));
};
export default FlagsToggler;
