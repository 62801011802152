import { Form, Switch, Typography, Col } from 'antd';
import React from 'react';
const { Text } = Typography;
const PublishControl = ({ id, label, description }) => {
    return (React.createElement(Col, { className: "stream-publish-control" },
        React.createElement(Text, null, label),
        React.createElement(Form.Item, { name: id, valuePropName: "checked" },
            React.createElement(Switch, { "data-automation-id": id })),
        React.createElement(Text, { className: "description" }, description)));
};
export default PublishControl;
