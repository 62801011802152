import { Select } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChatType } from '../../../../../common/constants';
import { CHATS_INFO_SEARCH } from '../../../../../redux/actions/chat/messagingActions';
import { getChatsSlice } from '../../../../../redux/selectors';
const { Option } = Select;
const GroupChatSelect = ({ onGroupChatSelect }) => {
    const dispatch = useDispatch();
    const { items: groupChats } = useSelector(getChatsSlice);
    const options = useMemo(() => groupChats.map((chats) => {
        return (React.createElement(Option, { key: chats.id, value: chats.id, label: chats.name, privateCode: chats.is_private ? chats.private_code : null }, `${chats.name}`));
    }), [groupChats]);
    useEffect(() => {
        dispatch({
            type: CHATS_INFO_SEARCH,
            payload: {
                withGlobalTags: true,
                type_chat: ChatType.GROUP_CHAT,
                archived: false,
            },
        });
    }, [dispatch]);
    return (React.createElement(Select, { size: "large", className: "mt-4", style: { width: 'calc(80% + 2rem + 2px)' }, onChange: onGroupChatSelect, filterOption: false, placeholder: "Select group chat" }, options));
};
export default GroupChatSelect;
