/* eslint-disable camelcase */
export default (user) => {
    const { id, name, last_name: lastName, avatar, last_activity, permissions, partnerGroups = [] } = user;
    const formatedPartnerGroupArr = permissions.partner_groups
        ? partnerGroups.map((group) => {
            const permissionsArr = permissions.partner_groups.find((permission) => permission.university_group_id === group.id);
            return (permissionsArr || {
                university_group_id: group.id,
                is_enabled: false,
            });
        })
        : [];
    const partnerGroupObj = Object.assign({}, ...formatedPartnerGroupArr.map((item) => ({
        [`partner_group_tap_feed_permission_${item.university_group_id}`]: item,
    })));
    const mainData = {
        id,
        name,
        lastName: lastName,
        avatar,
        last_activity,
        fullName: `${name} ${lastName}`,
        chat_permission: permissions.chat,
        content_permission: permissions.content,
        faq_permission: permissions.faq,
        university_tap_feed_permission: permissions.university_tap_page,
        idp_community_permission: permissions.idp_community || {},
        idp_live_app_permission: permissions.idp_conversion_chat || {},
        idp_what_uni_permission: permissions.idp_what_uni || {},
        userInfo: user,
    };
    const data = Object.assign(Object.assign({}, mainData), partnerGroupObj);
    return data;
};
