var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Select } from 'antd';
import React, { useState } from 'react';
export const IdpFilterSelect = (_a) => {
    var { onChange, optionFetcher } = _a, props = __rest(_a, ["onChange", "optionFetcher"]);
    const [options, setOptions] = useState();
    const onDropdownVisibleChange = (open) => __awaiter(void 0, void 0, void 0, function* () {
        if (open && !options) {
            try {
                const resp = yield optionFetcher();
                const parsedItems = resp.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
                setOptions(parsedItems);
            }
            catch (error) {
                console.error('Error fetching options:', error);
            }
        }
    });
    const handleChange = (value) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    };
    return (React.createElement(Select, Object.assign({ showSearch: true, optionFilterProp: "label", onDropdownVisibleChange: onDropdownVisibleChange }, props, { options: options, onChange: handleChange })));
};
