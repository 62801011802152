import { Modal } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAppSelector } from 'src/app/hooks/store';
import { useModalVisibility } from 'src/app/hooks/useModalVisibility';
import { IdpContentRequestForm } from './forms/idp-content-request-form';
export const IdpContentRequest = () => {
    const history = useHistory();
    const { hash, state: sharedPost } = useLocation();
    const visible = hash.startsWith('#idp-content-request');
    const [mount, setMount] = useModalVisibility(visible);
    const post = useAppSelector((state) => {
        if (sharedPost)
            return sharedPost;
        const id = visible ? Number(hash.split('/')[1]) : null;
        return state.posts.posts.items.find((post) => post.id === id);
    });
    const closeModal = () => {
        history.replace({ hash: '' });
    };
    return mount ? (React.createElement(Modal, { open: visible, className: "react-create-content-group", centered: true, closable: true, maskClosable: true, destroyOnClose: true, onCancel: closeModal, afterClose: setMount, footer: null },
        React.createElement(IdpContentRequestForm, { post: post }))) : null;
};
