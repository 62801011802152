export const PrimaryTags = {
    topic: 'Topic',
    testType: 'Test type',
    testSkill: 'Test skill',
};
export const PrimaryTagValues = {
    [PrimaryTags.topic]: ['Preparation', 'Immigration', 'Band scores', 'Test day', 'Results', 'Test format'],
    [PrimaryTags.testType]: [
        'Academic & Academic UKVI',
        'General Training & General Training UKVI',
        'Life Skills (A1, A2 and B1)',
    ],
    [PrimaryTags.testSkill]: ['Listening', 'Reading', 'Writing', 'Speaking'],
};
export const SubTopic = [
    { label: 'Results', options: ['One skill retake', 'EOR', 'General information'] },
    {
        label: 'Preparation',
        options: ['Paid preparation', 'Free preparation', 'Preparation materials', 'Test tips', 'Improving band score'],
    },
    { label: 'Immigration', options: ['Study', 'Work', 'Migration'] },
    { label: 'Band scores', options: ['1 to 4', '5', '6', '7', '8', '9'] },
    { label: 'Test day', options: ['Speaking test', 'Test day procedures', 'Feeling ready'] },
    { label: 'Test format', options: ['On paper', 'On computer', 'IELTS Online'] },
];
export function parseGlobalTags(globalTags) {
    const result = {};
    if (!globalTags) {
        return result;
    }
    for (const tag of globalTags) {
        const nameParts = tag.name.split(' :');
        switch (nameParts[0]) {
            case PrimaryTags.testSkill: {
                result.testSkils = result.testSkils || [];
                result.testSkils.push(tag.name);
                break;
            }
            case PrimaryTags.testType: {
                result.testTypes = result.testTypes || [];
                result.testTypes.push(tag.name);
                break;
            }
            case PrimaryTags.topic: {
                result.topics = result.topics || [];
                result.topics.push(tag.name);
                break;
            }
            default: {
                result.subTopics = result.subTopics || [];
                result.subTopics.push(tag.name);
            }
        }
    }
    return result;
}
