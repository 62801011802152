export function concatClsx(cls, className) {
    return className ? `${cls} ${className}` : cls;
}
export function clsx(...args) {
    return args.filter(Boolean).join(' ');
}
export function recordToCls(classes) {
    return Object.keys(classes)
        .filter((key) => classes[key])
        .join(' ');
}
