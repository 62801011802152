import { Form, Select } from 'antd';
import React from 'react';
import { Button } from 'src/app/components/base/Button';
import { channel, journeyStep, topics } from '../constants/formData';
import { DestinationSelect } from 'src/app/components/destination-select';
import { useIdpContentGroup } from '../hooks/useIdpConentGroup';
import ReactPlayer from 'react-player';
export const IdpContentRequestForm = ({ post }) => {
    var _a, _b, _c;
    const { form, onPublishContent, handleJurneyStepChange, handleTopicsOfInterestChange, handleDestinationChange, handleChannelChange, } = useIdpContentGroup({ post });
    return (React.createElement("div", { className: "create-content-group" },
        React.createElement("h2", { className: "modal-title" }, "Publish content"),
        React.createElement(Form, { name: "content-group-form", className: "content-group-form", layout: "vertical", requiredMark: false, form: form, onFinish: onPublishContent },
            React.createElement("div", { className: "grid grid-cols-2 gap-6" },
                React.createElement("div", null,
                    React.createElement(Form.Item, { name: "jurneyStep", rules: [{ required: true, message: 'Select at least one jurney step' }], label: "Journey step" },
                        React.createElement(Select, { placeholder: "Select jurney step", mode: "multiple", onChange: handleJurneyStepChange, options: journeyStep })),
                    React.createElement(Form.Item, { name: "destination", rules: [{ required: true, message: 'Select at least one destination' }], label: "Destination (Country - City)" },
                        React.createElement(DestinationSelect, { placeholder: "Select destination", onChange: handleDestinationChange }))),
                post ? (post.type === 'image' ? (React.createElement("img", { className: "max-h-[18rem] w-full object-cover", src: ((_a = post.images[0]) === null || _a === void 0 ? void 0 : _a.original) || '', alt: post.caption || 'Image preview' })) : (React.createElement(ReactPlayer, { key: post.id, className: "max-h-[18rem] max-w-[18rem] object-cover", url: ((_b = post.videos[0]) === null || _b === void 0 ? void 0 : _b.qualities['370x370']) || ((_c = post.videos[0]) === null || _c === void 0 ? void 0 : _c.original) || '', width: "100%", height: "100%", playing: false, pip: true, playsinline: true, loop: true, controls: true, config: {
                        file: {
                            attributes: { controlsList: 'nodownload', pip: 'true' },
                        },
                    }, onContextMenu: (event) => event.preventDefault() }))) : (React.createElement("img", { className: "h-40 w-40 object-contain opacity-60", src: "https://cdn.theaccessplatform.com/img/idp-logo.jpg", alt: "Default placeholder" }))),
            React.createElement(Form.Item, { name: "topicOfInterests", rules: [{ required: true, message: 'Select at least one topic' }], label: "Topics of Interest" },
                React.createElement(Select, { placeholder: "Select topic", mode: "multiple", onChange: handleTopicsOfInterestChange, options: topics })),
            React.createElement(Form.Item, { name: "channel", rules: [{ required: true, message: 'Select at least one channel' }], label: "Channel" },
                React.createElement(Select, { placeholder: "Select channel", mode: "multiple", onChange: handleChannelChange, options: channel })),
            React.createElement(Form.Item, { name: "tags", label: "Tags" },
                React.createElement(Select, { disabled: true, mode: "tags", suffixIcon: null })),
            React.createElement("div", { className: "hidden-buttons-block mt-8" },
                React.createElement(Button, { type: "primary", htmlType: "submit", className: "el-btn create-group-btn h-auto" }, "Publish")))));
};
