import { Button } from 'antd';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
const validate = (values) => {
    const errors = {};
    if (!values.old_password) {
        errors.old_password = 'Old Please enter your password';
    }
    if (!values.new_password) {
        errors.new_password = 'Please enter a password';
    }
    else if (!/^(?=.*?[\p{Ll}])(?=.*?[\p{Lu}])(?=.*\d)(?=.*?[\W_]).{8,}$/gu.test(values.new_password)) {
        errors.new_password =
            'Password must be at least 8 characters and contain 1 number, 1 uppercase letter, 1 lowercase letter and 1 symbol';
    }
    if (!values.confirm_password) {
        errors.confirm_password = 'Please enter your password';
    }
    else if (!/^(?=.*?[\p{Ll}])(?=.*?[\p{Lu}])(?=.*\d)(?=.*?[\W_]).{8,}$/gu.test(values.confirm_password)) {
        errors.confirm_password =
            'Password must be at least 8 characters and contain 1 number, 1 uppercase letter, 1 lowercase letter and 1 symbol';
    }
    if (values.new_password != values.confirm_password) {
        errors.confirm_password = 'Passwords don’t match';
    }
    return errors;
};
const renderField = ({ input, label, placeholder, type, className, meta: { touched, error, warning } }) => (React.createElement("div", null,
    React.createElement("input", Object.assign({}, input, { className: className, placeholder: placeholder, type: type })),
    touched && ((error && React.createElement("span", { className: "error" }, error)) || (warning && React.createElement("span", null, warning)))));
/**
 * @deprecated
 * Have to be refactored. Redux forn is not used anymore
 */
function PasswordEditForm({ handleSubmit, onCancel }) {
    return (React.createElement("form", { action: "#", method: "POST", className: "hidden-form", onSubmit: handleSubmit },
        React.createElement("label", { className: "signin-label" },
            React.createElement(Field, { name: "old_password", component: renderField, type: "password", placeholder: "Old Password", className: "signin-input mod-hidden settings-input" })),
        React.createElement("label", { className: "signin-label" },
            React.createElement(Field, { name: "new_password", component: renderField, type: "password", placeholder: "New Password", className: "signin-input mod-hidden settings-input" })),
        React.createElement("label", { className: "signin-label" },
            React.createElement(Field, { name: "confirm_password", component: renderField, type: "password", placeholder: "Confirm Password", className: "signin-input mod-hidden settings-input" })),
        React.createElement("div", { className: "hidden-buttons-block" },
            React.createElement(Button, { htmlType: "submit", className: "settings-btn", type: "link", style: { paddingLeft: 0 }, "data-automation-id": "submit-password" }, "Submit"),
            React.createElement(Button, { className: "settings-btn mod-cancel js-hide-form", type: "link", onClick: onCancel, "data-automation-id": "cancel-password-change" }, "Cancel"))));
}
export default reduxForm({
    form: 'password_edit_form',
    validate,
})(PasswordEditForm);
