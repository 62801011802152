import { Modal } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg';
import { closePublishControlModal, updateLiveStream } from '../../../../redux/actions/live-streams/live-streams-actions';
import { getLivePublishControlModal } from '../../../../redux/selectors';
import { useAppSelector } from '../../../../hooks/store';
import { PublishControlForm } from './publish-control-form';
const PublishControlModal = () => {
    const dispatch = useDispatch();
    const { open, data, submitting } = useAppSelector(getLivePublishControlModal);
    const handleModalClose = () => {
        dispatch(closePublishControlModal());
    };
    const handleSubmit = useCallback((values) => {
        dispatch(updateLiveStream({
            liveStreamId: data === null || data === void 0 ? void 0 : data.id,
            autoPublish: values === null || values === void 0 ? void 0 : values.autoPublish,
        }));
    }, [dispatch, data]);
    return (React.createElement(Modal, { title: "Manage Live Stream publish", open: open, closeIcon: React.createElement(CloseIcon, null), onCancel: handleModalClose, width: 600, className: "tap-event-modal", destroyOnClose: true, footer: null },
        React.createElement(PublishControlForm, { data: data, handleSubmit: handleSubmit, submitting: submitting })));
};
export default PublishControlModal;
