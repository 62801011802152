/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react'
import { Checkbox } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { TWO_TIER_MENU_CHANGE_SUBMENU } from '../../../redux/actions/content/componentsActions'
import { setToggleExperienceBasedQuestions } from '../../../redux/actions/account/account-actions'
import { getUniversityInfo } from '../../../redux/selectors'

const ExperienceBasedQuestions = () => {
    const dispatch = useDispatch()

    const {
        // isAllowedToSkipExperienceBasedQuestions,
        isEnabledExperienceBasedQuestions,
    } = useSelector(getUniversityInfo)

    useEffect(() => {
        dispatch({
            type: TWO_TIER_MENU_CHANGE_SUBMENU,
            payload: {
                activeSubmenu: 'settings',
            },
        })
    }, [])

    const handleChange = useCallback(
        (isAllowed, allowedType) => {
            dispatch(setToggleExperienceBasedQuestions({ isAllowed, allowedType }))
        },
        [dispatch]
    )

    return (
        <section className="content settings" style={{ paddingLeft: '4rem' }}>
            <h3 className="settings-title">Smart Start</h3>

            <div className="settings-grid has-tutorial">
                <div className="column">
                    <div className="tutorial-intro">
                        <p>
                            Kick off a chat with friendly, guided questions that focus on the student experience. Smart
                            Start helps both prospective students and ambassadors by filtering out admin-related
                            queries, making the conversation more meaningful.
                        </p>
                        <p>For universities, it:</p>
                        <ul className="mt-4 list-disc pl-4 text-16">
                            <li>
                                Nudges students towards relevant, detailed questions, making ambassadors more confident
                                in their responses.
                            </li>
                            <li>Reduces frustration by keeping chats focused on what matters.</li>
                            <li>Encourages meaningful interactions over quick, transactional exchanges.</li>
                            <li>
                                Teaches prospective students how to ask impactful questions, leading to stronger
                                engagement.
                            </li>
                        </ul>
                    </div>

                    <div className="experience-based-questions-container">
                        <Checkbox
                            key="enable-questions"
                            checked={isEnabledExperienceBasedQuestions}
                            onChange={(e) => handleChange(e.target.checked, 'enabledFeature')}
                        >
                            Enable Smart Start
                        </Checkbox>
                        {/* <br />
                        <Checkbox
                            key="skip-questions"
                            checked={isAllowedToSkipExperienceBasedQuestions}
                            onChange={(e) => handleChange(e.target.checked, 'skipQuestion')}
                        >
                            Prospective students can't skip experience based questions
                        </Checkbox> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ExperienceBasedQuestions
