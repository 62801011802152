import { App } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { getCommonRoutePath } from 'src/app/helpers/helpers';
import { useAppSelector } from 'src/app/hooks/store';
import { getAccountInfo, selectIsSascOrIdp } from 'src/app/redux/selectors';
import { LicensesEnum } from 'src/types/general';
import { licenseModal } from '../modals/LicenseModal';
export const ProtectedNavLink = (props) => {
    const { modal } = App.useApp();
    const accountInfo = useAppSelector(getAccountInfo);
    const isSascOrIdp = useAppSelector(selectIsSascOrIdp);
    const hasPermission = !isSascOrIdp || ((accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.isSasc) && props.licenseName === LicensesEnum.Reporting);
    const to = !hasPermission ? `${getCommonRoutePath()}/home` : props.to;
    const handleClick = (event) => {
        if (!hasPermission) {
            modal.warning(licenseModal(props.licenseName));
            event.preventDefault();
        }
        if (props.onClick) {
            props.onClick(event);
        }
    };
    return (React.createElement(NavLink, Object.assign({}, props, { to: to, onClick: handleClick }), props.children));
};
