import { Modal } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useModalVisibility } from 'src/app/hooks/useModalVisibility';
import CreateContentGroup from '../../../messaging/components/popups/CreateContentGroup';
export const CreateContentGroupModal = () => {
    const location = useLocation();
    const history = useHistory();
    const visible = location.hash === '#create-content-group';
    const [mount, setMount] = useModalVisibility(visible);
    const closeModal = () => {
        history.replace({ hash: '' });
    };
    return mount ? (React.createElement(Modal, { open: visible, className: "react-create-content-group", centered: true, closable: true, maskClosable: true, destroyOnClose: true, onCancel: closeModal, afterClose: setMount, footer: null },
        React.createElement(CreateContentGroup, { onClosePopup: closeModal, location: location, history: history }))) : null;
};
