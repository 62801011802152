import { Radio } from 'antd';
import React, { useCallback, useState } from 'react';
import { Permissions } from 'src/app/permissions/permissions';
import { PermissionEnum } from 'src/types/general';
import { INVITE_AMBASSADORS_TYPES } from '../types';
export const AmbassadorInviteSelect = ({ onInviteSelect }) => {
    const [buttonValue, setButtonValue] = useState(INVITE_AMBASSADORS_TYPES.TAP);
    const isIdp = Permissions.can(PermissionEnum.IdpCommunity, false) ||
        Permissions.can(PermissionEnum.IdpLiveApp, false) ||
        Permissions.can(PermissionEnum.IdpWhatUni, false);
    const onChangeButton = useCallback((e) => {
        setButtonValue(e.target.value);
        onInviteSelect(e.target.value);
    }, [onInviteSelect]);
    return (React.createElement(Radio.Group, { onChange: onChangeButton, value: buttonValue },
        React.createElement(Radio, { value: INVITE_AMBASSADORS_TYPES.TAP, className: "text-submenu-base" }, "TAP-only ambassadors"),
        React.createElement(Radio, { value: INVITE_AMBASSADORS_TYPES.IDP_AND_TAP, disabled: !isIdp, className: "text-submenu-base" }, "TAP and IDP ambassadors"),
        React.createElement(Radio, { value: INVITE_AMBASSADORS_TYPES.IDP, disabled: !isIdp, className: "text-submenu-base" }, "IDP-only ambassadors")));
};
