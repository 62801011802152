var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import Sprite from 'src/assets/img/symbol/sprite.svg';
export const Icon = (props) => {
    const { icon } = props, svgProps = __rest(props, ["icon"]);
    return (React.createElement("svg", Object.assign({}, svgProps, { "data-icon": icon, role: "img" }),
        React.createElement("use", { xmlnsXlink: "http://www.w3.org/1999/xlink", xlinkHref: `${Sprite}#${icon}` })));
};
