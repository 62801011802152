import React from 'react';
import { concatClsx } from 'src/app/helpers/classNames';
const ChartHeader = ({ title = '', label = '', labelDescription = '', totalLabel = '', totalLabelDescription = '', displaySingleLabel = false, className, }) => {
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: "chart-title" }, title),
        React.createElement("div", { className: concatClsx('chart-label-wrapper', displaySingleLabel ? 'chart-label-wrapper--display-single-label' : '') },
            React.createElement("div", { className: "chart-label-item-wrapper" },
                React.createElement("div", { className: "chart-label" }, totalLabel),
                React.createElement("div", { className: "chart-label-description" }, totalLabelDescription)),
            React.createElement("div", { className: "chart-label-item-wrapper" },
                React.createElement("div", { className: "chart-label" }, label),
                React.createElement("div", { className: "chart-label-description" }, labelDescription)))));
};
export default ChartHeader;
