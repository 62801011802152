import React from 'react';
import { ChartCard } from './ChartCard';
import ChartHeader from '../../components/ChartHeader';
import { Spin } from 'antd';
import AreaChart from '../../components/AreaChart';
import { useGetProspectsQuery } from 'src/app/redux/slices/reporting/idp-conversion-reporting/query';
import { prospectAccountsTooltipFormatter } from '../helpers';
import { useAppSelector } from 'src/app/hooks/store';
import { selectActiveUnivercityId } from 'src/app/redux/selectors/account-selectors';
export const ProspectCountChart = ({ filter }) => {
    const universityId = useAppSelector(selectActiveUnivercityId);
    const { data, isFetching } = useGetProspectsQuery({
        filter,
        universityIds: universityId ? [universityId] : null,
    });
    return (React.createElement(ChartCard, null,
        React.createElement(ChartHeader, { title: "Number of prospects created", label: `Average: ${!isFetching && data ? data.prospects.average : 0}`, labelDescription: "Per day across date range", totalLabel: `Total: ${!isFetching && data ? data.prospects.total : 0}`, totalLabelDescription: "Across date range" }),
        isFetching ? (React.createElement(Spin, { size: "large", className: "flex justify-center mt-20" })) : (React.createElement(AreaChart, { color: "#ff5100", data: data ? [...data.prospects.data] : [], tooltipFormatter: prospectAccountsTooltipFormatter, height: 200 }))));
};
