import { differenceInMinutes } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LIVE_STREAM_STATUS } from '../../../../../../../common/constants';
import { parseDateFromServer } from '../../../../../../../common/utils/date-time-utils';
import { openAmbassadorsModal, openSpeakersModal, openScheduledStreamTypeModal, joinStreamLobby, openPublishControlModal, } from '../../../../../../../redux/actions/live-streams/live-streams-actions';
import { LIVE_STREAMS } from '../../../../../../../redux/actions/live-streams/live-streams-action-types';
import { openNotificationModal } from '../../../../../../../redux/actions/notification-modal/notification-modal-actions';
import { OPEN_ACTION_MODAL } from '../../../../../../../redux/actions/components/componentsActions';
const EventMenuContent = ({ activeEvent, setIsOpen }) => {
    const dispatch = useDispatch();
    const [startEventButtonVisible, setStartEventButtonVisible] = useState(true);
    const { status, fromDate, isStreamActive, isLobbyMode, liveStreamLink } = activeEvent;
    useEffect(() => {
        const interval = setInterval(() => {
            const parsed = parseDateFromServer(fromDate);
            const now = new Date();
            const minutesBeforeEvent = differenceInMinutes(parsed, now);
            if (minutesBeforeEvent < 11) {
                setStartEventButtonVisible(true);
                clearInterval(interval);
            }
        }, 60000);
        return () => clearInterval(interval);
    });
    const handleStartEventClick = () => {
        const { id: liveStreamId, name } = activeEvent;
        setIsOpen(false);
        dispatch(openNotificationModal(LIVE_STREAMS.START_STREAM_CONFIRM, {
            liveStreamId,
            status: 'inProgress',
            name,
        }));
    };
    const handleEditEventClick = () => {
        setIsOpen(false);
        dispatch(openScheduledStreamTypeModal(activeEvent));
    };
    const handleCancelEventClick = () => {
        const { id: liveStreamId, name } = activeEvent;
        setIsOpen(false);
        dispatch(openNotificationModal(LIVE_STREAMS.CANCEL_STREAM_CONFIRM, {
            liveStreamId,
            status: 'cancelled',
            name,
        }));
    };
    const handleManageAmbassadorsClick = () => {
        setIsOpen(false);
        dispatch(openAmbassadorsModal(activeEvent));
    };
    const handleManageSpeakersClick = () => {
        setIsOpen(false);
        dispatch(openSpeakersModal(activeEvent));
    };
    const handleJoinLobby = () => {
        const { id: liveStreamId } = activeEvent;
        setIsOpen(false);
        if (!isLobbyMode) {
            dispatch(joinStreamLobby({ isLobbyMode: true, liveStreamId }));
            dispatch(openNotificationModal(LIVE_STREAMS.JOIN_STREAM_LOBBY));
        }
        else {
            dispatch(joinStreamLobby({ isLobbyMode: false, liveStreamId }));
        }
    };
    const handleStopStreamClick = () => {
        const { id: liveStreamId, name } = activeEvent;
        setIsOpen(false);
        dispatch(openNotificationModal(LIVE_STREAMS.STOP_STREAM_CONFIRM, {
            liveStreamId,
            status: 'completed',
            name,
        }));
    };
    const handleCopyLink = () => {
        const tempInput = document.createElement('input');
        tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px';
        tempInput.value = liveStreamLink;
        document.body.append(tempInput);
        tempInput.select();
        document.execCommand('copy');
        tempInput.remove();
        dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'Event link copied to clipboard',
            },
        });
    };
    const handleManagePublishingClick = () => {
        setIsOpen(false);
        dispatch(openPublishControlModal(activeEvent));
    };
    return (React.createElement("div", { className: "options-drop-block" },
        status === LIVE_STREAM_STATUS.UPCOMING && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "options-drop-item", onClick: handleJoinLobby, "data-automation-id": `${isLobbyMode ? 'leave' : 'join'}-lobby` }, `${isLobbyMode ? 'Leave' : 'Join'} the Stream lobby`),
            startEventButtonVisible && (React.createElement("p", { className: "options-drop-item", onClick: handleStartEventClick, "data-automation-id": "start-stream" }, "Start stream")))),
        (status === LIVE_STREAM_STATUS.UPCOMING || status === LIVE_STREAM_STATUS.IN_PROGRESS) && (React.createElement("p", { className: "options-drop-item", onClick: handleEditEventClick, "data-automation-id": "edit-stream" }, "Edit stream")),
        status === LIVE_STREAM_STATUS.UPCOMING && (React.createElement("p", { className: "options-drop-item", onClick: handleCancelEventClick, "data-automation-id": "cancel-stream" }, "Cancel stream")),
        (status === LIVE_STREAM_STATUS.UPCOMING || status === LIVE_STREAM_STATUS.IN_PROGRESS) && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "options-drop-item", onClick: handleManageAmbassadorsClick, "data-automation-id": "manage-ambassadors" }, "Manage ambassadors"),
            React.createElement("p", { className: "options-drop-item", onClick: handleManageSpeakersClick, "data-automation-id": "manage-speakers" }, "Manage speakers"),
            React.createElement("p", { className: "options-drop-item", onClick: handleManagePublishingClick, "data-automation-id": "manage-publish" }, "Manage publish"),
            React.createElement("p", { className: "options-drop-item", onClick: handleCopyLink, "data-automation-id": "standalone-page-link" }, "Copy stream link"))),
        status === LIVE_STREAM_STATUS.IN_PROGRESS && isStreamActive && (React.createElement("p", { className: "options-drop-item", onClick: handleStopStreamClick, "data-automation-id": "finish-stream" }, "Finish the stream"))));
};
export default EventMenuContent;
