import React from 'react';
import { ChartCard } from './ChartCard';
import ChartHeader from '../../components/ChartHeader';
import { Spin } from 'antd';
import AreaChart from '../../components/AreaChart';
import { useGetFirstResponseTimeQuery } from 'src/app/redux/slices/reporting/idp-conversion-reporting/query';
import { responseTimeTooltipFormatter, yAxisResponseTimeChart } from '../helpers';
import { useAppSelector } from 'src/app/hooks/store';
import { selectActiveUnivercityId } from 'src/app/redux/selectors/account-selectors';
export const FirstResponseTimeChart = ({ filter }) => {
    const universityId = useAppSelector(selectActiveUnivercityId);
    const { data: responseTimeData, isFetching } = useGetFirstResponseTimeQuery({
        filter,
        universityIds: universityId ? [universityId] : null,
    });
    return (React.createElement(ChartCard, null,
        React.createElement(ChartHeader, { title: "Median First Response Time", label: !isFetching && responseTimeData ? responseTimeData.average : 'No data', labelDescription: "Median First Response Time (across date range)", displaySingleLabel: true }),
        isFetching ? (React.createElement(Spin, { size: "large", className: "flex justify-center mt-20" })) : (React.createElement(AreaChart, { color: "#4e607e", data: responseTimeData ? [...responseTimeData.data] : [], tooltipFormatter: responseTimeTooltipFormatter, yAxisFormatter: yAxisResponseTimeChart, height: 200 }))));
};
