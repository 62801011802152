var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import API from 'src/app/api';
import { Select } from 'src/app/components/base/Select';
const fetchUniversitiesData = () => __awaiter(void 0, void 0, void 0, function* () {
    const resp = yield API.dashboardGroup.getIdpAmbassadorsUniversities();
    return resp.data;
});
export const UniversitiesMultiselect = ({ onChange, filters }) => {
    const [options, setOptions] = useState();
    useEffect(() => {
        const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
            const data = yield fetchUniversitiesData();
            if (data === null || data === void 0 ? void 0 : data.length) {
                const parsedItems = data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
                setOptions(parsedItems);
                onChange === null || onChange === void 0 ? void 0 : onChange(parsedItems[0].value);
            }
        });
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (React.createElement(Select, { value: filters === null || filters === void 0 ? void 0 : filters.universityId, onChange: onChange, placeholder: "Select university", className: "min-w-[28rem] max-w-[36rem]", size: "large", options: options, filterOption: (input, option) => {
            return typeof (option === null || option === void 0 ? void 0 : option.label) === 'string' && option.label.toLowerCase().includes(input.toLowerCase());
        } }));
};
