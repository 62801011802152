var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useReducer } from 'react';
import API from 'src/app/api';
import { IdpServiceTypeEnum } from 'src/types/general';
const handleSearch = (query, userList) => {
    const filtered = userList === null || userList === void 0 ? void 0 : userList.filter((user) => {
        const fullName = `${user.name} ${user.lastName}`;
        return fullName.toLowerCase().includes(query.toLowerCase());
    });
    return filtered;
};
const initialState = {
    conversionUsersList: [],
    communityUsersList: [],
    conversionSelectedUsers: [],
    communitySelectedUsers: [],
    conversionLoading: false,
    communityLoading: false,
};
function usersReducer(state, action) {
    switch (action.type) {
        case 'setCommunityLoading':
            return Object.assign(Object.assign({}, state), { communityLoading: true });
        case 'setConversionLoading':
            return Object.assign(Object.assign({}, state), { conversionLoading: true });
        case 'fetchCommunityUsers':
            return Object.assign(Object.assign({}, state), { communityUsersList: action.payload.communityUsersList, communitySelectedUsers: action.payload.communitySelectedUsers, communityLoading: false });
        case 'fetchConversionUsers':
            return Object.assign(Object.assign({}, state), { conversionUsersList: action.payload.conversionUsersList, conversionSelectedUsers: action.payload.conversionSelectedUsers, conversionLoading: false });
        case 'setCommunityUsersIds':
            return Object.assign(Object.assign({}, state), { communitySelectedUsers: action.payload.communityUsersIds });
        case 'setConversionUsersIds': {
            return Object.assign(Object.assign({}, state), { conversionSelectedUsers: action.payload.conversionUsersIds });
        }
        default:
            return state;
    }
}
function useUsersList() {
    const [state, dispatch] = useReducer(usersReducer, initialState);
    const fetchConversionUsers = (_a) => __awaiter(this, [_a], void 0, function* ({ conversionSearch }) {
        dispatch({ type: 'setConversionLoading' });
        try {
            const response = yield API.idpService.getAmbassadorsList({ service: IdpServiceTypeEnum.IdpConversionChat });
            dispatch({
                type: 'fetchConversionUsers',
                payload: {
                    conversionUsersList: conversionSearch
                        ? handleSearch(conversionSearch, response.data.ambassadors)
                        : response.data.ambassadors,
                    conversionSelectedUsers: typeof conversionSearch === 'string' ? state.conversionSelectedUsers : response.data.selected,
                },
            });
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    });
    const fetchCommunityUsers = (_a) => __awaiter(this, [_a], void 0, function* ({ communitySearch }) {
        dispatch({ type: 'setCommunityLoading' });
        try {
            const response = yield API.idpService.getAmbassadorsList({ service: IdpServiceTypeEnum.IdpCommunity });
            dispatch({
                type: 'fetchCommunityUsers',
                payload: {
                    communityUsersList: communitySearch
                        ? handleSearch(communitySearch, response.data.ambassadors)
                        : response.data.ambassadors,
                    communitySelectedUsers: typeof communitySearch === 'string' ? state.communitySelectedUsers : response.data.selected,
                },
            });
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    });
    const setCommunityUsersIds = (userIds) => __awaiter(this, void 0, void 0, function* () {
        dispatch({ type: 'setCommunityUsersIds', payload: { communityUsersIds: userIds } });
    });
    const setConversionUsersIds = (userIds) => __awaiter(this, void 0, void 0, function* () {
        dispatch({ type: 'setConversionUsersIds', payload: { conversionUsersIds: userIds } });
    });
    return { state, fetchConversionUsers, fetchCommunityUsers, setCommunityUsersIds, setConversionUsersIds };
}
export default useUsersList;
