/* eslint-disable camelcase */
import { Button, List } from 'antd';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as Clarity } from '../../../assets/img/svg/clarity_pinned-solid.svg';
import { ReactComponent as Close } from '../../../assets/img/svg/close.svg';
import { useAppSelector } from '../../hooks/store';
import { CHAT_MESSAGES_INFO, UNPIN_MESSAGE } from '../../redux/actions/chat/messagingActions';
import { openNotificationModal } from '../../redux/actions/notification-modal/notification-modal-actions';
import { MessageContent } from '../message-content';
const PinnedMessageContainer = ({ dialogId }) => {
    const pinnedMessages = useAppSelector((state) => state.chatMessages.pinnedMessages);
    const dispatch = useDispatch();
    const data = useMemo(() => {
        return pinnedMessages.map((message) => {
            return {
                title: message.user.name,
                description: React.createElement(MessageContent, { text: message.content.text }),
                id: message.id,
                dialogId: message.dialog_id,
            };
        });
    }, [pinnedMessages]);
    const onUnpinConfirm = (e, messageId) => {
        e.stopPropagation();
        dispatch(openNotificationModal(UNPIN_MESSAGE, { messageId, dialogId }));
    };
    const getMessage = (messageId) => {
        const messageElement = document.querySelector(`[id='${messageId}']`);
        if (messageElement) {
            const child = messageElement.firstElementChild;
            messageElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            child === null || child === void 0 ? void 0 : child.classList.add('highlight-repoted-message');
            setTimeout(() => child === null || child === void 0 ? void 0 : child.classList.remove('highlight-repoted-message'), 2000);
        }
        else {
            dispatch({
                type: CHAT_MESSAGES_INFO,
                payload: {
                    dialog_id: dialogId,
                    blockReloadChat: true,
                    isBlockedScroll: true,
                    loaderDisabled: true,
                    aroundMessageId: messageId,
                },
                onFinish: () => {
                    const messageElement = document.querySelector(`[id='${messageId}']`);
                    if (messageElement) {
                        const child = messageElement.firstElementChild || messageElement.firstChild;
                        messageElement.scrollIntoView({ block: 'nearest', inline: 'start' });
                        child.classList.add('highlight-repoted-message');
                        setTimeout(() => child.classList.remove('highlight-repoted-message'), 2000);
                    }
                },
            });
        }
    };
    if (pinnedMessages.length === 0) {
        return null;
    }
    return (React.createElement(List, { itemLayout: "horizontal", className: "pined-messages-container", dataSource: data, renderItem: (item) => (React.createElement(List.Item, { onClick: () => getMessage(item.id) },
            React.createElement(List.Item.Meta, { avatar: React.createElement(Clarity, null), title: item.title, description: item.description }),
            React.createElement(Button, { type: "link", onClick: (e) => onUnpinConfirm(e, item.id) },
                React.createElement(Close, { className: "unpin-button" })))) }));
};
export default PinnedMessageContainer;
