var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import API from 'src/app/api';
import { useAppDispatch } from 'src/app/hooks/store';
import { OPEN_ACTION_MODAL } from 'src/app/redux/actions/components/componentsActions';
export function useIdpPublishMutation() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    return useMutation({
        mutationFn: (data) => __awaiter(this, void 0, void 0, function* () {
            return API.dashboardGroup.publishIdpContent(data);
        }),
        onSuccess: () => {
            dispatch({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: 'Content was successfully published!',
                },
            });
            history.replace({ hash: '' });
        },
    });
}
