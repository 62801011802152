import { HttpService } from '../../system/services/HttpService';
export default {
    getCommunities: ({ spaceId, limit = 20, offset = 0, universityIds, withGlobalTags }) => {
        const data = {
            limit,
            offset,
            spaceId,
        };
        if (withGlobalTags) {
            data.withGlobalTags = withGlobalTags;
        }
        if (universityIds) {
            data.universityIds = universityIds;
        }
        return HttpService.get('/dashboard/communities/community-chats', data);
    },
    getCommunity: ({ id }) => {
        return HttpService.get(`/dashboard/communities/community-chats/${id}`);
    },
    createCommunity: (data) => {
        return HttpService.formData('/dashboard/communities/community-chats', data);
    },
    updateCommunity: (id, data) => {
        return HttpService.formData(`/dashboard/communities/community-chats/${id}`, data, 'PUT');
    },
    uploadCommunityMembers: (data) => {
        return HttpService.formData('/dashboard/communities/spaces/members', data);
    },
    getCommunitiesMembers: ({ limit = 10, offset = 0, order = 'desc', orderBy = 'invitedAt', universityId, withGlobalTags, spaceId, }) => {
        const data = {
            limit,
            offset,
            order,
            orderBy,
            spaceId,
        };
        if (universityId) {
            data.universityId = universityId;
        }
        if (withGlobalTags) {
            data.withGlobalTags = withGlobalTags;
        }
        return HttpService.get('/dashboard/communities/spaces/members', data);
    },
    getCommunitiesSettings: (data) => {
        return HttpService.get('/dashboard/communities/settings', data);
    },
    reporting: {
        getGrowthChardData: (data) => {
            return HttpService.get('/dashboard/reporting/community/communityGrowth', data);
        },
        getConnectionsData: (data) => {
            return HttpService.get('/dashboard/reporting/community/newChats', data);
        },
        getNumberOfMessagesData: (data) => {
            return HttpService.get('/dashboard/reporting/community/newMessages', data);
        },
        getNationalitiesData: (data) => {
            return HttpService.get('/dashboard/reporting/community/prospectNationalities', data);
        },
        getOverviewData: (data) => {
            return HttpService.get('/dashboard/reporting/community/overview', data);
        },
    },
    getSpaces: (data) => {
        return HttpService.get('/dashboard/communities/spaces', data);
    },
    createSpace: (data) => {
        return HttpService.post('/dashboard/communities/spaces', data);
    },
    getSpace: (id) => {
        return HttpService.get(`/dashboard/communities/spaces/${id}`);
    },
    updateSpace: (id, data) => {
        return HttpService.put(`/dashboard/communities/spaces/${id}`, data);
    },
    getSpacesList: (data) => {
        return HttpService.get('/dashboard/communities/spaces/list', data);
    },
};
