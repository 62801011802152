import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/store';
import { getStreamModal } from '../../../../redux/selectors';
const ModalWrapper = ({ name, component: Component }) => {
    const modalState = useAppSelector(getStreamModal(name)); // TODO: fix type
    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        if (!(modalState === null || modalState === void 0 ? void 0 : modalState.open)) {
            const timeoutId = setTimeout(() => {
                setMounted(false);
            }, 1000);
            return () => clearTimeout(timeoutId);
        }
        else {
            setMounted(true);
        }
    }, [modalState === null || modalState === void 0 ? void 0 : modalState.open, mounted]);
    return mounted ? React.createElement(Component, null) : null;
};
export default ModalWrapper;
