/* eslint-disable camelcase */
import { CLEAR_POSTS_STORE, EDIT_POST_CAPTION_SUCCESS, EDIT_POST_GLOBAL_TAG_FINISH, POSTS_DELETE_SUCCESS, POSTS_INFO_REQUESTED, POSTS_INFO_SUCCESS, PUBLISH_OR_UNPUBLISH_POST_SUCCESS, SET_HOME_VISIBLE_POSTS, UPDATE_SHARED_POST, USERS_POSTS_SUCCESS, bulkDeleteSuccess, bulkPublishSuccess, clearItems, getMorePostsAsync, getNewPostsAsync, setPostsFilters, } from '../../actions/content/postsActions';
import { produce } from 'immer';
import PostsDateHumanizeHelper from 'src/app/helpers/DateHumanizeHelper';
import { groupMapBy } from 'src/app/utils/list';
import { ACCOUNT_LOGIN_SUCCESS } from '../../actions/account/accountActions';
const initialState = {
    posts: {
        items: [],
        limit: 20,
        offset: 0,
        total: 0,
        itemIds: new Set(),
        hasMore: true,
        isLoading: false,
        sortBy: 'created_at',
        filter: {
            type: undefined,
            published: undefined,
        },
    },
    newPostsReceived: false,
    searchItems: [],
    visiblePostsNumber: 0,
    uiData: new Map(),
};
// TODO Fix action types
export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;
    switch (type) {
        case POSTS_INFO_REQUESTED: {
            const data = action.payload;
            return produce(state, (draft) => {
                draft.posts.isLoading = true;
                if (data.offset != null) {
                    draft.posts.offset = data.offset;
                }
                if (data.limit != null) {
                    draft.posts.limit = data.limit;
                }
                if (data.sort != null) {
                    draft.posts.sortBy = data.sort;
                }
                if (data.filter != null) {
                    draft.posts.filter = data.filter;
                }
            });
        }
        case getMorePostsAsync.pending.toString(): {
            return produce(state, (draft) => {
                draft.posts.isLoading = true;
            });
        }
        case getMorePostsAsync.fulfilled.toString(): {
            return produce(state, (draft) => {
                draft.posts.isLoading = false;
                const payload = action.payload; // FIXME: Add type for action
                draft.posts.items = [
                    ...state.posts.items,
                    ...payload.data.posts.map((post) => (Object.assign(Object.assign({}, post), { createdAtHumanized: PostsDateHumanizeHelper(post.created_at) }))),
                ];
                draft.posts.offset = payload.data.request.offset;
                draft.posts.total = payload.data.total;
                draft.posts.hasMore = payload.data.total > payload.data.request.offset + payload.data.request.limit;
                payload.data.posts.forEach((post) => {
                    const key = PostsDateHumanizeHelper(post.created_at);
                    const posts = draft.uiData.get(key);
                    if (posts != null) {
                        posts.push(Object.assign(Object.assign({}, post), { createdAtHumanized: PostsDateHumanizeHelper(post.created_at) }));
                    }
                    else {
                        draft.uiData.set(key, [
                            Object.assign(Object.assign({}, post), { createdAtHumanized: PostsDateHumanizeHelper(post.created_at) }),
                        ]);
                    }
                });
            });
        }
        case getNewPostsAsync.fulfilled.toString(): {
            return produce(state, (draft) => {
                var _a;
                draft.posts.isLoading = false;
                const { data } = payload; // FIXME: Add type for action
                if (((_a = data.posts) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    const postsToAdd = [];
                    data.posts.forEach((item) => {
                        if (!draft.posts.itemIds.has(item.id)) {
                            postsToAdd.push(Object.assign(Object.assign({}, item), { createdAtHumanized: PostsDateHumanizeHelper(item.created_at) }));
                            draft.posts.itemIds.add(item.id);
                        }
                    });
                    draft.posts.items = [...postsToAdd, ...draft.posts.items];
                    draft.posts.total = data.total;
                    postsToAdd.reverse().forEach((post) => {
                        const key = post.createdAtHumanized;
                        const posts = draft.uiData.get(key);
                        if (posts != null) {
                            posts.unshift(post);
                        }
                        else {
                            const data = new Map();
                            data.set(key, [post]);
                            draft.uiData.forEach((value, key) => {
                                data.set(key, value);
                            });
                            draft.uiData = data;
                        }
                    });
                }
            });
        }
        case POSTS_INFO_SUCCESS: {
            return produce(state, (draft) => {
                payload.items.forEach((item) => {
                    if (!draft.posts.itemIds.has(item.id)) {
                        draft.posts.items.push(Object.assign(Object.assign({}, item), { createdAtHumanized: PostsDateHumanizeHelper(item.created_at) }));
                        draft.posts.itemIds.add(item.id);
                    }
                });
                draft.uiData = groupMapBy((item) => item.createdAtHumanized)(draft.posts.items);
                draft.posts.hasMore =
                    payload.total != payload.request.offset &&
                        payload.total > payload.request.offset + payload.request.limit &&
                        payload.items.length === payload.request.limit;
                draft.posts.offset =
                    payload.total == payload.request.offset + payload.request.limit
                        ? payload.total
                        : payload.request.offset;
                draft.posts.total = payload.total;
                draft.posts.isLoading = false;
            });
        }
        case EDIT_POST_CAPTION_SUCCESS: {
            const { post_id: postId, caption } = payload;
            return produce(state, (draft) => {
                const itemIndex = draft.posts.items.findIndex((item) => item.id === postId);
                const item = itemIndex !== -1 ? draft.posts.items[itemIndex] : null;
                if (item != null) {
                    item.caption = caption;
                    const key = PostsDateHumanizeHelper(item.created_at);
                    const posts = draft.uiData.get(key);
                    if (posts != null) {
                        const postIndex = posts.findIndex((post) => post.id === postId);
                        if (postIndex !== -1) {
                            posts[postIndex] = item;
                        }
                    }
                }
            });
        }
        case PUBLISH_OR_UNPUBLISH_POST_SUCCESS: {
            const { post_id: postId, publish } = payload;
            const isPublished = publish;
            return produce(state, (draft) => {
                const itemIndex = draft.posts.items.findIndex((item) => item.id === postId);
                const item = itemIndex !== -1 ? draft.posts.items[itemIndex] : null;
                if (item != null) {
                    item.isPublished = isPublished;
                    const key = PostsDateHumanizeHelper(item.created_at);
                    const posts = draft.uiData.get(key);
                    if (posts != null) {
                        const postIndex = posts.findIndex((post) => post.id === postId);
                        if (postIndex !== -1) {
                            posts[postIndex] = item;
                        }
                    }
                }
            });
        }
        case EDIT_POST_GLOBAL_TAG_FINISH: {
            const { post_id: postId, globalTags } = payload;
            return produce(state, (draft) => {
                const itemIndex = draft.posts.items.findIndex((item) => item.id === postId);
                const item = itemIndex !== -1 ? draft.posts.items[itemIndex] : null;
                if (item != null) {
                    item.globalTags = globalTags;
                    const key = PostsDateHumanizeHelper(item.created_at);
                    const posts = draft.uiData.get(key);
                    if (posts != null) {
                        const postIndex = posts.findIndex((post) => post.id === postId);
                        if (postIndex !== -1) {
                            posts[postIndex] = item;
                        }
                    }
                }
            });
        }
        // TODO Need to fix this
        case USERS_POSTS_SUCCESS: {
            const items = payload.items;
            return produce(state, (draft) => {
                draft.posts.itemIds = new Set();
                draft.posts.items = items.map((item) => {
                    draft.posts.itemIds.add(item.id);
                    return Object.assign(Object.assign({}, item), { createdAtHumanized: PostsDateHumanizeHelper(item.created_at) });
                });
                draft.uiData = groupMapBy((item) => item.createdAtHumanized)(draft.posts.items);
            });
        }
        case ACCOUNT_LOGIN_SUCCESS: {
            return produce(state, (draft) => {
                draft.posts.items = [];
                draft.posts.itemIds = new Set();
                draft.posts.isLoading = false;
            });
        }
        case SET_HOME_VISIBLE_POSTS: {
            return Object.assign(Object.assign({}, state), { visiblePostsNumber: payload.visiblePostsNumber });
        }
        case POSTS_DELETE_SUCCESS: {
            return produce(state, (draft) => {
                var _a, _b, _c;
                const postIndex = draft.posts.items.findIndex((post) => post.id === payload.post_id);
                if (postIndex !== -1) {
                    const key = draft.posts.items[postIndex].createdAtHumanized;
                    draft.posts.items.splice(postIndex, 1);
                    draft.posts.itemIds.delete(payload.post_id);
                    const index = (_a = draft.uiData.get(key)) === null || _a === void 0 ? void 0 : _a.findIndex((post) => post.id === payload.post_id);
                    if (index !== undefined && index !== -1) {
                        (_b = draft.uiData.get(key)) === null || _b === void 0 ? void 0 : _b.splice(index, 1);
                        if (((_c = draft.uiData.get(key)) === null || _c === void 0 ? void 0 : _c.length) === 0) {
                            draft.uiData.delete(key);
                        }
                    }
                }
            });
        }
        case UPDATE_SHARED_POST: {
            return produce(state, (draft) => {
                const postToUpdate = draft.posts.items.find((post) => post.id === payload.postId);
                if (postToUpdate != null) {
                    postToUpdate.isShared = true;
                    const key = PostsDateHumanizeHelper(postToUpdate.created_at);
                    const posts = draft.uiData.get(key);
                    if (posts != null) {
                        const postIndex = posts.findIndex((post) => post.id === payload.postId);
                        if (postIndex !== -1) {
                            posts[postIndex] = postToUpdate;
                        }
                    }
                }
            });
        }
        case CLEAR_POSTS_STORE: {
            return initialState;
        }
        case clearItems.toString(): {
            return produce(state, (draft) => {
                draft.posts.items = [];
                draft.posts.itemIds = new Set();
                draft.posts.isLoading = false;
                draft.posts.hasMore = true;
                draft.posts.offset = 0;
                draft.posts.total = 0;
                draft.uiData.clear();
            });
        }
        case setPostsFilters.toString(): {
            return produce(state, (draft) => {
                Object.keys(payload).forEach((key) => {
                    draft.posts.filter[key] = payload[key];
                });
            });
        }
        case bulkPublishSuccess.toString(): {
            return produce(state, (draft) => {
                // Get posts ids
                const postIds = payload;
                // Filter current postst coz unpublished filter applied
                draft.posts.items = draft.posts.items.filter((post) => !postIds.has(post.id));
                const newUiData = new Map();
                state.uiData.forEach((posts, key) => {
                    const filteredPosts = posts.filter((post) => !postIds.has(post.id));
                    if (filteredPosts.length > 0) {
                        newUiData.set(key, filteredPosts);
                    }
                });
                draft.uiData = newUiData;
            });
        }
        case bulkDeleteSuccess.toString(): {
            return produce(state, (draft) => {
                // Get posts ids
                const postIds = payload;
                // Remove posts from items
                draft.posts.items = draft.posts.items.filter((post) => !postIds.has(post.id));
                // Construct new data for UI
                const newUiData = new Map();
                state.uiData.forEach((posts, key) => {
                    const filteredPosts = posts.filter((post) => !postIds.has(post.id));
                    if (filteredPosts.length > 0) {
                        newUiData.set(key, filteredPosts);
                    }
                });
                draft.uiData = newUiData;
            });
        }
        default: {
            return state;
        }
    }
}
