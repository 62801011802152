import React from 'react';
import { Table, Col, Row, Typography, Space, Input, Modal } from 'antd';
import { Link } from 'react-router-dom';
import Search from '../../../../components/Search';
import { useUserTable } from './use-user-table';
import { UserTableEmpty } from './components';
const { Title } = Typography;
const UserTable = () => {
    const { requestSearch, handleTableChange, searchItems, isTableEmpty, newUsersCount, columnsConfig, dataSource, pagination, usersMaxLimitReached, usersMaxLimitReachedText, handleCloseMaxLimitPopup, } = useUserTable();
    if (isTableEmpty) {
        return React.createElement(UserTableEmpty, null);
    }
    return (React.createElement(Col, { className: "users-container flexible-page" },
        React.createElement(Space, { direction: "vertical", size: 'large' },
            React.createElement(Row, null,
                React.createElement(Col, { flex: 4 },
                    React.createElement(Search, { requestSearch: requestSearch, currentItems: searchItems })),
                React.createElement(Col, { flex: 1 },
                    React.createElement(Link, { to: "/settings/invite", className: "el-btn btn-top-right invite-btn" }, "Invite ambassadors"))),
            React.createElement(Row, null,
                React.createElement(Title, { level: 4, className: "settings-title" },
                    "Ambassadors ",
                    React.createElement("span", null,
                        newUsersCount,
                        " new today"))),
            React.createElement(Table, { columns: columnsConfig, dataSource: dataSource, pagination: pagination, onChange: handleTableChange, rowClassName: "table-row", scroll: { x: 'max-content' } }),
            React.createElement(Input, { className: "university-access-code", type: "text", value: "" })),
        React.createElement(Modal, { open: usersMaxLimitReached, centered: true, width: 420, footer: null, closable: false, maskClosable: true, onCancel: handleCloseMaxLimitPopup },
            React.createElement("div", { className: "max-limit-popup" },
                React.createElement("h3", { className: "modal-title" }, usersMaxLimitReachedText),
                React.createElement("div", { className: "btn-wrap max-limit-users-confirm-button" },
                    React.createElement("button", { type: "submit", className: "el-btn", onClick: handleCloseMaxLimitPopup }, "Ok"))))));
};
export default UserTable;
