import { HttpService } from 'src/app/system/services/HttpService';
export default {
    getUserCountry: () => {
        return HttpService.get('/common/data/country');
    },
    getCountriesList: (data) => {
        return HttpService.get('/common/data/countries', data);
    },
    getNationalities: () => {
        return HttpService.get('/common/data/nationalities');
    },
    setTapPageUrl: (data) => {
        return HttpService.put('/dashboard/universities/updateUrl', data);
    },
};
