import { EditOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button } from 'src/app/components/base/Button';
import { textAreaCountFormatter } from 'src/app/components/forms/textarea-count-formatter';
import { useAppDispatch } from 'src/app/hooks/store';
import { changePostCaption } from 'src/app/redux/actions/content/postsActions';
const { TextArea } = Input;
export const Caption = ({ postId, caption: text = '' }) => {
    const dispatch = useAppDispatch();
    const [caption, setCaption] = useState(text);
    const [editCaption, setEditCaption] = useState(false);
    const handleChangeCaption = () => {
        dispatch(changePostCaption(postId, caption));
        setEditCaption(false);
    };
    useEffect(() => {
        setCaption(text);
    }, [text]);
    return editCaption ? (React.createElement(React.Fragment, null,
        React.createElement(TextArea, { className: "w-full", defaultValue: caption, autoSize: { minRows: 3, maxRows: 5 }, placeholder: "Write a caption...", onChange: (e) => setCaption(e.target.value), maxLength: 200, showCount: { formatter: textAreaCountFormatter } }),
        React.createElement("div", { className: "flex justify-end gap-4" },
            React.createElement(Button, { type: "default", onClick: () => setEditCaption(false) }, "Cancel"),
            React.createElement(Button, { onClick: handleChangeCaption }, "Save")))) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-reading-lg" }, caption || 'This post does not have a caption'),
        React.createElement(Button, { type: "text", onClick: () => setEditCaption(true), icon: React.createElement(EditOutlined, null), className: "text-brand-orange hover:!text-brand-orange justify-self-end" }, "Edit caption")));
};
