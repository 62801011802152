import userDefaultAvatar from '../../assets/img/placeholder-user.png'
import store from '../redux/store'
import config from '../system/config'

import { CLOSE_ACTION_MODAL, OPEN_ACTION_MODAL } from '../redux/actions/components/componentsActions'
import React from 'react'
import { startOfWeek, startOfMonth } from 'date-fns'

export const getDateFromMonday = () => {
    return startOfWeek(new Date(), { weekStartsOn: 1 })
}

export const getDateFromStartMonth = () => {
    return startOfMonth(new Date())
}

export const onUserImgError = (e) => {
    // eslint-disable-next-line unicorn/prefer-add-event-listener
    e.target.onerror = null
    e.target.src = userDefaultAvatar
}

export const roles = {
    admin: 'univercity-admin',
    student: 'univercity-student',
}

export const isAllowed = (type) => {
    const account = store.getState().account
    let allow = true
    if (account.account_info && account.account_info.university && account.account_info.university.permissions) {
        for (const perm of account.account_info.university.permissions) {
            if (perm.key === type && !perm.is_enabled) {
                allow = false
            }
        }
    }
    return allow
}

export const areYouSure = (confirm, cancel) => {
    const cancelAction = () => {
        store.dispatch({
            type: CLOSE_ACTION_MODAL,
        })
        if (cancel) {
            cancel()
        }
    }
    store.dispatch({
        type: OPEN_ACTION_MODAL,
        payload: {
            children: (
                <div className="general-popup">
                    <h2 className="modal-title">Are you sure?</h2>
                    <div className="btn-wrap confirm-ban-buttons">
                        <button type="submit" className="el-btn confirm-delete-button" onClick={confirm}>
                            Confirm
                        </button>
                        <button type="button" className="el-btn cancel-delete-button" onClick={cancelAction}>
                            Cancel
                        </button>
                    </div>
                </div>
            ),
            className: 'action-popup',
        },
    })
}

export const getAnyTapUrl = () => {
    const {
        account_info: { isGroupAdmin, university = {}, universityGroup = {} },
        currentGroupUniversityId,
    } = store.getState().account
    const { university_url: universityUrl, url, slug } = university

    if (universityUrl) {
        return universityUrl
    }

    if (url && slug) {
        return `${url}/${slug}`
    }

    if (isGroupAdmin && currentGroupUniversityId) {
        return `${config.tap_page_url}${currentGroupUniversityId}`
    }

    if (isGroupAdmin && !currentGroupUniversityId) {
        return `${config.tap_page_url}group/${universityGroup?.id}`
    }

    return ''
}

export const checkIfAnyTapUrlAvailable = () => {
    return getAnyTapUrl() !== ''
}

export const getUserFingerprint = () => {
    const fingerprintKey = 'AccountFingerprint'
    let accountFingerprint = window.localStorage.getItem(fingerprintKey)

    if (!accountFingerprint) {
        const components = [
            window.navigator.userAgent,
            window.screen.height,
            window.screen.width,
            window.navigator.hardwareConcurrency,
            window.navigator.deviceMemory,
            new Date().getTimezoneOffset(),
        ]

        const data = components.join('|')
        accountFingerprint = btoa(data)

        window.localStorage.setItem(fingerprintKey, accountFingerprint)
    }

    return accountFingerprint
}

export const csvJSON = (text, quoteChar = '"', delimiter = ',') => {
    var rows = text.split('\n')
    var headers = rows[0].split(',')

    const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs')

    const match = (line) => [...line.matchAll(regex)].map((m) => m[2]).slice(0, -1)

    var lines = text.split('\n')
    const heads = headers ?? match(lines.shift())
    lines = lines.slice(1)

    return lines.map((line) => {
        // eslint-disable-next-line unicorn/no-array-reduce
        return match(line).reduce((acc, cur, i) => {
            // replace blank matches with `null`
            const val = cur.length <= 0 ? null : Number(cur) || cur
            const key = heads[i] ?? `{i}`
            return { ...acc, [key]: val }
        }, {})
    })
}

export const getCommonRoutePath = () => {
    const { account_info: accountInfo } = store.getState().account

    let path = ''
    if (accountInfo && !accountInfo.isGroupAdmin && accountInfo.university) {
        path += '/' + accountInfo.university.id
    }
    return path
}

export const isMobile = () => {
    return document.body.clientWidth <= 750
}
