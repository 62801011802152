import { Modal, Tag } from 'antd';
import React, { useState } from 'react';
import { Button } from 'src/app/components/base/Button';
import TagsAddPopup from 'src/app/components/popups/TagsAddPopup';
export const TagsManager = ({ postId, tags }) => {
    const [visible, setVisible] = useState(false);
    const handleClose = () => setVisible(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "text-right" },
            tags != null && tags.length > 0 && (React.createElement("div", { className: "mb-4 w-full text-left" }, tags.map((tag) => {
                return (React.createElement(Tag, { key: tag.id, className: "my-1 bg-tap-dark text-white rounded-2xl whitespace-normal break-words text-reading-lg py-1 px-2" }, tag.name));
            }))),
            React.createElement(Button, { type: "text", onClick: () => setVisible(true), className: "text-brand-orange hover:!text-brand-orange" }, "Open Tags Manager")),
        visible && (React.createElement(Modal, { open: visible, bodyStyle: { padding: 0 }, centered: true, footer: null, closable: false, maskClosable: true, onCancel: handleClose, className: "global-tags-modal", zIndex: 11100 },
            React.createElement(TagsAddPopup, { postId: postId, currentList: tags, handleCloseTagsPopup: handleClose })))));
};
