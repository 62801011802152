const EMPTY_NAME_PLACEHOLDER = 'N/A';
const UserCheckboxModel = (user) => {
    return !user.roles
        ? {
            id: user.id,
            avatar: user.avatar,
            name: `${user.name} ${user.last_name}` || EMPTY_NAME_PLACEHOLDER,
            role: user.profileType ? user.profileType : user.role_name,
        }
        : {
            id: user.id,
            avatar: user.avatar,
            name: `${user.name} ${user.last_name}` || EMPTY_NAME_PLACEHOLDER,
            role: user.profileType ? user.profileType : user.roles[0].name,
        };
};
export const parseUsers = (users) => users.map((user) => UserCheckboxModel(user));
