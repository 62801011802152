import PropTypes from 'prop-types'
import React from 'react'
import { concatClsx } from '../helpers/classNames'

const Loader = ({ className }) => {
    return (
        <div className={concatClsx('loader-component', className)}>
            <svg className="load__icon">
                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
        </div>
    )
}
export default Loader

Loader.propTypes = {
    className: PropTypes.string,
}
