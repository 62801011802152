import { HttpService } from 'src/app/system/services/HttpService';
export default {
    getAll: (data) => {
        return HttpService.get('/dashboard/posts', data);
    },
    bulk: {
        publish: (data) => {
            return HttpService.post('/dashboard/posts/bulk/publish', data);
        },
        delete: (data) => {
            return HttpService.post('/dashboard/posts/bulk/delete', data);
        },
    },
    filters: {
        getAmbassadors(data) {
            return HttpService.get('/dashboard/posts/filters/ambassadors', data);
        },
    },
};
