import { Form, Input, Tag } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { ROLE_KEY } from 'src/app/common/constants';
import { Button } from 'src/app/components/base/Button';
import { Select } from 'src/app/components/base/Select';
import { UsersSearchSelectField } from 'src/app/components/common/form/UserSearchSelectFormField';
import { toGroupSelectOptions, valuesToSelectOptions, } from 'src/app/containers/faq/new_questions/components/popups/forms/toSelectOptions';
import { useAppSelector } from 'src/app/hooks/store';
import { selectAmbassadorsForFormField, selectIsUsersMessagingLoading, } from 'src/app/redux/reducers/content/messagingReducers/selectors';
import { parseGlobalTags, PrimaryTags, PrimaryTagValues, SubTopic } from './idpData';
export const IdpQuestionForm = ({ isEdit, onCancelButtonClick, onSubmit, faqData }) => {
    var _a, _b, _c, _d;
    const [form] = Form.useForm();
    const [tagsToDisplay, setTagsToDisplay] = useState(parseGlobalTags(faqData === null || faqData === void 0 ? void 0 : faqData.tags));
    const ambassadors = useAppSelector(selectAmbassadorsForFormField);
    const isLoading = useAppSelector(selectIsUsersMessagingLoading);
    const [users, admins] = useMemo(() => {
        if (ambassadors.length === 0) {
            return [[], []];
        }
        const ambassadorsSet = new Set(ambassadors.map((user) => user.id));
        const admins = [];
        const users = [];
        if (faqData === null || faqData === void 0 ? void 0 : faqData.users) {
            faqData.users.forEach((user) => {
                if (ambassadorsSet.has(user)) {
                    users.push(user);
                }
                else {
                    admins.push(user);
                }
            });
        }
        form.setFieldValue('users', users);
        return [users, admins];
    }, [ambassadors, faqData === null || faqData === void 0 ? void 0 : faqData.users, form]);
    const handleSubmit = (data) => {
        onSubmit({
            question: data.question,
            selectedUserIds: [...data.users, ...admins],
            globalTags: Object.values(tagsToDisplay).flat().filter(Boolean),
            mode: isEdit ? 'edit' : 'create',
            faqID: faqData === null || faqData === void 0 ? void 0 : faqData.id,
        });
    };
    useEffect(() => {
        if (faqData === null || faqData === void 0 ? void 0 : faqData.tags) {
            const tags = parseGlobalTags(faqData.tags);
            setTagsToDisplay(tags);
            form.setFieldsValue({
                testSkills: tags.testSkils,
                testTypes: tags.testTypes,
                topics: tags.subTopics,
            });
        }
    }, [faqData === null || faqData === void 0 ? void 0 : faqData.tags, form]);
    return (React.createElement(Form, { name: "content-group-form", className: "content-group-form", layout: "vertical", requiredMark: false, form: form, onFinish: handleSubmit, initialValues: {
            question: faqData === null || faqData === void 0 ? void 0 : faqData.question,
            users: users,
        } },
        React.createElement(Form.Item, { name: "question", required: true, label: "Question", rules: [{ required: true, message: 'Question is required' }] },
            React.createElement(Input.TextArea, { placeholder: "Type your question", maxLength: 255, showCount: true })),
        React.createElement(Form.Item, { name: "testSkills", label: "Test skill" },
            React.createElement(Select, { mode: "multiple", allowClear: true, placeholder: "Select test skill", options: valuesToSelectOptions(PrimaryTagValues[PrimaryTags.testSkill], PrimaryTags.testSkill), onChange: (value) => {
                    const tags = Array.isArray(value) && value.length > 0 ? value : undefined;
                    setTagsToDisplay((prev) => {
                        return Object.assign(Object.assign({}, prev), { testSkils: tags });
                    });
                } })),
        React.createElement(Form.Item, { name: "testTypes", label: "Test type" },
            React.createElement(Select, { mode: "multiple", allowClear: true, placeholder: "Select test type", options: valuesToSelectOptions(PrimaryTagValues[PrimaryTags.testType], PrimaryTags.testType), onChange: (value) => {
                    const tags = Array.isArray(value) && value.length > 0 ? value : undefined;
                    setTagsToDisplay((prev) => {
                        return Object.assign(Object.assign({}, prev), { testTypes: tags });
                    });
                } })),
        React.createElement(Form.Item, { name: "topics", label: "Topic" },
            React.createElement(Select, { mode: "multiple", placeholder: "Select topic", allowClear: true, options: toGroupSelectOptions(SubTopic), onChange: (_, options) => {
                    if (Array.isArray(options) && options.length > 0) {
                        const topics = new Set();
                        const subTopics = options.map((option) => {
                            if ('tag' in option) {
                                topics.add(option.tag);
                            }
                            if ('value' in option) {
                                return option.value;
                            }
                            return option.label;
                        });
                        setTagsToDisplay((prev) => {
                            return Object.assign(Object.assign({}, prev), { topics: [...topics], subTopics: subTopics });
                        });
                    }
                    else {
                        setTagsToDisplay((prev) => {
                            return Object.assign(Object.assign({}, prev), { topics: undefined, subTopics: undefined });
                        });
                    }
                } })),
        React.createElement("div", { className: "mb-6 flex flex-wrap gap-y-1.5" }, (_a = tagsToDisplay.testSkils) === null || _a === void 0 ? void 0 :
            _a.map((tag) => (React.createElement(Tag, { color: "magenta", key: tag }, tag))), (_b = tagsToDisplay.testTypes) === null || _b === void 0 ? void 0 :
            _b.map((tag) => (React.createElement(Tag, { color: "cyan", key: tag }, tag))), (_c = tagsToDisplay.topics) === null || _c === void 0 ? void 0 :
            _c.map((tag) => (React.createElement(Tag, { color: "green", key: tag }, tag))), (_d = tagsToDisplay.subTopics) === null || _d === void 0 ? void 0 :
            _d.map((tag) => (React.createElement(Tag, { color: "orange", key: tag }, tag)))),
        React.createElement(UsersSearchSelectField, { label: "Add or remove ambassadors by checking the boxes", roles: [ROLE_KEY.AMBASSADOR], permission: "faq", form: form, name: "users", users: ambassadors, isLoading: isLoading, required: true, rules: [{ required: true, message: 'Select at least one ambassador' }] }),
        React.createElement("div", { className: "mt-8 flex gap-4" },
            React.createElement(Button, { htmlType: "submit" }, isEdit ? 'Update' : 'Create'),
            React.createElement(Button, { htmlType: "reset", ghost: true, onClick: onCancelButtonClick }, "Cancel"))));
};
