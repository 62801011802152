import { Select } from 'antd';
import React from 'react';
const LeadSelect = ({ onChangeLeadType }) => {
    const options = [
        { value: 'tap_feed', label: 'Tap feed' },
        { value: 'ambassadors_list', label: 'List of ambassadors' },
        { value: 'content', label: 'Content' },
        { value: 'live_streams', label: 'Live streams' },
        { value: 'faq', label: 'FAQ' },
        // { value: 'tracked_link', label: 'Tracked link' },
        { value: 'group_chat', label: 'Group chat' },
        { value: 'single_chat', label: 'Individual chat' },
    ];
    return (React.createElement(Select, { defaultValue: "tap_feed", size: "large", style: { width: 'calc(80% + 2rem + 2px)' }, onChange: onChangeLeadType, options: options }));
};
export default LeadSelect;
