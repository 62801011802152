import { numberTooltipFormatter, pluralize, prepareAverageTimeData, prepareTimeData, timeTooltipFormatter, yAxisTimeFormatter, } from 'src/app/helpers/chart';
export function prospectAccountsTooltipFormatter() {
    return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'account'));
}
export function conversationsTooltipFormatter() {
    return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'conversation'));
}
export function messagesTooltipFormatter() {
    return numberTooltipFormatter(this.point.x, this.point.y, pluralize(this.point.y, 'message'));
}
export function responseTimeTooltipFormatter() {
    return timeTooltipFormatter(this.point.x, this.point.y);
}
export function yAxisResponseTimeChart() {
    return yAxisTimeFormatter(this.value);
}
export const colorsUniversity = ['#274496', '#67bfe0', '#4e607e', '#4e9bf3', '#1c66a7', '#acc5db'];
export const dataLabelFormat = `<div style="font-size: 16px; font-weight: normal; color: #ff5100;">{point.percentage:.1f}%</div><br />
    <div style="font-size: 12px; font-weight: normal; color: rgba(0,0,0,0.45); opacity: 0.8">{point.name}</div>`;
export const highcartDataMaper = (chartData) => {
    if (!chartData) {
        return { data: [], average: 0, total: 0 };
    }
    const preparedData = chartData.data.map((item) => {
        return [Date.parse(item.date), item.count];
    });
    return {
        data: preparedData,
        average: Math.round(chartData.average),
        total: chartData.total,
    };
};
export const prepareNationalitiesChartData = (chartData) => {
    if (!chartData) {
        return { data: [], centerText: '<div style="color: rgba(0,0,0,0.45);">No Data</div>' };
    }
    const data = [];
    for (const item in chartData) {
        data.push({ name: item, y: chartData[item].percent });
    }
    return {
        data,
        centerText: `<div style="color: rgba(0,0,0,0.45);">${data.length > 0 ? '' : 'No Data'}</div>`,
    };
};
export const prepareFirstResponseTimeChartData = (chartData) => {
    if (!chartData) {
        return { data: [], average: 'No data' };
    }
    return {
        data: prepareTimeData(chartData.data, []),
        average: prepareAverageTimeData(chartData, 'No data'),
    };
};
