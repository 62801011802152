import { Button } from 'antd'
import React, { PureComponent } from 'react'
import { LicensesEnum } from 'src/types/general'
import { ProtectedButton } from 'src/app/components/protected-button'

const DateHeader = (props) => {
    return <div className="panel-date-header">{props.date.format('DD MMMM YYYY (dddd)')}</div>
}

const NoEvent = (props) => {
    return (
        <>
            <div className="panel-title-description" />
            <ProtectedButton
                licenseName={LicensesEnum.CreateContentGroup}
                type="link"
                data-automation-id="create-content-group"
                className="panel-create-button"
                onClick={props.onCreate}
            >
                + Add Content Group
            </ProtectedButton>
        </>
    )
}

const ViewEvent = (props) => {
    return (
        <>
            <div className="panel-title-description" />
            <div className="panel-text">
                <div className="panel-text-title">{props.name}</div>
                <div className="panel-text-description">{props.description}</div>
            </div>
            <div className="panel-action-buttons-row">
                <Button className="panel-action-button" type="link" onClick={props.onEdit} disabled={props.closed}>
                    Edit
                </Button>
                <Button
                    className="panel-action-button"
                    type="link"
                    onClick={props.onDeactivate}
                    disabled={props.closed}
                >
                    Deactivate group
                </Button>
            </div>
        </>
    )
}

class DayPanel extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            lastDate: this.props.date,
        }

        this.handleEditButtonClick = this.handleEditButtonClick.bind(this)
        this.handleDeactivateButtonClick = this.handleDeactivateButtonClick.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.date !== state.lastDate) {
            state.lastDate = props.date
            return state
        }

        return null
    }

    handleEditButtonClick() {
        if (this.props.id) {
            this.props.onEdit(this.props.id)
        }
    }

    handleDeactivateButtonClick() {
        this.props.onDeactivate(this.props.id)
    }

    renderEventView() {
        if (this.props.name !== '') {
            return (
                <ViewEvent
                    name={this.props.name}
                    description={this.props.description}
                    onEdit={this.handleEditButtonClick}
                    onDeactivate={this.handleDeactivateButtonClick}
                    date={this.props.date}
                    archived={this.props.closed}
                />
            )
        }

        return <NoEvent onCreate={this.props.onGroupCreate} />
    }

    render() {
        return (
            <>
                <DateHeader date={this.props.date} />
                {this.renderEventView()}
            </>
        )
    }
}

export default DayPanel
