var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Input } from 'antd';
import React, { useRef, useState } from 'react';
import { Button } from 'src/app/components/base/Button';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { updateTapPageUrl } from 'src/app/redux/actions/account/account-actions';
import { getCurrentGroupUniversityId, selectUniversityUrl } from 'src/app/redux/selectors/account-selectors';
export const UniversityUrlField = () => {
    const [isEdit, setIsEdit] = useState(false);
    const [error, setError] = useState(null);
    const ref = useRef(null);
    const dispatch = useAppDispatch();
    const universityUrl = useAppSelector(selectUniversityUrl);
    const universityId = useAppSelector(getCurrentGroupUniversityId);
    function handleSave() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            if ((_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.input) === null || _b === void 0 ? void 0 : _b.value) {
                const urlPattern = /^(https?:\/\/)?((([\dA-Za-z-]+\.)+[A-Za-z]{2,}))(:\d{1,5})?(\/\S*)?$/;
                const url = (_d = (_c = ref.current) === null || _c === void 0 ? void 0 : _c.input) === null || _d === void 0 ? void 0 : _d.value;
                if (!urlPattern.test(url) || url.includes('localhost')) {
                    setError('Invalid URL');
                }
                else {
                    try {
                        yield dispatch(updateTapPageUrl({ url, universityId }));
                        setError(null);
                    }
                    catch (error) {
                        setError(error.message);
                    }
                    finally {
                        setIsEdit(false);
                    }
                }
            }
        });
    }
    return (React.createElement("div", { className: "mb-6" },
        React.createElement("div", { className: "flex max-w-[94%] items-center gap-1" }, isEdit ? (React.createElement(React.Fragment, null,
            React.createElement(Input, { ref: ref, type: "text", className: "w-full", defaultValue: universityUrl || '', onChange: () => {
                    if (error)
                        setError(null);
                } }),
            React.createElement(Button, { className: "settings-btn", type: "link", onClick: handleSave }, "Save"),
            React.createElement(Button, { className: "settings-btn", type: "link", onClick: () => {
                    setIsEdit(false);
                } }, "Cancel"))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "hosted-tap-feed text-16" }, universityUrl ? (React.createElement("a", { className: "edit-url-link", href: universityUrl, target: "_blank", rel: "noreferrer" }, universityUrl)) : ('No URL set')),
            React.createElement(Button, { className: "settings-btn", type: "link", onClick: () => {
                    setIsEdit(true);
                } }, "Edit")))),
        error && React.createElement("div", { className: "text-red-500" }, error)));
};
