export const LIVE_EVENT_STATUS = {
    UPCOMING: 'upcoming',
    PREVIOUS: 'previous',
    IN_PROGRESS: 'inProgress',
    CANCELLED: 'cancelled',
};
export const LIVE_STREAM_STATUS = {
    UPCOMING: 'upcoming',
    COMPLETED: 'completed',
    IN_PROGRESS: 'inProgress',
    CANCELLED: 'cancelled',
    ARCHIVED: 'archived',
    UNARCHIVED: 'unarchived',
    ONDEMAND: 'onDemand',
};
export var ChatType;
(function (ChatType) {
    ChatType["CHAT"] = "chat";
    ChatType["CONTENT_GROUP"] = "content-group";
    ChatType["FAQ"] = "faq";
    ChatType["GROUP_CHAT"] = "group-chat";
    ChatType["LIVE_EVENT_SPEAKERS_CHAT"] = "live-event-speakers-chat";
    ChatType["LIVE_STREAM_CHAT"] = "live-stream-chat";
    ChatType["PAST_SCHEDULED_CONTENT_GROUP"] = "past-scheduled-content-group";
    ChatType["COMMUNITY_CHAT"] = "community-chat";
    ChatType["COMMUNITY_PROSPECT_TO_AMBASSADOR_CHAT"] = "community-1-to-1-chat";
    ChatType["COMMUNITY_PROSPECT_TO_PROSPECT_CHAT"] = "community-prospect-to-prospect-chat";
})(ChatType || (ChatType = {}));
export const CHAT_TYPE_KEY = {
    LIVE_STREAM_CHAT: 'live-stream-chat',
};
export var ROLE_KEY;
(function (ROLE_KEY) {
    ROLE_KEY["PROSPECT"] = "prospect";
    ROLE_KEY["AMBASSADOR"] = "univercity-student";
    ROLE_KEY["ADMIN"] = "univercity-admin";
})(ROLE_KEY || (ROLE_KEY = {}));
