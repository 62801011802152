import { Divider } from 'antd';
import React from 'react';
import { prepareBookSesionLink } from 'src/app/containers/account/registration/components/pls-sign-up/components/helpers';
import { UserCountry } from 'src/app/helpers/userCountry';
import { Permissions } from 'src/app/permissions/permissions';
import { PermissionEnum } from 'src/types/general';
import { getModalProps } from '../ant-app/modal';
import './licenseModal.scss';
export const licenseModal = (licenseName) => {
    const isIdpOnly = Permissions.can(PermissionEnum.IdpOnly, false);
    return getModalProps({
        maskClosable: true,
        width: 740,
        className: 'tap-license-modal',
        title: React.createElement("div", { className: "h-10 bg-brand-orange rounded-t" }),
        footer: (React.createElement("div", { className: " py-11 flex flex-row items-center justify-center bg-secondary-gray rounded" }, isIdpOnly ? (React.createElement("a", { href: prepareBookSesionLink(UserCountry.get()), rel: "noopener noreferrer", target: "_blank", className: "text-submenu-base px-17 py-3 rounded-lg border border-brand-orange border-solid font-interface-bold text-brand-orange hover:text-brand-orange" }, "Let's chat")) : (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "text-xl font-interface-bold" }, "Let's chat"),
            React.createElement(Divider, { type: "vertical", className: "h-6.5 bg-black" }),
            React.createElement("a", { href: "https://meetings.hubspot.com/kirsty-o-sullivan", rel: "noopener noreferrer", target: "_blank", className: "text-xl font-interface-bold text-brand-orange underline hover:underline hover:text-brand-orange" }, "Book an appointment"))))),
        content: (React.createElement("div", { className: "py-10 flex flex-col items-center text-center" },
            React.createElement("h3", { className: "text-header-sm font-interface-bold" }, "This feature is only available to users of The Ambassador Platform."),
            React.createElement("p", { className: "px-27.5 py-10 text-submenu-base" },
                "To access ",
                licenseName,
                " feature you need a license of The Ambassador Platform. Book a chat with us to discover everything the platform has to offer."))),
    });
};
