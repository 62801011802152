import { Input } from 'antd';
import React from 'react';
import { Button } from 'src/app/components/base/Button';
import { concatClsx } from 'src/app/helpers/classNames';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import { usePusher } from 'src/app/hooks/usePusher';
import { UPLOAD_FILE_INFO_SUCCESS, transcodeVideo, uploadImage, uploadVideo, } from 'src/app/redux/actions/content/postsActions';
import { openNotificationModal } from 'src/app/redux/actions/notification-modal/notification-modal-actions';
import { getAccountInfo } from 'src/app/redux/selectors';
import Sprite from 'src/assets/img/symbol/sprite.svg';
export const UploadContent = ({ file, onDone }) => {
    const src = URL.createObjectURL(file);
    const ref = React.useRef(null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const type = file.type.split('/')[0];
    const showInput = type === 'video' || type === 'image';
    const accountInfo = useAppSelector(getAccountInfo);
    const dispatch = useAppDispatch();
    // const [fileData, setFileData] = React.useState<{
    //     caption: string
    //     jobId: number
    //     fileData: FileData
    // } | null>(null)
    // Never fires
    usePusher(`file:transcoding:${accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.university_id}:${accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.id}`, (data) => {
        console.log('usePusher', data);
    });
    const handleSendFile = () => {
        var _a, _b;
        setError(null);
        setLoading(true);
        if (!file) {
            return;
        }
        const caption = ((_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.input) === null || _b === void 0 ? void 0 : _b.value) || '';
        let fileType = file.type;
        if (!fileType) {
            if (file.name.indexOf('.vob')) {
                fileType = 'video/vob';
            }
            else if (file.name.indexOf('.flv')) {
                fileType = 'video/flv';
            }
            else if (file.name.indexOf('.rmvb')) {
                fileType = 'video/rmvb';
            }
        }
        if (fileType.startsWith('image/')) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', () => {
                if (reader.result != null && typeof reader.result === 'string') {
                    dispatch(uploadImage({
                        type: 'image',
                        base64: reader.result.slice(reader.result.indexOf(',') + 1),
                        base64_original: reader.result.slice(reader.result.indexOf(',') + 1),
                        caption: caption,
                        onReady: () => {
                            onDone();
                        },
                        onError: () => {
                            setError('File upload failed');
                            setLoading(false);
                        },
                    }));
                }
            });
        }
        else if (file.type.includes('video/')) {
            const formData = new FormData();
            formData.append('file', file);
            dispatch(transcodeVideo({
                formData,
                callback: (jobId, fileData) => {
                    // setFileData({
                    //     caption: caption,
                    //     jobId,
                    //     fileData,
                    // })
                    dispatch(uploadVideo({
                        file_id: fileData.id,
                        caption: caption,
                        // jobId is null
                        currentJobId: jobId,
                        callback: () => {
                            onDone();
                            openNotificationModal(UPLOAD_FILE_INFO_SUCCESS);
                        },
                    }));
                },
            }));
        }
    };
    return (React.createElement("div", { className: "px-4 pb-4" },
        React.createElement("h3", { className: "text-lg py-4 pr-10" }, file.name || 'Preview'),
        React.createElement("div", { className: "pb-4" }, type === 'image' ? (React.createElement("img", { alt: "preview", className: "w-full", src: src })) : type === 'video' ? (React.createElement("video", { controls: true, controlsList: "nodownload", className: "w-full", src: src })) : (React.createElement("div", { className: "h-16 flex items-center justify-center text-xl" }, "Unsupported file type"))),
        error && React.createElement("div", { className: "text-red-500 py-4" }, error),
        showInput && (React.createElement("div", { className: "flex gap-4 " },
            React.createElement(Input, { type: "text", className: "flex-1 rounded-full", placeholder: "Caption\u2026", maxLength: 200, ref: ref, size: "large" }),
            React.createElement(Button, { type: "primary", onClick: handleSendFile, loading: loading, shape: "circle", size: "large" },
                React.createElement("svg", { className: concatClsx('icon icon-send', loading ? '!hidden' : undefined) },
                    React.createElement("use", { xmlnsXlink: "http://www.w3.org/1999/xlink", xlinkHref: Sprite + '#send' })))))));
};
