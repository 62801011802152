var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { concatClsx } from 'src/app/helpers/classNames';
import { Icon } from '../../base/Icon';
export const ToolbarButton = (_a) => {
    var { title, icon, className, children } = _a, props = __rest(_a, ["title", "icon", "className", "children"]);
    return (React.createElement("button", Object.assign({ className: concatClsx('toolbar-btn', className), "aria-disabled": props.disabled }, props),
        icon && React.createElement(Icon, { icon: icon, className: "size-4" }),
        title || children));
};
