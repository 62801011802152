var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable camelcase */
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../api';
export const POSTS_INFO_REQUESTED = 'POSTS_INFO_REQUESTED';
export const POSTS_INFO_SUCCESS = 'POSTS_INFO_SUCCESS';
export const POSTS_INFO_FAILED = 'POSTS_INFO_FAILED';
export const TAGS_INFO_FAILED = 'TAGS_INFO_FAILED';
export const POSTS_TAGS_FILTER_REQUESTED = 'POSTS_TAGS_FILTER_REQUESTED';
export const ADD_DOWNLOAD_POINTS = 'ADD_DOWNLOAD_POINTS';
export const POSTS_DELETE = 'POSTS_DELETE';
export const POSTS_DELETE_FAILED = 'POSTS_DELETE_FAILED';
export const POSTS_DELETE_SUCCESS = 'POSTS_DELETE_SUCCESS';
export const OPEN_SHARE_POST_POPUP = 'OPEN_SHARE_POST_POPUP';
export const CLOSE_SHARE_POST_POPUP = 'CLOSE_SHARE_POST_POPUP';
export const SHARE_POST_REQUESTED = 'SHARE_POST_REQUESTED';
export const SHARE_POST_SUCCESS = 'SHARE_POST_SUCCESS';
export const SHARE_POST_FAILED = 'SHARE_POST_FAILED';
export const PUBLISH_POST_REQUESTED = 'PUBLISH_POST_REQUESTED';
export const PUBLISH_POST_SUCCESS = 'PUBLISH_POST_SUCCESS';
export const PUBLISH_POST_FAILED = 'PUBLISH_POST_FAILED';
export const PUBLISH_OR_UNPUBLISH_POST_SUCCESS = 'PUBLISH_OR_UNPUBLISH_POST_SUCCESS';
export const EDIT_POST_CAPTION_REQUESTED = 'EDIT_POST_CAPTION_REQUESTED';
export const EDIT_POST_CAPTION_SUCCESS = 'EDIT_POST_CAPTION_SUCCESS';
export const EDIT_POST_CAPTION_FAILED = 'EDIT_POST_CAPTION_FAILED';
export const USERS_POSTS_REQUESTED = 'USERS_POSTS_REQUESTED';
export const USERS_POSTS_SUCCESS = 'USERS_POSTS_SUCCESS';
export const USERS_POSTS_FAILED = 'USERS_POSTS_FAILED';
export const UPLOAD_FILE_INFO = 'UPLOAD_FILE_INFO';
export const UPLOAD_FILE_INFO_SUCCESS = 'UPLOAD_FILE_INFO_SUCCESS';
export const UPLOAD_FILE_INFO_FAILED = 'UPLOAD_FILE_INFO_FAILED';
export const OPEN_CONFIRM_DELETE_POPUP = 'OPEN_CONFIRM_DELETE_POPUP';
export const CLOSE_CONFIRM_DELETE_POPUP = 'CLOSE_CONFIRM_DELETE_POPUP';
export const CLEAR_POSTS_STORE = 'CLEAR_POSTS_STORE';
export const OPEN_SHARE_POST_CONTENT_PREVIEW_MODAL = 'OPEN_SHARE_POST_CONTENT_PREVIEW_MODAL';
export const CLOSE_SHARE_POST_CONTENT_PREVIEW_MODAL = 'CLOSE_SHARE_POST_CONTENT_PREVIEW_MODAL';
export const CONTENT_GROUP_POSTS_REQUESTED = 'CONTENT_GROUP_POSTS_REQUESTED';
export const EDIT_POST_GLOBAL_TAG = 'EDIT_POST_GLOBAL_TAG';
export const EDIT_POST_GLOBAL_TAG_FINISH = 'EDIT_POST_GLOBAL_TAG_FINISH';
export const VIDEO_UNIVERSITY_TRANSCODE = 'VIDEO_UNIVERSITY_TRANSCODE';
export const VIDEO_UPLOAD = 'VIDEO_UPLOAD';
export const UPDATE_SHARED_POST = 'UPDATE_SHARED_POST';
export const SET_HOME_VISIBLE_POSTS = 'SET_HOME_VISIBLE_POSTS';
export const changePostCaption = (postId, caption) => ({
    type: EDIT_POST_CAPTION_REQUESTED,
    payload: {
        post_id: postId,
        caption,
    },
});
export const sharePostToRSS = (postId, caption, onSuccess) => ({
    type: SHARE_POST_REQUESTED,
    payload: {
        post_id: postId,
        caption,
        onSuccess,
    },
});
export const togglePublishPost = (postId, publish) => ({
    type: PUBLISH_POST_REQUESTED,
    payload: {
        post_id: postId,
        publish,
    },
});
export const openDeleteModal = (id, type) => ({
    type: OPEN_CONFIRM_DELETE_POPUP,
    payload: {
        postId: id,
        type,
    },
});
export const closeDeleteModal = () => ({
    type: CLOSE_CONFIRM_DELETE_POPUP,
});
export const getPosts = createAction(POSTS_INFO_REQUESTED);
export const clearPosts = createAction(CLEAR_POSTS_STORE);
export const clearItems = createAction('CLEAR_POSTS_ITEMS');
export const addDownloadPoints = createAction(ADD_DOWNLOAD_POINTS, (postId) => ({
    payload: {
        post_id: postId,
    },
}));
export const getMorePostsAsync = createAsyncThunk('POSTS_INFO_REQUESTED_MORE', (payload_1, _a) => __awaiter(void 0, [payload_1, _a], void 0, function* (payload, { getState }) {
    var _b;
    const state = getState();
    const isIdpGroupAdmin = (_b = state.account.account_info) === null || _b === void 0 ? void 0 : _b.isIdpGroupAdmin;
    const data = state.posts.posts;
    const apiCall = isIdpGroupAdmin ? API.dashboardGroup.getIdpPosts : API.posts.getAll;
    const filters = Object.assign(Object.assign({}, data.filter), payload === null || payload === void 0 ? void 0 : payload.filter);
    const basePayload = {
        search: payload === null || payload === void 0 ? void 0 : payload.search,
        sort: (payload === null || payload === void 0 ? void 0 : payload.sort) || data.sortBy,
        limit: data.limit,
        offset: data.offset + data.limit,
    };
    const requestPayload = isIdpGroupAdmin
        ? Object.assign(Object.assign({}, basePayload), { type: data.filter.type, universityId: data.filter.universityId }) : Object.assign(Object.assign({}, basePayload), { filter: filters, withGlobalTags: (payload === null || payload === void 0 ? void 0 : payload.withGlobalTags) || true, postsInIds: payload === null || payload === void 0 ? void 0 : payload.postsInIds, postsNotInIds: payload === null || payload === void 0 ? void 0 : payload.postsNotInIds, universityId: state.account.currentGroupUniversityId || null });
    return apiCall(requestPayload);
}));
export const getNewPostsAsync = createAsyncThunk('POSTS_INFO_REQUESTED_NEW', (payload_1, _a) => __awaiter(void 0, [payload_1, _a], void 0, function* (payload, { getState }) {
    var _b;
    const state = getState();
    const isIdpGroupAdmin = (_b = state.account.account_info) === null || _b === void 0 ? void 0 : _b.isIdpGroupAdmin;
    const data = state.posts.posts;
    const apiCall = isIdpGroupAdmin ? API.dashboardGroup.getIdpPosts : API.posts.getAll;
    return apiCall({
        search: payload === null || payload === void 0 ? void 0 : payload.search,
        sort: (payload === null || payload === void 0 ? void 0 : payload.sort) || data.sortBy,
        limit: 10,
        offset: 0,
        withGlobalTags: (payload === null || payload === void 0 ? void 0 : payload.withGlobalTags) || true,
        universityId: isIdpGroupAdmin ? data.filter.universityId : state.account.currentGroupUniversityId || null,
        postsInIds: payload === null || payload === void 0 ? void 0 : payload.postsInIds,
        postsNotInIds: payload === null || payload === void 0 ? void 0 : payload.postsNotInIds,
    });
}));
export const uploadImage = createAction(UPLOAD_FILE_INFO);
export const transcodeVideo = createAction(VIDEO_UNIVERSITY_TRANSCODE);
export const uploadVideo = createAction(VIDEO_UPLOAD);
export const deletePost = createAction(POSTS_DELETE);
export const setPostsFilters = createAction('@SET_POSTS_FILTERS');
export const bulkPublishAsync = createAsyncThunk('POSTS_BULK_PUBLISH_ASYNC', (params) => __awaiter(void 0, void 0, void 0, function* () {
    return API.posts.bulk.publish(params);
}));
export const bulkPublishSuccess = createAction('@BULK_PUBLISH_SUCCESS');
export const bulkDeleteSuccess = createAction('@BULK_DELETE_SUCCESS');
