import { useEffect, useRef } from 'react';
function useUnmountEffect(callback) {
    const callbackRef = useRef(callback);
    // Update the ref each render so it always has the latest callback
    callbackRef.current = callback;
    useEffect(() => {
        return () => {
            callbackRef.current();
        };
    }, []);
}
export default useUnmountEffect;
