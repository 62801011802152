import React from 'react';
import { useHistory } from 'react-router';
import { Select } from 'src/app/components/base/Select';
import { ProtectedButton } from 'src/app/components/protected-button';
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store';
import useUnmountEffect from 'src/app/hooks/useUnmountEffect';
import { CLEAR_POSTS_STORE, setPostsFilters } from 'src/app/redux/actions/content/postsActions';
import { selectPostsFilters } from 'src/app/redux/selectors/posts-selectors';
import { LicensesEnum } from 'src/types/general';
import { UploadContentButton } from './UploadContentButton';
import { BulkOperations } from './bulk/BulkOperations';
import { UserMultiselect } from './filters/UserMultiselect';
export const PostsListHeader = () => {
    const filters = useAppSelector(selectPostsFilters);
    const dispatch = useAppDispatch();
    const history = useHistory();
    useUnmountEffect(() => {
        dispatch({
            type: CLEAR_POSTS_STORE,
        });
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "search flex flex-wrap gap-4 p-5" },
            React.createElement("div", { className: "flex items-center gap-4 text-base" },
                React.createElement("span", { className: "whitespace-nowrap" }, "Content type:"),
                React.createElement(Select, { size: "large", className: "w-[10rem]", value: (filters === null || filters === void 0 ? void 0 : filters.type) || 'all', onChange: (value) => {
                        dispatch(setPostsFilters({ type: value === 'all' ? undefined : value }));
                    }, options: [
                        {
                            label: 'All',
                            title: 'All',
                            value: 'all',
                        },
                        {
                            label: 'Photos',
                            title: 'Photos',
                            value: 'image',
                        },
                        {
                            label: 'Videos',
                            title: 'Videos',
                            value: 'video',
                        },
                    ] })),
            React.createElement("div", { className: "flex items-center gap-4 text-base" },
                React.createElement("span", { className: "whitespace-nowrap" }, "Published:"),
                React.createElement(Select, { size: "large", className: "w-[13rem]", value: (filters === null || filters === void 0 ? void 0 : filters.published) || 'all', onChange: (value) => {
                        dispatch(setPostsFilters({ published: value === 'all' ? undefined : value }));
                    }, popupMatchSelectWidth: false, options: [
                        {
                            label: 'All',
                            title: 'All',
                            value: 'all',
                        },
                        {
                            label: 'Published',
                            title: 'Published',
                            value: 'true',
                        },
                        {
                            label: 'Unpublished',
                            title: 'Unpublished',
                            value: 'false',
                        },
                    ] })),
            React.createElement("div", { className: "flex items-center gap-4 text-base" },
                React.createElement("span", { className: "whitespace-nowrap" }, "Ambassadors:"),
                React.createElement(UserMultiselect, { placeholder: "Select ambassadors", onChange: (value) => {
                        dispatch(setPostsFilters({ publisherIds: value }));
                    }, className: "min-w-[28rem] max-w-[36rem]", size: "large", filters: filters })),
            React.createElement(UploadContentButton, null),
            React.createElement(ProtectedButton, { licenseName: LicensesEnum.CreateContentGroup, type: "secondary", "data-automation-id": "create-content-group", size: "large", onClick: () => history.push({
                    hash: '#create-content-group',
                }) }, "Create Content Group")),
        React.createElement(BulkOperations, { className: "bulk-action mx-5 mb-4" })));
};
