import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ReactComponent as TimeIcon } from '../../../../../../../assets/svg/pie-chart.svg'
import { CHAT_TYPE_KEY, LIVE_STREAM_STATUS } from '../../../../../../common/constants'
import { parseDateFromServer } from '../../../../../../common/utils/date-time-utils'
import { getActiveLiveStream, getChatMessagesSlice } from '../../../../../../redux/selectors'

import EventMenu from './components/event-menu'
import ReportingPopover from '../../../../../../components/ReportingPopover'
import { TAB_KEY } from '../chat-sidebar/chat-sidebar-constants'

const FROM_FORMAT = 'dd MMMM yyyy, HH:mm aa'
const TO_FORMAT = 'HH:mm aa'

const EventHeader = () => {
    const activeEvent = useSelector(getActiveLiveStream)
    const activeTab = useSelector((state) => state.liveStreams.activeTab)
    const chatMessages = useSelector(getChatMessagesSlice)

    const { fromDate, toDate, status, name } = activeEvent

    const dialogId = useMemo(() => {
        const { chat, speakersChat } = activeEvent
        return activeTab === TAB_KEY.CHAT ? chat.id : speakersChat.id
    }, [activeEvent, activeTab])
    const showEventMenu = useMemo(() => {
        const isUpcoming = status === LIVE_STREAM_STATUS.UPCOMING
        const isInProgress = status === LIVE_STREAM_STATUS.IN_PROGRESS

        return isUpcoming || isInProgress
    }, [status])

    const fromTime = useMemo(() => {
        const parsed = parseDateFromServer(fromDate)

        return format(parsed, FROM_FORMAT)
    }, [fromDate])

    const toTime = useMemo(() => {
        const parsed = parseDateFromServer(toDate)

        return format(parsed, TO_FORMAT)
    }, [toDate])

    return (
        <div className="event-head">
            <div className="chat-head-left">
                <div className="chat-name-block chat-name-block--chat">
                    <div className="chat-name-block__info">
                        <div className="chat-nav-name-container">
                            <h3 className="chat-name">{name}</h3>
                            {chatMessages.totalReportedMessages ? (
                                <ReportingPopover dialogId={dialogId} chatType={CHAT_TYPE_KEY.LIVE_STREAM_CHAT} />
                            ) : null}
                        </div>
                        <div className="live-event-date">
                            <TimeIcon />
                            <span>{fromTime + ' - ' + toTime}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="chat-head-right">{showEventMenu && <EventMenu activeEvent={activeEvent} />}</div>
        </div>
    )
}

export default EventHeader
