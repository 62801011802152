import { call, put, select } from 'redux-saga/effects'
import API from '../../../api'
import {
    CREATE_GLOBAL_FAQ_TAG_FAILED,
    CREATE_GLOBAL_FAQ_TAG_SUCCESS,
    CREATE_GLOBAL_POST_TAG_FAILED,
    CREATE_GLOBAL_POST_TAG_SUCCESS,
    CREATE_GLOBAL_TAG_FAILED,
    CREATE_GLOBAL_TAG_SUCCESS,
    CREATE_GLOBAL_USER_TAG_FAILED,
    CREATE_GLOBAL_USER_TAG_SUCCESS,
    GET_GLOBAL_FAQ_TAGS_FAILED,
    GET_GLOBAL_FAQ_TAGS_SUCCESS,
    GET_GLOBAL_LIST_TAGS_FAILED,
    GET_GLOBAL_LIST_TAGS_SUCCESS,
    GET_GLOBAL_POST_TAGS_FAILED,
    GET_GLOBAL_POST_TAGS_SUCCESS,
    GET_GLOBAL_USER_TAGS_FAILED,
    GET_GLOBAL_USER_TAGS_SUCCESS,
    REMOVE_GLOBAL_FAQ_TAG_FAILED,
    REMOVE_GLOBAL_FAQ_TAG_SUCCESS,
    REMOVE_GLOBAL_POST_TAG_FAILED,
    REMOVE_GLOBAL_POST_TAG_SUCCESS,
    REMOVE_GLOBAL_USER_TAG_SUCCESS,
} from '../../actions/tags/tagsActions'

const getCurrentGroupUniversityIds = (state) =>
    state.account.currentGroupUniversityId ? [state.account.currentGroupUniversityId] : null
const getCurrentGroupUniversityId = (state) => state.account.currentGroupUniversityId

export function* createGlobalTag(action) {
    const payload = action.payload

    try {
        const universityId = yield select(getCurrentGroupUniversityId)

        const response = yield call(() => {
            return API.tag.createTag(payload.name, universityId)
        })

        if (response && response.success) {
            if (payload.afterSuccess) {
                payload.afterSuccess(response.data.globalTag)
            }

            yield put({
                type: CREATE_GLOBAL_TAG_SUCCESS,
            })
        } else {
            yield put({
                type: CREATE_GLOBAL_TAG_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        if (error.status === 409 && payload.afterConflict) {
            payload.afterConflict()
        }
        yield put({
            type: CREATE_GLOBAL_TAG_FAILED,
        })
    }
}

export function* globalTags(action) {
    const payload = action.payload
    const currentGroupUniversityIds = yield select(getCurrentGroupUniversityIds)

    try {
        const response = yield call(() => {
            return API.tag.getAllTags(
                currentGroupUniversityIds,
                payload.limit,
                payload.offset,
                payload.searchName,
                payload.excludeIds
            )
        })
        yield response && response.success
            ? put({
                  type: GET_GLOBAL_LIST_TAGS_SUCCESS,
                  payload: {
                      items: response.data.globalTags,
                      total: response.data.total,
                      offset: payload.offset,
                  },
              })
            : put({
                  type: GET_GLOBAL_LIST_TAGS_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: GET_GLOBAL_LIST_TAGS_FAILED,
        })
    }
    if (payload.afterFinished) payload.afterFinished()
}

export function* createUserGlobalTag(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.tag.createUserTag(payload.userId, payload.globalTagsIds)
        })

        if (response && response.success) {
            if (payload.afterSuccess) payload.afterSuccess(response.data.currentGlobalTags)

            yield put({
                type: CREATE_GLOBAL_USER_TAG_SUCCESS,
            })
        } else {
            yield put({
                type: CREATE_GLOBAL_USER_TAG_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CREATE_GLOBAL_USER_TAG_FAILED,
        })
    }
}

export function* userGlobalTags(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.tag.getUserTags(payload.id)
        })
        yield response && response.success
            ? put({
                  type: GET_GLOBAL_USER_TAGS_SUCCESS,
                  payload: {
                      items: response.data.globalTags,
                  },
              })
            : put({
                  type: GET_GLOBAL_USER_TAGS_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: GET_GLOBAL_USER_TAGS_FAILED,
        })
    }
}

export function* removeUserGlobalTag(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.tag.removeUserTag(payload.userId, payload.globalTagsIds)
        })

        if (response && response.success) {
            if (payload.afterSuccess) payload.afterSuccess(response.data.currentGlobalTags)

            yield put({
                type: REMOVE_GLOBAL_USER_TAG_SUCCESS,
            })
        } else {
            yield put({
                type: REMOVE_GLOBAL_POST_TAG_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: REMOVE_GLOBAL_POST_TAG_FAILED,
        })
    }
}

export function* createPostGlobalTag(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.tag.createPostTag(payload.postId, payload.globalTagsIds)
        })

        if (response && response.success) {
            if (payload.afterSuccess) payload.afterSuccess(response.data.currentGlobalTags)

            yield put({
                type: CREATE_GLOBAL_POST_TAG_SUCCESS,
            })
        } else {
            yield put({
                type: CREATE_GLOBAL_POST_TAG_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CREATE_GLOBAL_POST_TAG_FAILED,
        })
    }
}

export function* postGlobalTags(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.tag.getPostTags(payload.id)
        })
        yield response && response.success
            ? put({
                  type: GET_GLOBAL_POST_TAGS_SUCCESS,
                  payload: {
                      items: response.data.globalTags,
                  },
              })
            : put({
                  type: GET_GLOBAL_POST_TAGS_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: GET_GLOBAL_POST_TAGS_FAILED,
        })
    }
}

export function* removePostGlobalTag(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.tag.removePostTag(payload.postId, payload.globalTagsIds)
        })
        if (response && response.success) {
            if (payload.afterSuccess) payload.afterSuccess(response.data.currentGlobalTags)

            yield put({
                type: REMOVE_GLOBAL_POST_TAG_SUCCESS,
            })
        } else {
            yield put({
                type: REMOVE_GLOBAL_POST_TAG_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: REMOVE_GLOBAL_POST_TAG_FAILED,
        })
    }
}

export function* createFaqGlobalTag(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.tag.createFaqTag(payload.faqId, payload.globalTagsIds)
        })

        if (response && response.success) {
            if (payload.afterSuccess) payload.afterSuccess(response.data.currentGlobalTags)

            yield put({
                type: CREATE_GLOBAL_FAQ_TAG_SUCCESS,
            })
        } else {
            yield put({
                type: CREATE_GLOBAL_FAQ_TAG_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: CREATE_GLOBAL_FAQ_TAG_FAILED,
        })
    }
}

export function* faqGlobalTags(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.tag.getFaqTags(payload.id)
        })
        yield response && response.success
            ? put({
                  type: GET_GLOBAL_FAQ_TAGS_SUCCESS,
                  payload: {
                      items: response.data.globalTags,
                  },
              })
            : put({
                  type: GET_GLOBAL_FAQ_TAGS_FAILED,
              })
    } catch (error) {
        console.log(error)
        yield put({
            type: GET_GLOBAL_FAQ_TAGS_FAILED,
        })
    }
}

export function* removeFaqGlobalTag(action) {
    const payload = action.payload

    try {
        const response = yield call(() => {
            return API.tag.removeFaqTag(payload.faqId, payload.globalTagsIds)
        })
        if (response && response.success) {
            if (payload.afterSuccess) payload.afterSuccess(response.data.currentGlobalTags)

            yield put({
                type: REMOVE_GLOBAL_FAQ_TAG_SUCCESS,
            })
        } else {
            yield put({
                type: REMOVE_GLOBAL_FAQ_TAG_FAILED,
            })
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: REMOVE_GLOBAL_FAQ_TAG_FAILED,
        })
    }
}
