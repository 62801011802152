import { Checkbox, Empty, Form } from 'antd';
import React from 'react';
import Loader from 'src/app/components/Loader';
import { UsersListField } from './UsersListField';
const SELECT_ALL_TEXT = 'Select all';
const UNSELECT_ALL_TEXT = 'Unselect all';
export const UsersCheckboxList = ({ name, users, form, isLoading = false, required = false, rules, }) => {
    const handleSelectionChange = () => {
        const currentValue = form.getFieldValue(name) || [];
        const valSet = new Set(currentValue);
        const allSelected = users.every((item) => valSet.has(item.id));
        form.setFieldsValue({
            [name]: allSelected ? [] : users.map((item) => item.id),
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Form.Item, { shouldUpdate: true, noStyle: true }, ({ getFieldValue }) => {
            const valSet = new Set(getFieldValue(name));
            const allSelected = users.every((item) => valSet.has(item.id));
            const buttonText = allSelected ? UNSELECT_ALL_TEXT : SELECT_ALL_TEXT;
            return (React.createElement("div", { className: "mb-2 flex px-4 text-reading-lg" },
                React.createElement(Checkbox, { className: "!text-reading-lg", checked: allSelected && users.length > 0, onChange: handleSelectionChange, disabled: users.length === 0 }, buttonText),
                React.createElement("p", { className: "ml-8" },
                    valSet.size,
                    " selected")));
        }),
        users.length === 0 ? (isLoading ? (React.createElement(Loader, null)) : (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE }))) : (React.createElement("div", { className: "relative" },
            React.createElement(Form.Item, { shouldUpdate: true, name: name, required: required, rules: rules },
                React.createElement(UsersListField, { users: users })),
            isLoading ? (React.createElement(Loader, { className: "absolute inset-0 !flex items-center justify-center bg-black/25 !p-0" })) : null))));
};
