import { GET_TIMEZONES_SUCCESS, RESET_TIMEZONES, GET_TIMEZONES } from '../../actions/settings/timezonesActions';
import { TIMEZONES } from '../../store/initialState';
const initialState = TIMEZONES;
export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;
    switch (type) {
        case GET_TIMEZONES: {
            return Object.assign(Object.assign({}, state), { isLoading: true });
        }
        case GET_TIMEZONES_SUCCESS: {
            return Object.assign(Object.assign({}, state), { items: payload.items, isLoading: false });
        }
        case RESET_TIMEZONES: {
            return Object.assign(Object.assign({}, state), { items: [] });
        }
        default: {
            return Object.assign({}, state);
        }
    }
}
