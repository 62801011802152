import { Steps } from 'antd';
import React, { useEffect, useMemo, useRef } from 'react';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';
import { StepThree } from './StepThree';
import { StepFour } from './StepFour';
import { useStepsData } from '../StepsProvider';
export const IdpActivationSteps = () => {
    const layoutRef = useRef(null);
    const { currentStep, isCompleted } = useStepsData();
    const stepsContent = useMemo(() => {
        switch (currentStep) {
            case 1:
                return React.createElement(StepTwo, null);
            case 2:
                return React.createElement(StepThree, null);
            case 3:
                return React.createElement(StepFour, null);
            default:
                return React.createElement(StepOne, null);
        }
    }, [currentStep]);
    useEffect(() => {
        if (layoutRef.current && currentStep !== 0 && currentStep !== 3) {
            layoutRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }, [currentStep]);
    return (React.createElement("div", { ref: layoutRef },
        React.createElement(Steps, { className: isCompleted ? 'completed' : undefined, current: currentStep, items: Array.from({ length: 3 }, (_, index) => ({
                title: null,
                icon: React.createElement("div", { className: "step-counter" }, index + 1),
            })) }),
        React.createElement("div", { className: "flex flex-col items-center text-xl" }, stepsContent)));
};
