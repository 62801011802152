var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { App } from 'antd';
import React, { useEffect } from 'react';
import { getSuccessMessageConfig } from 'src/app/components/ant-app/message';
import { ToolbarButton } from 'src/app/components/common/BulkToolbar/ToolbarButton';
import { useBulkDeleteMutation } from 'src/app/queries/posts/bulk';
import { useGalleryBulkStore } from '../bulk-state';
export const BulkDeleteButton = () => {
    const selectedIds = useGalleryBulkStore((state) => state.selectedIds);
    const resetBulk = useGalleryBulkStore((state) => state.reset);
    const setLoading = useGalleryBulkStore((state) => state.setLoading);
    const { message, modal } = App.useApp();
    const { mutateAsync, isPending, reset, isSuccess } = useBulkDeleteMutation();
    const disabled = selectedIds.size === 0;
    const handleDelete = () => {
        console.log('Delete');
        modal.confirm({
            title: 'Delete selected media?',
            icon: null,
            content: 'This action cannot be undone and the media will be permanently removed from dashboard library and all places it was published to.',
            okText: 'Yes, delete',
            cancelText: 'No, cancel',
            onOk: () => __awaiter(void 0, void 0, void 0, function* () {
                setLoading();
                return mutateAsync(selectedIds);
            }),
        });
    };
    useEffect(() => {
        if (isSuccess) {
            reset();
            resetBulk();
            message.info(getSuccessMessageConfig('Media was deleted'));
        }
    }, [isSuccess, message, reset, resetBulk]);
    return (React.createElement(ToolbarButton, { icon: "trash", title: "Delete", "aria-label": "Delete", disabled: disabled || isPending, onClick: handleDelete }));
};
