import { Checkbox } from 'antd';
import React from 'react';
import { ProtectedButton } from 'src/app/components/protected-button';
import { LicensesEnum } from 'src/types/general';
export const PostItemView = ({ post, index, onClick, onShareClick, bulkEnabled = true, selected = false, onToggleSelected, allowSharing = true, }) => {
    if ((post.type === 'image' && !post.images[0]) || (post.type === 'video' && !post.videos[0])) {
        return null;
    }
    const handleClick = () => {
        onToggleSelected === null || onToggleSelected === void 0 ? void 0 : onToggleSelected(post.id, !selected);
    };
    return (React.createElement("div", { className: "gallery-post group relative aspect-square cursor-pointer overflow-hidden", key: post.id, "data-index": index, onClick: handleClick },
        post.type === 'video' ? (React.createElement("video", { className: "h-full w-full object-cover", controlsList: "nodownload", src: (post.videos[0].qualities && post.videos[0].qualities['370x370']) || post.videos[0].original })) : (React.createElement("img", { className: "h-full w-full object-cover", src: post.images[0].sizes['370x370'] || post.images[0].original, alt: "Content preview image" })),
        post.type === 'video' && (React.createElement("div", { className: "absolute bottom-4 right-4 h-0 w-0 border-b-[2.4rem] border-l-[3.2rem] border-t-[2.4rem] border-solid border-b-transparent border-l-white border-t-transparent" })),
        bulkEnabled ? (React.createElement(Checkbox, { className: "absolute right-3 top-3", checked: selected, onChange: (event) => {
                event.stopPropagation();
                onToggleSelected === null || onToggleSelected === void 0 ? void 0 : onToggleSelected(post.id, event.target.checked);
            } })) : (React.createElement("div", { className: "absolute inset-0 grid grid-rows-1 bg-black/70 p-4 opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100", onClick: () => {
                onClick(post);
            }, role: "button" },
            post.post_publisher != null ? (React.createElement("div", null,
                React.createElement("h3", { className: "content-preview-user-name" }, post.post_publisher && post.post_publisher.name
                    ? post.post_publisher.name
                    : 'Deleted user'),
                React.createElement("p", { className: "content-preview-user-text" }, post.post_publisher.user_tags
                    ? Object.entries(post.post_publisher.user_tags)
                        .map((tags) => {
                        tags[1].map((tag) => tag.name);
                    })
                        .join(', ')
                    : ''))) : (React.createElement("h3", { className: "content-preview-user-name" }, "Deleted user")),
            React.createElement("p", { className: "sr-only font-interface text-xs text-white" }, post.caption),
            allowSharing && (React.createElement("div", null,
                React.createElement(ProtectedButton, { licenseName: LicensesEnum.Sharing, type: "secondary", "data-automation-id": "share-rss", className: post.isExample ? 'hidden' : 'content-preview-share', "data-post-index": index, onClick: (event) => {
                        event.stopPropagation();
                        onShareClick(post);
                    } },
                    post.isShared ? 'Share again' : 'Share',
                    ' ')))))));
};
