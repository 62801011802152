import { Select } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roles } from '../../../../../helpers/helpers';
import { AMBASSADORS_INFO } from '../../../../../redux/actions/content/messagingActions';
import { getUsersMessagingSlice } from '../../../../../redux/selectors';
const { Option } = Select;
const AmbassadorSelect = ({ onChangeAmbassadorSelect }) => {
    const dispatch = useDispatch();
    const { ambassadors } = useSelector(getUsersMessagingSlice);
    const options = useMemo(() => ambassadors.map((ambassador) => {
        return (React.createElement(Option, { key: ambassador.id, value: ambassador.id, label: ambassador.name }, `${ambassador.name} ${ambassador.last_name}`));
    }), [ambassadors]);
    const onSearch = useCallback((value) => {
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                    keyword: value,
                },
                roles: [roles.student],
            },
        });
    }, [dispatch]);
    useEffect(() => {
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    permission: 'chat',
                },
                roles: [roles.student],
            },
        });
    }, [dispatch]);
    return (React.createElement(Select, { showSearch: true, size: "large", style: { width: 'calc(80% + 2rem + 2px)' }, onChange: onChangeAmbassadorSelect, onSearch: onSearch, filterOption: false, className: "mt-4", placeholder: "Select ambassador" }, options));
};
export default AmbassadorSelect;
