var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable camelcase */
import { Select, Spin } from 'antd';
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import API from 'src/app/api';
import { useDebouncedCallback } from 'use-debounce';
const initialState = {
    items: [],
    offset: 0,
    limit: 50,
    loading: false,
};
const reducer = (state, action) => {
    switch (action.type) {
        case 'setLoading':
            return Object.assign(Object.assign({}, state), { loading: true });
        case 'setFulfilled':
            return Object.assign(Object.assign({}, state), { loading: false, items: action.payload.offset ? [...state.items, ...action.payload.items] : action.payload.items, offset: action.payload.offset || 0 });
        default:
            return state;
    }
};
const LIST_HEIGHT = 200;
function optionsFetcher(dispatch) {
    return ({ searchName, limit = 50, offset = 0 }) => {
        dispatch({ type: 'setLoading' });
        API.dashboardGroup
            .getDestinationOptions({ limit, offset, search: searchName })
            .then((response) => {
            dispatch({ type: 'setFulfilled', payload: { items: response.data, limit, offset, search: searchName } });
        })
            .catch((error) => console.log(error));
    };
}
const defaultOptionMapper = ({ name, country_name: countryName, id }) => {
    return {
        label: `${countryName} - ${name}`,
        value: `Destination : ${countryName} - ${name}_${id}`,
    };
};
export const DestinationSelect = (_a) => {
    var { value, onChange, optionMapper = defaultOptionMapper, listHeight = LIST_HEIGHT, maxCount } = _a, props = __rest(_a, ["value", "onChange", "optionMapper", "listHeight", "maxCount"]);
    const [state, dispatch] = useReducer(reducer, initialState);
    const { items, offset, limit, loading } = state;
    const fetchOptions = useRef(optionsFetcher(dispatch));
    const search = useRef('');
    const [options, setOptions] = useState();
    const [localValue, setLocalValue] = useState(value || []);
    useEffect(() => {
        if (items.length > 0) {
            const parsedItems = items.map((item) => optionMapper(item));
            setOptions(parsedItems);
        }
    }, [items, optionMapper]);
    const debounced = useDebouncedCallback((inputValue) => {
        const searchName = inputValue.trim();
        fetchOptions.current({
            searchName: searchName,
        });
    }, 300);
    const handleSearch = (inputValue) => {
        search.current = inputValue.trim();
        if (!inputValue.trim()) {
            debounced('');
        }
        else if (inputValue.trim().length >= 3) {
            debounced(inputValue);
        }
    };
    const onDropdownVisibleChange = (open) => {
        if (open && !options) {
            fetchOptions.current({
                searchName: search.current,
            });
        }
        if (!open) {
            search.current = '';
            debounced('');
        }
    };
    const handlePopupScroll = useCallback((event) => {
        const target = event.currentTarget;
        if (target.scrollTop + listHeight === target.scrollHeight) {
            fetchOptions.current({
                searchName: search.current,
                limit: limit,
                offset: offset + limit,
            });
        }
    }, [limit, offset, listHeight]);
    const handleChange = (value) => {
        setLocalValue(value);
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    };
    const onSelect = () => {
        if (search.current) {
            search.current = '';
            fetchOptions.current({
                searchName: '',
                offset: 0,
            });
        }
    };
    return (React.createElement(Select, Object.assign({ mode: "multiple", filterOption: false, maxCount: maxCount, notFoundContent: loading ? (React.createElement("div", { className: "p-6 text-center" },
            React.createElement(Spin, { size: "small" }))) : null, onChange: handleChange, value: localValue, listHeight: listHeight, onPopupScroll: handlePopupScroll, onSearch: handleSearch, onDropdownVisibleChange: onDropdownVisibleChange }, props, { options: options, onSelect: onSelect })));
};
