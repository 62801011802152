import { SearchOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { AMBASSADORS_INFO } from '../../../../redux/actions/content/messagingActions';
export const UsersSearchField = ({ roles, permission }) => {
    const dispatch = useDispatch();
    const debounced = useDebouncedCallback(({ target: { value } }) => {
        const searchString = value.trim();
        dispatch({
            type: AMBASSADORS_INFO,
            payload: {
                search: {
                    keyword: searchString,
                    permission,
                },
                roles,
            },
        });
    }, 300);
    const handleSearchChange = (event) => {
        event.persist();
        return debounced(event);
    };
    return (React.createElement(Form.Item, null,
        React.createElement(Input, { className: "text-reading-lg", prefix: React.createElement(SearchOutlined, null), placeholder: "Search for an Ambassador", onChange: handleSearchChange })));
};
