export const formatColorCodeToRGB = (color) => {
    return `rgb(${color})`;
};
export function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
}
export function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}
export function lighten(color, percent) {
    const { r, g, b } = hexToRgb(color);
    const newR = Math.min(255, Math.floor(r + (255 - r) * percent));
    const newG = Math.min(255, Math.floor(g + (255 - g) * percent));
    const newB = Math.min(255, Math.floor(b + (255 - b) * percent));
    return rgbToHex(newR, newG, newB);
}
export function darken(color, percent) {
    const { r, g, b } = hexToRgb(color);
    const newR = Math.max(0, Math.floor(r * (1 - percent)));
    const newG = Math.max(0, Math.floor(g * (1 - percent)));
    const newB = Math.max(0, Math.floor(b * (1 - percent)));
    return rgbToHex(newR, newG, newB);
}
export function getContrastRatio(color1, color2) {
    const luminance = (color) => {
        const { r, g, b } = hexToRgb(color);
        const a = [r, g, b].map((v) => {
            v /= 255;
            return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
        });
        return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    };
    const lum1 = luminance(color1);
    const lum2 = luminance(color2);
    return (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
}
export function getContrastColor(baseColor) {
    const initialColor = '#efefef';
    const initialBg = '#4f4c4c';
    try {
        if (baseColor === initialBg || !baseColor) {
            return initialColor;
        }
        const shades = [];
        for (let i = 0; i <= 1; i += 0.1) {
            shades.push(lighten(baseColor, i), darken(baseColor, i));
        }
        const accessibleShades = shades.filter((shade) => getContrastRatio(baseColor, shade) >= 4.5);
        if (accessibleShades.length > 0) {
            return accessibleShades[0];
        }
        return initialColor; // Fallback to initial if no accessible shade is found
    }
    catch (error) {
        console.error('Error in getContrastColor:', error);
        return initialColor;
    }
}
