import React, { useMemo } from 'react'

const excludedKeysForBuzz = new Set([
    'flagged_conversation',
    'new_answer_submitted',
    'new_content_created',
    'prospect_messaging_activity',
    'admin_monthly_automated_report',
])

const Toggle = ({ id, name, keyName, checked, disableToggle, blockName, handleToggleNotification }) => (
    <div className="notification-control-form-row">
        <div className="max-w-[80%]">
            <h4 className={blockName ? 'toggle-block-name' : 'notification-name'}>{name}</h4>
            {keyName === 'prospect_messaging_activity' && (
                <p>
                    If set, you will be notified in case Prospect reaches the number of Ambassadors addressed within
                    time. Please, see details in &apos;Safeguarding&apos; part of &apos;Settings&apos;.
                </p>
            )}
        </div>
        <label className="slideOne js-holder">
            <input
                type="checkbox"
                name={keyName}
                checked={checked}
                disabled={disableToggle}
                onChange={(e) => handleToggleNotification(e, id)}
            />
            <span className="slideOne-icon"></span>
        </label>
    </div>
)

const NotificationToggles = ({
    account,
    notifications,
    disableToggle,
    handleToggleNotification,
    handleReceivingOfNotifications,
    receiveNotifications,
}) => {
    const { reportToggle, ambassadorToggleComponents, prospectToggleComponents } = useMemo(() => {
        // eslint-disable-next-line unicorn/no-array-reduce
        return notifications.reduce(
            (acc, toggle) => {
                if (account?.account_info.isBuzzOnly && excludedKeysForBuzz.has(toggle.key)) return acc

                const toggleComponent = (
                    <Toggle
                        id={toggle.id}
                        name={toggle.name}
                        keyName={toggle.key}
                        checked={toggle.is_active}
                        disableToggle={disableToggle}
                        handleToggleNotification={handleToggleNotification}
                    />
                )

                switch (toggle.type) {
                    case 'report_toggle':
                        if (account.account_info && !account.account_info.isGroupAdmin) {
                            acc.reportToggle = toggleComponent
                        }
                        break
                    case 'ambassador_toggle':
                        acc.ambassadorToggleComponents.push(toggleComponent)
                        break
                    case 'prospect_toggle':
                        acc.prospectToggleComponents.push(toggleComponent)
                        break
                    default:
                        break
                }
                return acc
            },
            { reportToggle: null, ambassadorToggleComponents: [], prospectToggleComponents: [] }
        )
    }, [notifications, account?.account_info, disableToggle, handleToggleNotification])

    return (
        <>
            <div className="notifications-toggle-block">
                <Toggle
                    name="Receive notifications"
                    keyName="receive_notifications"
                    checked={receiveNotifications}
                    disableToggle={disableToggle}
                    blockName
                    handleToggleNotification={handleReceivingOfNotifications}
                />
            </div>

            {receiveNotifications && notifications.length > 0 && (
                <>
                    {reportToggle && <div className="notifications-toggle-block">{reportToggle}</div>}
                    <div className="notifications-toggle-block">
                        <h4 className="toggle-block-name header">Notifications related to Ambassadors</h4>
                        {ambassadorToggleComponents}
                    </div>
                    <div className="notifications-toggle-block">
                        <h4 className="toggle-block-name header">Notifications related to Prospects</h4>
                        {prospectToggleComponents}
                    </div>
                </>
            )}
        </>
    )
}

export default NotificationToggles
