import React from 'react';
export const IdpInviteTemplate = ({ usniversityName, trainingCenterLink }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "Hello ",
            React.createElement("span", { className: "bg-color-selection/40" }, "[insert first name],")),
        React.createElement("p", null,
            "We want to give prospects an authentic insight into the experience of being part of\u00A0",
            React.createElement("span", { className: "bg-color-selection/40" }, usniversityName),
            " and we need your help!"),
        React.createElement("p", null, "We're inviting you to create a profile on The Ambassador Platform (TAP)."),
        React.createElement("p", null,
            "TAP is a part of a larger company called IDP Education. IDP Education has over 50 years of experience helping students achieve their study abroad dreams. They have a student-first approach and work with a global network of expert education counsellors spanning 35 countries. You can read more about IDP and their Peer-to-Peer (P2P) services\u00A0",
            React.createElement("a", { href: "https://knowledge.theambassadorplatform.com/ambassadors-who-are-idp-and-what-are-their-joint-services", rel: "noopener noreferrer", target: "_blank", className: "orange-link" }, "here.")),
        React.createElement("p", null, "In addition to your profiles being on our institutional website, we will also be providing prospective students with advice through IDP's Student Chat service or their Student Community service. Student Chat, like the name suggests, is a chat service that will enable students to chat with you, whilst Student Community will display some of your fantastic content to help them picture what life at your institution is really like."),
        React.createElement("p", null, "In order to get set up, please do the following:"),
        React.createElement("p", null,
            React.createElement("ul", { className: "invite-list" },
                React.createElement("li", null,
                    "Visit the\u00A0",
                    React.createElement("a", { href: trainingCenterLink, rel: "noopener noreferrer", target: "_blank" }, "Ambassador Training Center"),
                    "\u00A0 to learn about how to carry out your work on The Ambassador Platform"),
                React.createElement("li", null,
                    "As part of this work, there is a little additional training we need you to go through, which should take up to 30 minutes of your time. Firstly, you will need to watch the following short training videos:",
                    React.createElement("p", null,
                        React.createElement("ol", { className: "ml-10", type: "i" },
                            React.createElement("li", null,
                                React.createElement("a", { href: "https://vimeo.com/929876983", rel: "noopener noreferrer", target: "_blank" }, "IDP Live App")),
                            React.createElement("li", null,
                                React.createElement("a", { href: "https://vimeo.com/929882330", rel: "noopener noreferrer", target: "_blank" }, "WhatUni")),
                            React.createElement("li", null,
                                React.createElement("a", { href: "https://vimeo.com/929882695", rel: "noopener noreferrer", target: "_blank" }, "HCI"))))),
                React.createElement("li", null,
                    "Make sure you have read through the\u00A0",
                    React.createElement("a", { href: "https://knowledge.theambassadorplatform.com/idp-content-guidelines-for-ambassadors", rel: "noopener noreferrer", target: "_blank" }, "content guidelines"),
                    "\u00A0IDP have created for you"),
                React.createElement("li", null,
                    "Download 'The Ambassador Platform' app from the\u00A0",
                    React.createElement("a", { href: "https://tap.st/ios", rel: "noopener noreferrer", target: "_blank" }, "App"),
                    "/",
                    React.createElement("a", { href: "https://tap.st/android", rel: "noopener noreferrer", target: "_blank" }, "Play"),
                    "\u00A0 Store"),
                React.createElement("li", null, "Create an account in the app using the code you received when you completed training"),
                React.createElement("li", null, "Complete your profile to the best of your ability"))),
        React.createElement("p", null, "We expect this training to take up to an hour of your time."),
        React.createElement("p", null, "I hope that all makes sense and sounds like an exciting opportunity to gain some valuable skills. Let me know if you have any questions and thanks again for agreeing to be part of this programme!"),
        React.createElement("p", null, "Best wishes,")));
};
