import { OPEN_CONTENT_GROUP_POPUP, CLOSE_CONTENT_GROUP_POPUP } from '../../../actions/content/messagingActions';
import { ContentGroupTypeEnum } from 'src/types/general';
const initialState = {
    isOpened: false,
    groupType: ContentGroupTypeEnum.Content,
};
export default function contentGroupModalReducer(state = initialState, action) {
    const type = action.type;
    const payload = action.payload;
    switch (type) {
        case OPEN_CONTENT_GROUP_POPUP: {
            const newState = Object.assign(Object.assign({}, state), { isOpened: true, groupType: payload || initialState.groupType });
            return newState;
        }
        case CLOSE_CONTENT_GROUP_POPUP: {
            const newState = Object.assign(Object.assign({}, state), { isOpened: false });
            return newState;
        }
        default: {
            return state;
        }
    }
}
