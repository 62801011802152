var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@tanstack/react-query';
import API from 'src/app/api';
import { useAppDispatch } from 'src/app/hooks/store';
import { bulkDeleteSuccess, bulkPublishSuccess } from 'src/app/redux/actions/content/postsActions';
export function useBulkPublishMutation() {
    const dispatch = useAppDispatch();
    return useMutation({
        mutationFn: (data) => __awaiter(this, void 0, void 0, function* () {
            return API.posts.bulk.publish({
                // eslint-disable-next-line unicorn/prefer-spread
                postIds: Array.from(data.postIds),
                publish: data.publish,
            });
        }),
        onSuccess: (_, variables) => {
            dispatch(bulkPublishSuccess(variables.postIds));
        },
    });
}
export function useBulkDeleteMutation() {
    const dispatch = useAppDispatch();
    return useMutation({
        mutationFn: (postIds) => __awaiter(this, void 0, void 0, function* () {
            return API.posts.bulk.delete({
                // eslint-disable-next-line unicorn/prefer-spread
                postIds: Array.from(postIds),
            });
        }),
        onSuccess: (_, variables) => {
            dispatch(bulkDeleteSuccess(variables));
        },
    });
}
