/* eslint-disable unicorn/prefer-spread */
import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { reducer as notifications } from 'react-notification-system-redux';
import { reducer as reduxFormReducer } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import { TAPFiltersReducer, accountReducer, actionPopupReducer, activityReducer, adminsReducer, ambassadorsLoadingReducer, ambassadorsReducer, campaignReducer, changeEmailModalReducer, changePasswordModalReducer, changeUniversityNameSuccessReducer, chatMessagesReducer, chatsReducer, companiesControlReducer, confirmBanModalReducer, confirmDeleteModalReducer, contentGroupsReducer, contentReportReducer, copyLinkPopupReducer, countriesReducer, createChatGroupModalReducer, createContentGroupModalReducer, createQuestionPopupReducer, crmReducer, cspDomainsReducer, currentChatReducer, currentTagsReducer, customTitlesReducer, dialogMessagesReducer, eventStreamReducer, faqReportReducer, faqTagsReducer, faqsAnswersReducer, faqsReducer, homeChartReducer, homeDataReducer, liveEventsReducer, liveStreamsReducer, newMessagesReducer, notificationModalReducer, notificationsControlReducer, notificationsReducer, optionalQuestions, overviewReducer, plsSignUpReducer, postSharedContentPreviewModalReducer, postTagsReducer, postsReducer, postsSharedReducer, prospectsReducer, replyMessageReducer, safeguardingReducer, scheduleReducer, settingsMessagingModalReducer, sharePostModalReducer, ssoProvidersReducer, successLetterSendModalReducer, successRecoveryReducer, successRegistrationModalReducer, tagsReducer, timezonesReducer, topicsReducer, trendAnalyzerReducer, tutorialModalReducer, twoTierMenuReducer, universityWidgetsReducer, userInfoPopupReducer, userTagsReducer, usersMessagingReducer, usersReducer, widgetReducer, } from '../reducers';
import saga from '../sagas';
import communityReducer from '../slices/community';
import { idpConversionReportApi } from '../slices/reporting/idp-conversion-reporting/query';
const sagaMiddleware = createSagaMiddleware();
const listenerMiddleware = createListenerMiddleware();
const store = configureStore({
    reducer: {
        account: accountReducer,
        tapFilters: TAPFiltersReducer,
        tapTitles: customTitlesReducer,
        cspDomains: cspDomainsReducer,
        safeguarding: safeguardingReducer,
        ssoProviders: ssoProvidersReducer,
        timezones: timezonesReducer,
        crm: crmReducer,
        overview: overviewReducer,
        activity: activityReducer,
        ambassadors: ambassadorsReducer,
        ambassadorsLoading: ambassadorsLoadingReducer,
        prospects: prospectsReducer,
        trendAnalyzer: trendAnalyzerReducer,
        form: reduxFormReducer, // mounted under "form"
        notifications: notifications,
        posts: postsReducer,
        users: usersReducer,
        postsShared: postsSharedReducer,
        homeChart: homeChartReducer,
        homeData: homeDataReducer,
        changeEmailModal: changeEmailModalReducer,
        changePasswordModal: changePasswordModalReducer,
        tutorialModal: tutorialModalReducer,
        sharePostModal: sharePostModalReducer,
        confirmBanModal: confirmBanModalReducer,
        successRegistrationModal: successRegistrationModalReducer,
        confirmDeleteModal: confirmDeleteModalReducer,
        successLetterSendModal: successLetterSendModalReducer,
        successRecovery: successRecoveryReducer,
        changeUniversityNameSuccess: changeUniversityNameSuccessReducer,
        postSharedContentPreviewModal: postSharedContentPreviewModalReducer,
        universityWidgets: universityWidgetsReducer,
        usersMessaging: usersMessagingReducer,
        createContentGroupModal: createContentGroupModalReducer,
        contentGroups: contentGroupsReducer,
        twoTierMenu: twoTierMenuReducer,
        actionPopup: actionPopupReducer,
        userInfoPopup: userInfoPopupReducer,
        newMessages: newMessagesReducer,
        dialogMessages: dialogMessagesReducer,
        createChatGroupModal: createChatGroupModalReducer,
        copyLinkChatGroupModal: copyLinkPopupReducer,
        chats: chatsReducer,
        currentChat: currentChatReducer,
        chatMessages: chatMessagesReducer,
        settingsMessagingModal: settingsMessagingModalReducer,
        liveEvents: liveEventsReducer,
        liveStreams: liveStreamsReducer,
        eventStream: eventStreamReducer,
        plsSignUp: plsSignUpReducer,
        topics: topicsReducer,
        createQuestionPopup: createQuestionPopupReducer,
        faqs: faqsReducer,
        faqsAnswers: faqsAnswersReducer,
        widgetSettings: widgetReducer,
        optionalQuestions: optionalQuestions,
        customNotifications: notificationsReducer,
        admins: adminsReducer,
        globalTags: tagsReducer,
        currentGlobalTags: currentTagsReducer,
        userGlobalTags: userTagsReducer,
        postGlobalTags: postTagsReducer,
        faqGlobalTags: faqTagsReducer,
        faqReport: faqReportReducer,
        contentReport: contentReportReducer,
        schedule: scheduleReducer,
        notificationsControl: notificationsControlReducer,
        companiesControl: companiesControlReducer,
        replyMessage: replyMessageReducer,
        notificationModal: notificationModalReducer,
        countries: countriesReducer,
        campaign: campaignReducer,
        community: communityReducer,
        [idpConversionReportApi.reducerPath]: idpConversionReportApi.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        const defaultMiddleware = getDefaultMiddleware({
            serializableCheck: false,
        });
        return defaultMiddleware
            .concat(listenerMiddleware.middleware)
            .concat(sagaMiddleware)
            .concat(idpConversionReportApi.middleware);
    },
});
sagaMiddleware.run(saga);
export default store;
export const startAppListening = listenerMiddleware.startListening.withTypes();
