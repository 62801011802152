var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DownloadOutlined } from '@ant-design/icons';
import Button from 'antd/es/button';
import React, { useEffect, useRef } from 'react';
import Api from '../../../../../api';
import { downloadFile } from '../../../../../helpers/file';
import { subscribeToDashboard } from '../../../../../system/services/PusherWrapper/subscriber';
import { REFERENCES_GENERATED } from '../../../../../system/services/PusherWrapper/types';
export const DownloadCareerReferenceButton = ({ userId }) => {
    const [loading, setLoading] = React.useState(false);
    const channel = useRef(null);
    useEffect(() => {
        channel.current = subscribeToDashboard(`${REFERENCES_GENERATED}${userId}`, (data) => {
            if (data === null || data === void 0 ? void 0 : data.url) {
                downloadFile(data.url.path);
            }
            else {
                // TODO: show error
            }
            setLoading(false);
        });
        return () => {
            var _a;
            (_a = channel.current) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        };
    }, [userId]);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        Api.user.downloadCareerReference(userId);
    });
    return (React.createElement(Button, { type: "link", icon: React.createElement(DownloadOutlined, null), onClick: handleClick, loading: loading, "data-automation-id": "download-reference-button" }, "Download career reference"));
};
