import store, { startAppListening } from '../redux/store';
export class Permissions {
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        var _a;
        this.permissions = new Map();
        if (!store) {
            throw new Error('Account store is not initialized');
        }
        const { account } = store.getState();
        this.setPermissions((_a = account.account_info) === null || _a === void 0 ? void 0 : _a.university.permissions);
        startAppListening({
            predicate: (_, currentState, originalState) => {
                var _a, _b;
                return (((_a = currentState.account.account_info) === null || _a === void 0 ? void 0 : _a.university.id) !=
                    ((_b = originalState.account.account_info) === null || _b === void 0 ? void 0 : _b.university.id));
            },
            effect: (_, api) => {
                var _a;
                const { account } = api.getState();
                this.setPermissions((_a = account.account_info) === null || _a === void 0 ? void 0 : _a.university.permissions);
            },
        });
    }
    setPermissions(permissions) {
        this.permissions.clear();
        if (!permissions) {
            return;
        }
        permissions.forEach((permission) => {
            this.permissions.set(permission.key, permission.is_enabled);
        });
    }
    static get instance() {
        if (!this._instance) {
            this._instance = new this();
        }
        return this._instance;
    }
    static can(permission, isDefaultAllowed = true) {
        if (!this.instance.permissions) {
            return false;
        }
        const foundPermission = this.instance.permissions.get(permission);
        return foundPermission != null ? foundPermission : isDefaultAllowed;
    }
}
