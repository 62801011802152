import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { LIVE_STREAM_STATUS } from '../../../../../../../common/constants';
import { openStreamFormModal, openUsersModal, openVideoReUploadModal, shareLiveStreamRecord, } from '../../../../../../../redux/actions/live-streams/live-streams-actions';
import { openNotificationModal } from '../../../../../../../redux/actions/notification-modal/notification-modal-actions';
import { LIVE_STREAMS } from '../../../../../../../redux/actions/live-streams/live-streams-action-types';
import { OPEN_ACTION_MODAL } from '../../../../../../../redux/actions/components/componentsActions';
import { GET_CHAT_TRANSCRIPT_DATA } from '../../../../../../../redux/actions/chat/messagingActions';
const EventMenuContent = ({ activeEvent, setIsOpen }) => {
    const dispatch = useDispatch();
    const { liveStreamLink, liveStreamIframelessLink, state, status, video, totalProspects } = activeEvent;
    const vidoFile = video.find((item) => item);
    const disablePublishContent = totalProspects === 0 || (vidoFile === null || vidoFile === void 0 ? void 0 : vidoFile.isPublished);
    const handleEditStreamClick = () => {
        setIsOpen(false);
        dispatch(openStreamFormModal(activeEvent));
    };
    const handleManageParticipantsClick = () => {
        setIsOpen(false);
        dispatch(openUsersModal(activeEvent));
    };
    const handleArchiveClick = () => {
        const { id: liveStreamId, name } = activeEvent;
        setIsOpen(false);
        dispatch(openNotificationModal(LIVE_STREAMS.ARCHIVE_STREAM, {
            liveStreamId,
            state: state === 'public' ? 'archived' : 'public',
            name,
        }));
    };
    const handleCopyToClipboard = (value) => {
        const tempInput = document.createElement('input');
        tempInput.style.cssText = 'position: absolute; left: -1000px; top: -1000px';
        tempInput.value = value;
        document.body.append(tempInput);
        tempInput.select();
        document.execCommand('copy');
        tempInput.remove();
        dispatch({
            type: OPEN_ACTION_MODAL,
            payload: {
                title: 'Event link copied to clipboard',
            },
        });
    };
    const handleCopyLink = () => handleCopyToClipboard(liveStreamLink);
    const handleCopyIframelessLink = () => handleCopyToClipboard(liveStreamIframelessLink);
    const handleDownloadVideo = () => {
        setIsOpen(false);
        const link = document.createElement('a');
        link.href = (vidoFile === null || vidoFile === void 0 ? void 0 : vidoFile.original) || '';
        document.body.append(link);
        link.click();
        link.remove();
    };
    const handleReUploadVideo = () => {
        const { id: liveStreamId } = activeEvent;
        setIsOpen(false);
        dispatch(openVideoReUploadModal({
            liveStreamId,
        }));
    };
    const handleShareVideo = () => {
        const { id: liveStreamId } = activeEvent;
        setIsOpen(false);
        dispatch(shareLiveStreamRecord({
            liveStreamId,
        }));
    };
    const handleDownloadTranscripts = useCallback(() => {
        const onSuccess = (response) => {
            setTimeout(() => {
                const link = document.createElement('a');
                link.download = response.csvURL;
                link.href = response.csvURL;
                document.body.append(link);
                link.click();
                link.remove();
            }, 1000);
        };
        dispatch({
            type: GET_CHAT_TRANSCRIPT_DATA,
            payload: {
                dialogId: activeEvent.dialogId,
            },
            onSuccess: onSuccess,
        });
    }, [dispatch, activeEvent]);
    return (React.createElement("div", { className: "options-drop-block" },
        React.createElement("button", { className: "options-drop-item", onClick: handleEditStreamClick, "data-automation-id": "edit-stream" }, "Edit Stream"),
        React.createElement("button", { className: "options-drop-item", onClick: handleManageParticipantsClick, "data-automation-id": "manage-ambassadors" }, "Manage Ambassadors"),
        React.createElement("button", { className: "options-drop-item", onClick: handleCopyLink, "data-automation-id": "copy-link" }, "Copy link"),
        status !== LIVE_STREAM_STATUS.ONDEMAND && (React.createElement("button", { className: "options-drop-item", onClick: handleCopyIframelessLink, "data-automation-id": "standalone-page-link" }, "Link to a standalone page")),
        React.createElement("button", { className: "options-drop-item", onClick: handleArchiveClick, "data-automation-id": state === 'public' ? 'archive' : 'publish' }, state === 'public' ? 'Archive' : 'Publish'),
        status === LIVE_STREAM_STATUS.COMPLETED && vidoFile && vidoFile.id && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "options-drop-item", onClick: handleDownloadVideo, "data-automation-id": "download-video" }, "Download video"),
            React.createElement("button", { className: "options-drop-item", onClick: handleReUploadVideo, "data-automation-id": "re-upload" }, "Re-upload video recording"),
            React.createElement("button", { className: "options-drop-item", onClick: handleShareVideo, "data-automation-id": "share", disabled: disablePublishContent }, "Share the video recording with registered prospects"))),
        React.createElement("button", { className: "options-drop-item", onClick: handleDownloadTranscripts, "data-automation-id": "download-transcripts" }, "Download chat transcripts")));
};
export default EventMenuContent;
