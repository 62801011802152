import store from '../redux/store';
export class UserCountry {
    // eslint-disable-next-line no-useless-constructor
    constructor(store) {
        this.store = store;
    }
    static get instance() {
        if (!this._instance) {
            const { account } = store.getState();
            if (!store) {
                throw new Error('Account store is not initialized');
            }
            this._instance = new this(account);
        }
        return this._instance;
    }
    static get() {
        var _a, _b, _c;
        const countryCode = (_c = (_b = (_a = this.instance.store.account_info) === null || _a === void 0 ? void 0 : _a.university) === null || _b === void 0 ? void 0 : _b.country) === null || _c === void 0 ? void 0 : _c.alpha_3_code;
        return countryCode || 'GBR';
    }
}
