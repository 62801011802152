import { CREATE_GLOBAL_TAG_FAILED, CREATE_GLOBAL_TAG_SUCCESS, GET_GLOBAL_LIST_TAGS_FAILED, GET_GLOBAL_LIST_TAGS_SUCCESS, } from '../../actions/tags/tagsActions';
const initialState = {
    items: [],
    total: 0,
    limit: 50,
    offset: 0,
    isLoading: true,
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CREATE_GLOBAL_TAG_SUCCESS: {
            return Object.assign(Object.assign({}, state), { isLoading: false });
        }
        case GET_GLOBAL_LIST_TAGS_SUCCESS: {
            return Object.assign(Object.assign({}, state), { items: payload.offset ? [...state.items, ...payload.items] : payload.items, offset: payload.offset ? payload.offset : 0, total: payload.total, isLoading: false });
        }
        case CREATE_GLOBAL_TAG_FAILED: {
            return Object.assign(Object.assign({}, state), { isLoading: false });
        }
        case GET_GLOBAL_LIST_TAGS_FAILED: {
            return Object.assign(Object.assign({}, state), { isLoading: false });
        }
        default: {
            return state;
        }
    }
}
