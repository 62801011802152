import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'src/app/hooks/store'
import { setSubmenu } from 'src/app/redux/actions/content/componentsActions'
import { IdpContentRequest } from './modals/IdpContentRequest'
import { PostsList } from './PostsList'
import { PostsListHeader } from './PostsList/PostsListHeader'
import { IdpPostsListHeader } from './PostsList/IdpPostListHeader'
import { CreateContentGroupModal } from './modals/CreateContentGroup'
import { PostsShareModal } from './modals/PostsShareModal'
import { PreviewPostModal } from './modals/PreviewPostModal'

const PostsCore = () => {
    const dispatch = useAppDispatch()
    const { account_info: accountInfo } = useAppSelector((state) => state.account)

    const isIdpGroupAdmin = accountInfo?.isIdpGroupAdmin

    useEffect(() => {
        dispatch(setSubmenu('content'))
    })

    return (
        <section id="content" className="content-layout relative h-full bg-light-gray">
            {isIdpGroupAdmin ? <IdpPostsListHeader /> : <PostsListHeader />}
            <PostsList />
            {isIdpGroupAdmin ? <IdpContentRequest /> : <CreateContentGroupModal />}
            <PostsShareModal />
            <PreviewPostModal />
        </section>
    )
}
export default PostsCore
