import { Avatar, Checkbox } from 'antd';
import React, { useEffect } from 'react';
import { clsx } from 'src/app/helpers/classNames';
const EMPTY_NAME_PLACEHOLDER = 'N/A';
let valuesSet = new Set();
export const UsersListField = (props) => {
    const { users, value, onChange, id } = props;
    useEffect(() => {
        if (value) {
            valuesSet = new Set(value);
        }
        return () => {
            valuesSet.clear();
        };
    }, [value]);
    function handleCheckboxChange(event) {
        if (event.target.checked) {
            valuesSet.add(event.target.value);
        }
        else {
            valuesSet.delete(event.target.value);
        }
        if (onChange) {
            onChange([...valuesSet]);
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "aria-describedby": props['aria-describedby'], "aria-invalid": props['aria-invalid'], "aria-required": props['aria-required'], className: "mb-0 max-h-[25rem] overflow-auto border border-solid border-border-gray shadow-[inset_0px_-10px_15px_-10px_rgba(0,0,0,0.1)] aria-[invalid]:border-red-500" },
            React.createElement(Checkbox.Group, { className: "checkbox-group-custom", value: value }, users.map((user) => {
                var _a, _b;
                const userName = user.name || EMPTY_NAME_PLACEHOLDER;
                return (React.createElement("div", { key: user.id, className: clsx('px-4 py-3', (value === null || value === void 0 ? void 0 : value.includes(user.id)) && 'bg-highlighted-gray') },
                    React.createElement(Checkbox, { value: user.id, onChange: handleCheckboxChange },
                        React.createElement("div", { className: "flex items-center" },
                            React.createElement("div", { className: "!px-4" },
                                React.createElement(Avatar, { className: "!m-0", size: 64, src: ((_a = user.avatar) === null || _a === void 0 ? void 0 : _a.sizes['70x70']) || ((_b = user.avatar) === null || _b === void 0 ? void 0 : _b.original) }, user.name ? user.name[0] : EMPTY_NAME_PLACEHOLDER)),
                            React.createElement("div", null,
                                React.createElement("div", { className: "pb-1 font-interface-bold text-submenu-base" }, userName),
                                React.createElement("p", { className: "text-reading-base capitalize text-secondary" }, user.role))))));
            }))),
        React.createElement("div", { id: id })));
};
