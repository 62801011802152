var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import API from '../../../api';
import { createSelector } from '@reduxjs/toolkit';
import { createAsyncSlice } from '../sliceCreator';
import CommunityDialogModel from '../../../models/community-dialog-model';
import { RESET_CHAT_MESSAGES_COUNTER } from '../../actions/chat/messagingActions';
import { chatMessageInfo } from '../../actions/chat/messagingActionCreators';
const initialState = {
    communityChats: [],
    chat: undefined,
    loading: false,
    listLoading: false,
    dialogLoading: false,
    error: '',
    offset: 0,
    limit: 20,
    total: null,
};
const communitySlice = createAsyncSlice({
    name: 'community',
    initialState,
    reducers: (create) => ({
        getCommunities: create.asyncThunk((data) => __awaiter(void 0, void 0, void 0, function* () {
            return API.communities.getCommunities(data);
        }), {
            pending: (state) => {
                state.listLoading = true;
            },
            fulfilled: (state, action) => {
                const { offset } = action.meta.arg;
                state.listLoading = false;
                state.communityChats = offset
                    ? [...state.communityChats, ...action.payload.data.dialogs]
                    : action.payload.data.dialogs;
                state.offset = offset || 0;
                state.total = action.payload.data.total;
            },
        }),
        getCommunity: create.asyncThunk((data) => __awaiter(void 0, void 0, void 0, function* () {
            return API.communities.getCommunity(data);
        }), {
            pending: (state) => {
                state.dialogLoading = true;
            },
            fulfilled: (state, action) => {
                state.chat = CommunityDialogModel(action.payload.data.dialog);
            },
            settled: (state) => {
                state.dialogLoading = false;
            },
        }),
        updateCommunity: create.asyncThunk((_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ data, id }, { rejectWithValue, dispatch, getState }) {
            var _c;
            const formData = new FormData();
            const state = getState();
            const prevWelcomeMessage = (_c = state.community.chat) === null || _c === void 0 ? void 0 : _c.welcomeMessage;
            Object.keys(data).forEach((key) => {
                if (data[key] != null) {
                    switch (key) {
                        case 'tags':
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            if (data[key].length > 0) {
                                formData.append('tags', JSON.stringify(data[key]));
                            }
                            break;
                        case 'isHidden':
                            formData.append('isHidden', JSON.stringify(data[key]));
                            break;
                        case 'userIds':
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            if (Array.isArray(data[key])) {
                                formData.append('userIds', JSON.stringify(data[key]));
                            }
                            break;
                        default:
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            formData.append(key, data[key]);
                            break;
                    }
                }
            });
            const res = yield API.communities.updateCommunity(id, formData);
            if (res.success) {
                if (prevWelcomeMessage !== data.welcomeMessage || data.userIds) {
                    dispatch(chatMessageInfo({
                        dialog_id: id,
                        scrollBottom: true,
                        blockReloadChat: true,
                    }));
                }
                return API.communities.getCommunity({ id });
            }
            return rejectWithValue({ success: false });
        }), {
            fulfilled: (state, action) => {
                var _a;
                const { success, data } = action.payload;
                if (success) {
                    const updatedChat = CommunityDialogModel(data.dialog);
                    state.chat = updatedChat;
                    const communityIndex = state.communityChats.findIndex((community) => community.id === (updatedChat === null || updatedChat === void 0 ? void 0 : updatedChat.id));
                    if (communityIndex > -1) {
                        const community = state.communityChats[communityIndex];
                        community.name = updatedChat.name;
                        const userIdsCount = (_a = action.meta.arg.data.userIds) === null || _a === void 0 ? void 0 : _a.length;
                        community.members.ambassadors = userIdsCount !== null && userIdsCount !== void 0 ? userIdsCount : null;
                    }
                }
            },
        }),
        resetCurrentCommunity: create.reducer((state) => {
            state.chat = undefined;
        }),
        getNewCommunityMessagesCount: create.asyncThunk((id) => __awaiter(void 0, void 0, void 0, function* () {
            return API.dialog.getDialogNewMessagesCount(id);
        }), {
            fulfilled: (state, action) => {
                const chat = state.communityChats.find((c) => c.id === action.meta.arg);
                if (chat && action.payload.success) {
                    chat.new_messages = action.payload.data.newMessagesCount;
                }
            },
        }),
    }),
    extraReducers: (builder) => {
        builder.addCase(RESET_CHAT_MESSAGES_COUNTER, (state, action) => {
            if (action.payload.dialogId) {
                const chat = state.communityChats.find((c) => c.id === action.payload.dialogId);
                if (chat) {
                    chat.new_messages = '0';
                }
            }
        });
    },
    selectors: {
        // TODO: fix selector, memoization doesn't work
        getCommunitiesList: createSelector((sliceState) => sliceState, ({ communityChats: communities, listLoading, offset, limit, total }) => {
            return {
                communities,
                listLoading,
                total,
                offset,
                limit,
            };
        }),
        getCurrentCommunity: createSelector((sliceState) => sliceState, ({ chat: community, dialogLoading }) => {
            return {
                community,
                dialogLoading,
            };
        }),
        selectCommunityChats: (state) => state.communityChats,
        selectHasCommunities: (state) => state.communityChats.length > 0,
        selectIsChatHidden: (state) => { var _a; return (_a = state.chat) === null || _a === void 0 ? void 0 : _a.isHidden; },
    },
});
export const { getCommunities, getCommunity, resetCurrentCommunity, updateCommunity, getNewCommunityMessagesCount } = communitySlice.actions;
export const { getCommunitiesList, getCurrentCommunity, selectCommunityChats, selectHasCommunities, selectIsChatHidden, } = communitySlice.selectors;
export default communitySlice.reducer;
