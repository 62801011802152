/* eslint-disable camelcase */
import { REPLY_MESSAGE } from '../../actions/components/componentsActions';
const initialState = {
    activeMessage: null,
};
export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;
    switch (type) {
        case REPLY_MESSAGE: {
            return {
                activeMessage: payload.activeMessage,
            };
        }
        default: {
            return state;
        }
    }
}
