import React, { useCallback } from 'react';
import { Button } from 'src/app/components/base/Button';
import { ReactComponent as GrifithLogo } from 'src/assets/svg/grifith.svg';
import { ServiceCards } from './ServiceCards';
import { useStepsData } from '../StepsProvider';
import API from 'src/app/api';
export const StepTwo = () => {
    const { isSpetialOffer, setIsConversionSelected, isConversionSelected, setIsCommunitySelected, isCommunitySelected, setCurrentStep, setIsSpetialOffer, setIsConversionSpecialOffer, setIsCommunitySpecialOffer, } = useStepsData();
    const onNext = useCallback(() => {
        if (!isConversionSelected && !isCommunitySelected) {
            if (isSpetialOffer && !isConversionSelected && !isCommunitySelected) {
                API.idpService
                    .activateSevices({ services: [] })
                    .then((response) => {
                    if (response.success) {
                        setCurrentStep(3);
                    }
                })
                    .catch((error) => {
                    console.log('Error activating services:', error);
                });
            }
            else {
                setIsSpetialOffer(true);
                setIsConversionSelected(true);
                setIsCommunitySelected(true);
            }
        }
        else {
            setIsConversionSpecialOffer(isSpetialOffer && isConversionSelected);
            setIsCommunitySpecialOffer(isSpetialOffer && isCommunitySelected);
            setCurrentStep(2);
        }
    }, [
        isCommunitySelected,
        isConversionSelected,
        isSpetialOffer,
        setCurrentStep,
        setIsCommunitySelected,
        setIsConversionSelected,
        setIsSpetialOffer,
        setIsConversionSpecialOffer,
        setIsCommunitySpecialOffer,
    ]);
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", { className: "text-header-xl font-interface-bold pb-6" }, isSpetialOffer ? '🎁 Get your special offer' : 'Select services for activation'),
        React.createElement("div", { className: "mx-23 mb-11.5" }, isSpetialOffer ? (React.createElement("p", { className: "mb-11.5 text-center" },
            "Activate now to get \u00A0",
            React.createElement("span", { className: "font-interface-bold" }, "a free student insights online workshop"),
            "\u00A0 (worth \u00A3599)")) : (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "mb-11 text-center" },
                "Just select the ones you want to activate. This\u00A0",
                React.createElement("span", { className: "font-interface-bold" }, "will not affect your normal TAP activities, and we'll never share your data with anyone.")),
            React.createElement("div", { className: "rounded-xl bg-[#CCF2D7] p-6" },
                React.createElement("p", { className: "text-2xl italic text-black pb-5.5" }, "\u201CThis is a really great result, and we look forward to closely monitoring progress as this continues.\u201D"),
                React.createElement("p", { className: "flex flex-row items-center" },
                    React.createElement(GrifithLogo, { className: "pr-2.5" }),
                    "Griffith University"))))),
        React.createElement("div", { className: "grid grid-cols-2 gap-6" },
            React.createElement(ServiceCards, null)),
        React.createElement(Button, { className: "mt-11 my-12 process-btn", onClick: onNext }, "Continue"),
        !isSpetialOffer && (React.createElement("div", { className: "bg-brand-gray rounded-xl" },
            React.createElement("p", { className: "px-4 mt-4 text-[3.2rem]" }, "FAQ"),
            React.createElement("ul", { className: "grid gap-7 px-8 pt-7 pb-9 list-disc" },
                React.createElement("li", null,
                    React.createElement("a", { rel: "noopener noreferrer", target: "_blank", className: "faq-link", href: "https://knowledge.theambassadorplatform.com/tapxidp-joint-p2p-services-faqs#value-of-joint-services" }, "What's the value of these services?")),
                React.createElement("li", null,
                    React.createElement("a", { rel: "noopener noreferrer", target: "_blank", href: "https://knowledge.theambassadorplatform.com/tapxidp-joint-p2p-services-faqs#cost-of-js", className: "faq-link" }, "Do I have to pay extra?")),
                React.createElement("li", null,
                    React.createElement("a", { rel: "noopener noreferrer", target: "_blank", className: "faq-link", href: "https://knowledge.theambassadorplatform.com/tapxidp-joint-p2p-services-faqs#time-on-js" }, "What's the time commitment for ambassadors on these services?")),
                React.createElement("li", null,
                    React.createElement("a", { rel: "noopener noreferrer", target: "_blank", className: "faq-link", href: "https://knowledge.theambassadorplatform.com/tapxidp-joint-p2p-services-faqs#js-eligibility" }, "Eligibility criteria for the services")),
                React.createElement("li", null,
                    React.createElement("a", { rel: "noopener noreferrer", target: "_blank", className: "faq-link", href: "https://knowledge.theambassadorplatform.com/tapxidp-joint-p2p-services-faqs#idp-joint-services" }, "What are the joint services?")),
                React.createElement("li", null,
                    React.createElement("a", { rel: "noopener noreferrer", target: "_blank", className: "faq-link", href: "https://knowledge.theambassadorplatform.com/tapxidp-joint-p2p-services-faqs#subscription-for-js" }, "Do I need a subscription with TAP?")),
                React.createElement("li", null,
                    React.createElement("a", { rel: "noopener noreferrer", target: "_blank", className: "faq-link", href: "https://knowledge.theambassadorplatform.com/tapxidp-joint-p2p-services-faqs#location-of-js" }, "Where will these services live?")),
                React.createElement("li", null,
                    React.createElement("a", { rel: "noopener noreferrer", target: "_blank", className: "faq-link", href: "https://knowledge.theambassadorplatform.com/tapxidp-joint-p2p-services-faqs#js-prospect-data" }, "Who owns the leads from these services?")))))));
};
