/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Button } from 'src/app/components/base/Button';
import { useStepsData } from '../StepsProvider';
import AmbassadorsSelect from './AmbassadorsSelect';
import { ServiceCards } from './ServiceCards';
import { useDebouncedCallback } from 'use-debounce';
import useUsersList from '../hooks/useUsersList';
import { App } from 'antd';
import API from 'src/app/api';
import { IdpServiceTypeEnum } from 'src/types/general';
export const StepThree = () => {
    const { setCurrentStep, isConversionSelected, isCommunitySelected, isCommunitySpecialOffer, isConversionSpecialOffer, } = useStepsData();
    const { state, fetchConversionUsers, fetchCommunityUsers, setCommunityUsersIds, setConversionUsersIds } = useUsersList();
    const { message } = App.useApp();
    const onNext = useCallback(() => {
        const requestData = {
            services: [],
        };
        if (isCommunitySelected) {
            const communityService = {
                key: IdpServiceTypeEnum.IdpCommunity,
                isSpecialOffer: isCommunitySpecialOffer,
                selectedAmbassadors: state.communitySelectedUsers,
            };
            requestData.services.push(communityService);
        }
        if (isConversionSelected) {
            const conversionService = {
                key: IdpServiceTypeEnum.IdpConversionChat,
                isSpecialOffer: isConversionSpecialOffer,
                selectedAmbassadors: state.conversionSelectedUsers,
            };
            requestData.services.push(conversionService);
        }
        API.idpService
            .activateSevices(requestData)
            .then((response) => {
            if (response.success) {
                setCurrentStep(3);
            }
        })
            .catch((error) => {
            console.log('Error activating services:', error);
        });
    }, [
        isCommunitySelected,
        isConversionSelected,
        state.communitySelectedUsers,
        state.conversionSelectedUsers,
        isCommunitySpecialOffer,
        isConversionSpecialOffer,
        setCurrentStep,
    ]);
    const onIdpConversionUserSelect = useCallback(({ target: { checked, value } }) => {
        const currentValue = state.conversionSelectedUsers;
        const updatedValue = checked ? [...currentValue, value] : currentValue.filter((item) => item !== value);
        setConversionUsersIds(updatedValue);
    }, [setConversionUsersIds, state.conversionSelectedUsers]);
    const onIdpCommunityUserSelect = useCallback(({ target: { checked, value } }) => {
        const currentValue = state.communitySelectedUsers;
        const updatedValue = checked ? [...currentValue, value] : currentValue.filter((item) => item !== value);
        if (updatedValue.length > 5) {
            message.open({
                type: 'info',
                content: 'You can select up to 5 ambassadors for P2P Community',
            });
        }
        else {
            setCommunityUsersIds(updatedValue);
        }
    }, [message, setCommunityUsersIds, state.communitySelectedUsers]);
    const handleConversionUserSearch = useDebouncedCallback((e) => {
        fetchConversionUsers({ conversionSearch: e.target.value });
    }, 300);
    const handleCommunityUserSearch = useDebouncedCallback((e) => {
        fetchCommunityUsers({ communitySearch: e.target.value });
    }, 300);
    useEffect(() => {
        fetchConversionUsers({});
        fetchCommunityUsers({});
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("h3", { className: "text-header-xl font-interface-bold pb-6" }, "Select ambassadors"),
        React.createElement("div", { className: "mx-23 mb-7" },
            React.createElement("p", { className: "mb-6" }, "We have pre-selected ambassadors for TAP x IDP Joint Services based on their activity on the platform. Change the list of ambassadors now or later in the dashboard."),
            React.createElement("p", { className: "mb-6" },
                React.createElement("span", { className: "font-interface-bold" }, "We will send the ambassadors the additional training automatically"),
                "\u00A0 so you do not have to worry about that. The instructions can be found on the invite ambassadors page of the dashboard.")),
        React.createElement("div", { className: "grid grid-cols-2 gap-6" },
            React.createElement(ServiceCards, null),
            React.createElement(AmbassadorsSelect, { items: state.conversionUsersList, isLoading: state.conversionLoading, isSelected: isConversionSelected, handleSearch: handleConversionUserSearch, serviceName: "P2P Conversion app", selectedIds: state.conversionSelectedUsers, handleCheckboxChange: onIdpConversionUserSelect }),
            React.createElement(AmbassadorsSelect, { items: state.communityUsersList, isLoading: state.communityLoading, isSelected: isCommunitySelected, handleSearch: handleCommunityUserSearch, serviceName: "P2P Community", selectedIds: state.communitySelectedUsers, handleCheckboxChange: onIdpCommunityUserSelect })),
        React.createElement(Button, { className: "mt-11 process-btn", onClick: onNext }, "Activate & Continue")));
};
