export function getTopNItemsByKey(arr, key, n) {
    return [...arr]
        .sort((a, b) => {
        if (a[key] > b[key])
            return -1;
        if (a[key] < b[key])
            return 1;
        return 0;
    })
        .slice(0, n);
}
